import { Component, Input } from '@angular/core';
import { NavigationService } from 'src/app/navigation/navigation.service';

@Component({
  selector: 'migration-modal',
  templateUrl: './migration-modal.component.html',
})
export class MigrationModalComponent {
  @Input() modal: any;

  constructor(private navigator: NavigationService) {}

  gotoPage(route: string) {
    this.navigator.gotoPage(route);
    this.close();
  }

  close() {
    this.modal.dismiss('Cross click');
  }
}
