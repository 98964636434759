// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const gamesServer = {
  host: 'games-server-production.bethehouse.com',
  port: 443,
  ssl: true,
};
const gamesServerHost = gamesServer.host + ':' + gamesServer.port;

const externalGamesServer = {
  host: 'ss-prod.bethehouse.com',
  port: 444,
  ssl: true,
};
const externalGamesServerHost =
  externalGamesServer.host + ':' + externalGamesServer.port;

export const environment = {
  production: false,

  apiUrl: (gamesServer.ssl ? 'https://' : 'http://') + gamesServerHost,

  gamesSocket: gamesServer,

  externalGamesSocket: externalGamesServer,
  externalGamesApi:
    (externalGamesServer.ssl ? 'https://' : 'http://') +
    externalGamesServerHost,

  currencyApiUrl: 'https://currency-server-production.bethehouse.com',

  /*   chatServer: {
    host: 'localhost',
    port: 3000,
    ssl: false,
  }, */

  chatServer: {
    host: 'chat-production.bethehouse.com',
    port: 443,
    ssl: true,
  },

  /*** WAX MAINNET: ***/
  eos_chainId:
    '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  eos_protocol: 'https',
  eos_host: 'api.waxpub.net',
  eos_port: 443,
  /***/

  // TODO:
  recaptchaSiteKey: '6Lc7I_0cAAAAABkBroiSSqOMi1CkL2Gbs0EfLwgX',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
