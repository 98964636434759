import { Injectable } from '@angular/core';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { environment } from 'src/environments/environment';
import { IGoogleAnalyticsService } from './interfaces';

// declare var ga;
declare var gtag: any;

const propertyTrackingId = 'UA-125191453-1';

@Injectable()
export class GoogleAnalyticsService implements IGoogleAnalyticsService {
  constructor(authState: AuthStateService) {
    authState.authenticatedEvent.subscribe((data) =>
      this.setUserId(data.username as string)
    );
  }

  private setUserId(userId: string) {
    if (environment.production && gtag) {
      gtag('config', propertyTrackingId, {
        user_id: userId,
      });
    }
  }

  addTransaction(
    txnId: string,
    game: string,
    currency: string,
    amountInUSD: string,
    isAutoBet: boolean,
    isJackpot: boolean
  ) {
    if (!environment.production || !gtag) {
      return;
    }

    console.log({
      txnId,
      game,
      currency,
      amountInUSD,
      isAutoBet,
      isJackpot,
    });

    const productId =
      game +
      '-' +
      currency +
      '-' +
      (isAutoBet ? 'AUT' : 'MAN') +
      (isJackpot ? '-JP' : '');

    gtag('event', 'purchase', {
      transaction_id: txnId,
      value: amountInUSD,
      currency: 'USD',
      affiliation: game,
      items: [
        {
          id: productId,
          name: productId,
          quantity: 1,
          price: amountInUSD,
          category: currency,
        },
      ],
    });
  }
}
