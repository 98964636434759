import { Howl, Howler } from 'howler';

import { Injectable } from '@angular/core';
import { IAudioService } from './interfaces';

const audioDiectory = 'assets/audio/';

const volumeKey = 'volume';

@Injectable()
export class AudioService implements IAudioService {
  private cache: { [name: string]: Howl } = {};

  constructor() {}

  init() {
    Howler.volume(this.volume);
  }

  play(soundName: string): void {
    if (!this.cache[soundName]) {
      this.cache[soundName] = new Howl({
        src: [audioDiectory + soundName + '.mp3'],
      });
    }

    const sound = this.cache[soundName];
    sound.play();
  }

  set isMuted(value: boolean) {
    this.volume = value ? 0 : 1;
  }
  get isMuted() {
    return this.volume == 0;
  }

  playFile(filePath: string) {
    if (this.isMuted) {
      return;
    }

    let audio = new Audio();
    audio.src = filePath;
    audio.volume = this.volume;
    audio.load();
    audio.play();

    return audio;
  }

  set volume(value: number) {
    localStorage.setItem(volumeKey, JSON.stringify(value));

    Howler.volume(value);
  }
  get volume() {
    return JSON.parse(localStorage.getItem(volumeKey) || '1');
  }
}
