import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { LeaderboardApiService } from './services/leaderboard-api.service';

import { LeaderboardCharts } from './services/leaderboard-charts';
import { LeaderboardService } from './services/leaderboard.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [LeaderboardService, LeaderboardCharts, LeaderboardApiService],
  exports: [HttpClientModule],
})
export class LeaderboardServicesModule {}
