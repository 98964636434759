<div class="modal-width nft">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <h4 class="modal-title" id="modal-basic-title">CryptoPunk #9998</h4>
    <img src="assets/images/leader_board/nft.png" alt="nft image" />
  </div>
  <div class="modal-footer"></div>
</div>
