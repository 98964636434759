import { Injectable } from '@angular/core';

import { IPublicDataOutput } from 'src/app/global-state/outputs/interfaces';
import { AppEventsService } from 'src/app/global-state/services/app-events.service';
import { SidebarStateService } from 'src/app/sidebar/services/sidebar-state.service';

import { LeaderboardCharts } from './leaderboard-charts';

@Injectable()
export class LeaderboardService {
  private isInitialized = false;

  constructor(
    private sidebar: SidebarStateService,
    readonly charts: LeaderboardCharts,
    events: AppEventsService
  ) {
    events.publicDataReceivedEvent.subscribe(this.onPublicDataReceived);
  }

  private onPublicDataReceived = (data: IPublicDataOutput) => {
    this.charts.setCurrentLeaderboardData(data.currentLeaderboard);

    if (!this.isInitialized) {
      this.isInitialized = true;
      this.sidebar.showLeaderboard();
    }
  };

  get myRank() {
    return this.myData ? this.myData.rank : '----';
  }

  get myPoints() {
    return this.myData ? this.myData.points : 0;
  }

  get myData() {
    return this.latest ? this.latest.myData : undefined;
  }

  get firstPlacePrize() {
    return this.latest ? this.latest.firstPlacePrize : '';
  }

  get startDate() {
    return this.latest ? this.latest.startDate : '';
  }

  get endDate() {
    return this.latest ? this.latest.endDate : '';
  }
  get endTime() {
    return this.latest?.endTime;
  }

  get latest() {
    return this.charts.latest;
  }
}
