export class CountdownData {
  constructor(readonly endTime: Date) {}

  get days() {
    return Math.floor(this.millisecondsRemaining / (1000 * 60 * 60 * 24));
  }

  get hours() {
    return Math.floor((this.millisecondsRemaining / (1000 * 60 * 60)) % 24);
  }

  get minutes() {
    return Math.floor((this.millisecondsRemaining / 1000 / 60) % 60);
  }

  get seconds() {
    return Math.floor((this.millisecondsRemaining / 1000) % 60);
  }

  get millisecondsRemaining() {
    const diff = this.endTime.getTime() - Date.now();

    return diff > 0 ? diff : 0;
  }
}

export function dateStringToGMT(datetime: string) {
  const dateParts = datetime.split('T');
  return dateParts[0] + ' ' + dateParts[1].substr(0, 8) + ' GMT';
}
