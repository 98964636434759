<!-- Stage 1: not logged in -->
<div class="background" [ngClass]="'bg-' + stage" *ngIf="stage == 1">
  <button class="close-btn" (click)="close()">
    <img src="assets/icons/function/close_banner.svg" />
  </button>
  <div class="buttons-claim">
    <h6>RAIN BOT</h6>
    <img src="assets/images/banners/clouds_1.svg" />
    <div class="info">
      <p>Login or Sign up to claim your free crypto</p>
      <button (click)="login()">LOG IN</button>
    </div>
  </div>
</div>

<!-- Stage 2: Waiting for Next Rain -->
<div class="background" [ngClass]="'bg-' + stage" *ngIf="stage == 2">
  <button class="close-btn" (click)="close()">
    <img src="assets/icons/function/close_banner.svg" />
  </button>

  <div class="buttons-claim">
    <h6>RAIN BOT</h6>
    <img src="assets/images/banners/clouds_2.svg" />
    <div class="info">
      <p>Stick around and claim free crypto by entering the rain giveaway</p>
    </div>
  </div>
</div>

<!-- Stage 3: It's raining but not yet claimed -->
<div class="background" [ngClass]="'bg-' + stage" *ngIf="stage == 3">
  <button class="close-btn" (click)="close()">
    <img src="assets/icons/function/close_banner.svg" />
  </button>

  <div (click)="claim()" class="buttons-claim">
    <h6>RAIN BOT</h6>
    <!--<img src="assets/images/banners/clouds_btc.svg" />-->
    <div class="info">
      <p>A rain of $25 in Bitcoin will get distributed shortly</p>
      <button>Claim rain</button>
    </div>
  </div>

  <ng-lottie
    [options]="optionsRain"
    (animationCreated)="animationCreated($event)"
  ></ng-lottie>
</div>

<!-- Stage 4: Rain claimed and waiting for distribution -->
<div class="background" [ngClass]="'bg-' + stage" *ngIf="stage == 4">
  <button class="close-btn" (click)="close()">
    <img src="assets/icons/function/close_banner.svg" />
  </button>
  <div class="buttons-claim">
    <h6>RAIN BOT</h6>
    <!--<img src="assets/images/banners/clouds_btc.svg" />-->
    <div class="info">
      <p>A rain of $25 in Bitcoin will get distributed shortly</p>
      <ngb-progressbar [value]="progress">
        <div>
          <img src="" />
        </div>
      </ngb-progressbar>
    </div>
  </div>
  <ng-lottie
    [options]="optionsRain"
    (animationCreated)="animationCreated($event)"
  ></ng-lottie>
</div>
