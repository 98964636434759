export function generateNonce(length = 40) {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  let nonce = '';

  for (let i = 0; i < length; i++) {
    nonce += characters.charAt(getRandomIntegerBetween0AndX(characters.length));
  }

  return nonce;
}

export function getRandomDigit() {
  return getRandomIntegerBetween0AndX(9);
}

export function getRandomIntegerBetween0AndX(x: number): number {
  return Math.floor(Math.random() * x);
}
