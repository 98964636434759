<div class="modal-width rules">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <h4 class="modal-title" id="modal-basic-title">Chat Rules</h4>
    <h5>
      In order to ensure the best chatting experience, EarnBet.io reserves the
      right to mute or ban users if they do not obey the following chat rules:
    </h5>
    <ul>
      <li>Do not spam</li>
      <li>Do not argue with other people over chat</li>
      <li>Be Respectful</li>
      <li>No advertising or self promotion.</li>
      <li>No racism or hate speech</li>
      <li>No Slander</li>
      <li>Do not harass users for money</li>
    </ul>
  </div>
  <div class="modal-footer"></div>
</div>
