import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { getStakingLevelName } from 'src/app/staking/models/staking-level-data';
import {
  ILeaderboardPointsData,
  ILeaderboardData,
} from '../outputs/interfaces';
import { ILeaderboardChartRow, ILeaderboardReward } from './interfaces';

export class LeaderboardChart {
  private points: ILeaderboardPointsData[] = [];
  private prizes: string[][] = [];
  private _startTime: Date;
  private _endTime: Date;
  readonly chartData: ILeaderboardChartRow[] = [];
  firstPlacePrize = '';
  private _myData: ILeaderboardChartRow;

  constructor(
    initialData: ILeaderboardData,
    private authState: AuthStateService
  ) {
    this.setData(initialData);
  }

  private setData(data: ILeaderboardData) {
    this.points = data.points;
    this.prizes = JSON.parse(data.prizes);

    this._startTime = new Date(data.startTime);
    this._endTime = new Date(data.endTime);

    // delete chart data
    this.chartData.splice(0, this.chartData.length);

    // re-populate chart data
    for (let i = 0; i < this.points.length; i++) {
      const rank = i + 1;

      const pointsData = this.points[i];

      const user = pointsData.username;
      const points = Math.round(Number(pointsData.wageringPoints));

      const prizes = this.prizes[i];
      const rewards: ILeaderboardReward[] = [];

      if (prizes) {
        for (let j = 0; j < prizes.length; j++) {
          const prize = prizes[j];

          if (i == 0 && j == 0) {
            this.firstPlacePrize = prize;
          }

          const parts = prize.split(' ');
          const amount = parts[0];
          const token = parts[1];

          rewards.push({ amount, token });
        }
      }

      const data: ILeaderboardChartRow = {
        rank,
        user,
        points,
        rewards,
        stakeLevelName: getStakingLevelName(pointsData.totalStakePoints),
      };
      this.chartData.push(data);

      /*** if data belongs to current user, save it as such ***/
      const isMyData =
        this.authState.isLoggedIn && pointsData.userId == this.authState.userId;

      if (isMyData) {
        this._myData = data;
      }
    }
  }

  get myData() {
    return this._myData;
  }

  get startDate() {
    if (!this.startTime) {
      return '';
    }

    return this.startTime.getMonth() + 1 + '/' + this.startTime.getDate();
  }

  get endDate() {
    if (!this.endTime) {
      return '';
    }

    return this.endTime.getMonth() + 1 + '/' + this.endTime.getDate();
  }

  get startTime() {
    return this._startTime;
  }
  get endTime() {
    return this._endTime;
  }
}
