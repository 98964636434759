import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'user-stats',
  templateUrl: './user-stats.component.html',
})
export class UserStatsComponent implements OnInit {
  @Input() modal: any;

  constructor() {}

  ngOnInit(): void {}
}
