import { Big, RoundingMode } from 'big.js';

import { roundDownWithoutTrailingZeros } from 'src/app/util/math-util';

export class IntBasedNum {
  constructor(
    readonly precision: number,
    decimalValue: number | string = 0,
    bigDecimal: Big | undefined = undefined
  ) {
    const decimal =
      bigDecimal == undefined
        ? new Big(decimalValue).round(precision, RoundingMode.RoundDown)
        : bigDecimal;

    this.factor = Math.pow(10, precision);
    this._integer = decimal.times(this.factor).round(0, RoundingMode.RoundDown);
  }

  get reciprocal(): IntBasedNum {
    if (this._reciprocal == undefined) {
      this._reciprocal = integerDivision(
        new IntBasedNum(this.precision, '1'),
        this
      );
    }

    return this._reciprocal;
  }

  get opposite_(): IntBasedNum {
    return integerMultiplication(new IntBasedNum(this.precision, '-1'), this);
  }

  get decimalAsNumber() {
    return Number(this.decimal);
  }

  get integer(): Big {
    return this._integer;
  }

  get decimalWithoutTrailingZeros() {
    return roundDownWithoutTrailingZeros(this.decimal, this.precision);
  }

  get decimal() {
    Big.RM = RoundingMode.RoundDown;

    return this.integer.div(this.factor).toFixed(this.precision);
  }

  // INSTANCE
  readonly factor: number;
  private _integer: Big;

  private _reciprocal: IntBasedNum;
  // CLASS
  static fromInteger(integer: string, precision: number) {
    return new IntBasedNum(
      precision,
      undefined,
      new Big(integer)
        .div(Math.pow(10, precision))
        .round(precision, RoundingMode.RoundDown)
    );
  }

  /*** MUTATING METHODS ***/
  addAndReplace(other: IntBasedNum) {
    if (this.precision != other.precision) {
      throw new Error('precision of both operands must be the same!');
    }

    const sum = this.integer.plus(other.integer);
    this._integer = sum;

    // return this;
  }

  subtractAndReplace(other: IntBasedNum) {
    if (this.precision != other.precision) {
      throw new Error('precision of both operands must be the same!');
    }

    const diff = this.integer.minus(other.integer);
    this._integer = diff;

    return this;
  }
  /***/

  multiply(other: IntBasedNum) {
    return integerMultiplication(this, other);
  }

  multiplyDecimal(decimal: number) {
    const other = new IntBasedNum(this.precision, decimal);

    return integerMultiplication(this, other);
  }

  divideByDecimal(decimal: number) {
    const other = new IntBasedNum(this.precision, decimal.toString());

    return integerDivision(this, other);
  }

  toString() {
    return this.decimal;
  }

  /*
    toHigherPrecision(newPrecision:number):IntBasedNum {
        if (newPrecision < this.precision) {
            throw new Error('New Precision Must be Higher!');
        }


        return IntBasedNum.fromInteger(
            this.integer.times(
                Math.pow(
                    10,
                    newPrecision-this.precision
                )
            ).toString(),
            newPrecision
        );
    }
    */
}

export class AssetAmount extends IntBasedNum {
  // private _decimal:string;
  private _symbol: string;

  private _isZero: boolean;

  constructor(data: string) {
    const parts = data.split(' ');

    const decimal = parts[0];
    const symbol = parts[1];

    const precision = decimal.split('.')[1].length;

    super(precision, decimal);

    this._symbol = symbol;

    this._isZero = Number(decimal) == 0;
  }

  get isZero() {
    return this._isZero;
  }

  get symbol() {
    return this._symbol;
  }
}

// export
function integerAddition<T extends IntBasedNum>(a: T, b: T): IntBasedNum {
  if (a.precision != b.precision) {
    throw new Error(
      'precision of a: ' +
        a.precision +
        ', precision of b: ' +
        b.precision +
        '\nprecision of both operands must be the same!'
    );
  }

  const sum = a.integer.plus(b.integer);

  return IntBasedNum.fromInteger(sum.toString(), a.precision);
}

// export
function integerSubtraction<T extends IntBasedNum>(a: T, b: T): IntBasedNum {
  if (a.precision != b.precision) {
    throw new Error('precision of both operands must be the same!');
  }

  const diff = a.integer.minus(b.integer);

  return IntBasedNum.fromInteger(diff.toString(), a.precision);
}

// export
function integerMultiplication<T extends IntBasedNum>(a: T, b: T): IntBasedNum {
  if (a.precision < b.precision) {
    console.log(a.decimal);
    console.log(b.decimal);

    throw new Error(
      'precision of a: ' +
        a.precision +
        ', precision of b: ' +
        b.precision +
        '\nprecision of A must be >= B!'
    );
  }

  const product = a.integer.times(b.integer);

  const integer = product
    .div(b.factor)
    .round(0, RoundingMode.RoundDown)
    .toString();

  const result = IntBasedNum.fromInteger(integer, a.precision);

  // console.log('INT Multiplication: '+a+' * '+b+' = '+result);

  return result;
}

// when doing division between two eos numbers then disregard
// the decimal after the integer
// export
function integerDivision<T extends IntBasedNum>(a: T, b: T): IntBasedNum {
  if (a.precision < b.precision) {
    throw new Error('precision of A must be >= B!');
  }

  const quotient = a.integer.div(b.integer);

  const integer = quotient
    .times(b.factor)
    .round(0, RoundingMode.RoundDown)
    .toString();

  const result = IntBasedNum.fromInteger(integer, a.precision);

  console.log('INT Division: ' + a + ' / ' + b + ' = ' + result);

  return result;
}
