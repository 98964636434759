import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiServiceBase } from 'src/app/http/services/api-base.service';
import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { environment } from 'src/environments/environment';
import { ResolvedBets } from 'src/app/bets/services/resolved-bets';
import { IPublicDataOutput } from 'src/app/global-state/outputs/interfaces';
import { sleep } from 'src/app/util/timer-util';
import { IBetResult } from 'src/app/bets/outputs/interfaces';
import { BetResult } from 'src/app/bets/outputs/bet-result';

@Injectable()
export class ExternalGameEventsService extends ApiServiceBase {
  private isInit = false;
  private recentBets: IBetResult[] = [];

  constructor(
    http: HttpClient,
    errorHandler: ErrorHandlerService,
    private bets: ResolvedBets
  ) {
    super(`${environment.externalGamesApi}`, http, errorHandler);

    const events = bets.balances.stakingData.tokenSettings.events;

    events.publicDataReceivedEvent.subscribe(this.onPublicDataReceived);

    this.init();
  }

  private async init() {
    this.recentBets = await this.getRecentBets();

    this.isInit = true;
  }

  private onPublicDataReceived = async (data: IPublicDataOutput) => {
    if (this.bets.isPublicBetsLoaded) {
      return;
    }

    console.log('GameEventsService.onPublicDataReceived', data);

    for (let i = 0; i < 200; i++) {
      if (this.isInit) {
        console.log(`waited for ${i * 100} ms`);

        break;
      }

      await sleep(100);
    }

    await this.tokenSettings.waitForInit();

    const internal: IBetResult[] = data.recentBets.all.map(
      (data) => new BetResult(data, this.tokenSettings)
    );

    // merge internal and external bets list
    const allBets: IBetResult[] = internal.concat(this.recentBets);

    allBets.sort((a, b) => {
      if (!a.time.getTime) {
        a.time = new Date(a.time);
      }

      if (!b.time.getTime) {
        b.time = new Date(b.time);
      }

      return a.time.getTime() - b.time.getTime();
    });

    this.bets.initRecentBets({ ...data.recentBets, all: allBets });
  };

  private getRecentBets() {
    return this.get<IBetResult[]>('game-events');
  }

  private get tokenSettings() {
    return this.bets.tokenSettings;
  }
}
