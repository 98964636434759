import { Injectable } from '@angular/core';

import { IBetResult } from 'src/app/bets/outputs/interfaces';
import { ResolvedBets } from 'src/app/bets/services/resolved-bets';
import { SocketClientBase } from 'src/app/socket/socket-client-base';
import { environment } from 'src/environments/environment';

const NAMESPACE = '/external-games';

@Injectable()
export class ExternalGamesClient extends SocketClientBase {
  constructor(private bets: ResolvedBets) {
    super(environment.externalGamesSocket, NAMESPACE);

    this.connect();
  }

  private connect() {
    const socket = this.connectSocket();
    socket.on('onResolvedAction', (data) => {
      this.onResolvedAction(data);
    });
  }

  onConnected() {
    console.log('Swiss Dashboard client connected to Server!');
  }

  /* listen on softswiss betting  */
  onResolvedAction(data: IBetResult) {
    this.bets.onBetResult(data);
  }

  onDisconnected() {
    //console.log('Swiss Dashboard client disconnected');
  }
}
