import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/global-state/services/app-settings.service';

@Injectable()
export class TokensService {
  selectedToken: { symbol: string; balance: string };

  constructor(readonly settings: AppSettings) {
    settings.selectedCurrencyChangedEvent.subscribe(this.updateSelectedToken);
    settings.balances.balanceChangedEvent.subscribe(this.onBalanceChanged);
  }

  private onBalanceChanged = (tokenSymbol: string) => {
    if (tokenSymbol == this.selectedToken?.symbol) {
      this.updateSelectedToken();
    }
  };
  private updateSelectedToken = () => {
    const { symbol } = this.settings.selectedToken;

    this.selectedToken = {
      symbol,
      balance: this.settings.balances.getBalance(symbol),
    };
  };

  get tokens() {
    return this.settings.balances.bettingBalances;
  }
}
