export function toTotalDigits(
  amount: number,
  digits: number,
  precision?: number
) {
  return numStringToTotalDigits(String(amount), digits, precision);
}

export function numStringToTotalDigits(
  amount: string,
  digits: number,
  precision?: number
) {
  const rounded = roundStringDownWithPrecision(amount, digits + 1);

  const numOfDigitsBeforeDecimal = getNumOfDigitsBeforeDecimal(rounded);

  let numOfDigitsAfterDecimal = digits - numOfDigitsBeforeDecimal;

  if (precision != undefined && numOfDigitsAfterDecimal > precision) {
    numOfDigitsAfterDecimal = precision;
  }

  return numOfDigitsAfterDecimal > 0
    ? roundStringDownWithPrecision(rounded, numOfDigitsAfterDecimal)
    : rounded.split('.')[0];
}

export function roundDownWithPrecision(amount: number, precision: number) {
  // amount = Number(amount);

  return roundStringDownWithPrecision(String(amount), precision);
}

export function roundDownWithoutTrailingZeros(
  amount: string,
  precision: number
): string {
  const valueWithAllDigits = roundStringDownWithPrecision(amount, precision);

  const parts = valueWithAllDigits.split('.');
  const digitsBeforeDecimal = parts[0];
  const digitsAfterDecimal = parts[1];

  let numOfDecimalDigitsToInclude = 0;

  for (let i = digitsAfterDecimal.length - 1; i > -1; i--) {
    const digit = digitsAfterDecimal[i];

    if (digit != '0') {
      numOfDecimalDigitsToInclude = i + 1;

      break;
    }
  }

  return numOfDecimalDigitsToInclude > 0
    ? digitsBeforeDecimal +
        '.' +
        digitsAfterDecimal.substr(0, numOfDecimalDigitsToInclude)
    : digitsBeforeDecimal;
}

export function roundStringDownWithPrecision(
  amount: string,
  precision: number
) {
  let parts = String(amount).split('.');

  let isWholeNumber = parts.length == 1;

  let rounded = Number(
    parts[0] + '.' + (isWholeNumber ? '0' : parts[1].substr(0, precision))
  );

  let result = rounded.toFixed(precision);

  return result;
}

function getNumOfDigitsBeforeDecimal(number: string) {
  const parts = number.split('.');

  return parts[0].length;
}

export function toNumericString(value: number, precision: number) {
  let strValue = value.toString();

  if (strValue.includes('e-')) {
    strValue = value.toFixed(precision + 1);
  }

  const [beforeDecimal, afterDecimal] = strValue.split('.');

  return !afterDecimal || afterDecimal.length < precision
    ? strValue
    : beforeDecimal + '.' + afterDecimal.substring(0, precision);
}
