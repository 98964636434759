import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PreloadStrategy } from './preload-strategy';

const routes: Routes = [
  {
    path: '',
    data: { name: 'home' },
    loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
  },

  {
    path: '',
    data: { name: 'account-migration' },
    loadChildren: () =>
      import('../account-migration/account-migraton.module').then(
        (m) => m.AccountMigrationModule
      ),
  },

  {
    path: '',
    data: { name: 'users' },
    loadChildren: () =>
      import('../users/users.module').then((m) => m.UsersModule),
  },

  {
    path: '',
    data: { name: 'dice' },
    loadChildren: () => import('../dice/dice.module').then((m) => m.DiceModule),
  },

  {
    path: '',
    data: { name: 'blackjack' },
    loadChildren: () =>
      import('../blackjack/blackjack.module').then((m) => m.BlackjackModule),
  },

  {
    path: '',
    data: { name: 'baccarat' },
    loadChildren: () =>
      import('../baccarat/baccarat.module').then((m) => m.BaccaratModule),
  },

  {
    path: '',
    data: { name: 'crash' },
    loadChildren: () =>
      import('../crash/crash.module').then((m) => m.CrashModule),
  },

  {
    path: '',
    data: { name: 'hilo' },
    loadChildren: () => import('../hilo/hilo.module').then((m) => m.HiloModule),
  },

  {
    path: '',
    data: { name: 'games' },
    loadChildren: () =>
      import('../external-games/games.module').then((m) => m.GamesModule),
  },

  {
    path: '',
    data: { name: 'user-profile' },
    loadChildren: () =>
      import('../user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },

  {
    path: '',
    data: { name: 'blog' },
    loadChildren: () => import('../blog/blog.module').then((m) => m.BlogModule),
  },

  {
    path: '',
    data: { name: 'transactions' },
    loadChildren: () =>
      import('../transactions/transactions.module').then(
        (m) => m.TransactionsModule
      ),
  },

  {
    path: '',
    data: { name: 'bet-token' },
    loadChildren: () =>
      import('../bet-token/bet-token.module').then((m) => m.BetTokenModule),
  },

  {
    path: '',
    data: { name: 'leaderboard' },
    loadChildren: () =>
      import('../leaderboard/leaderboard-page.module').then(
        (m) => m.LeaderboardPageModule
      ),
  },

  {
    path: '',
    data: { name: 'chat' },
    loadChildren: () =>
      import('../chat/chat-page.module').then((m) => m.ChatPageModule),
  },

  {
    path: '',
    data: { name: 'affiliate' },
    loadChildren: () =>
      import('../affiliate/affiliate-page.module').then(
        (m) => m.AffiliatePageModule
      ),
  },

  {
    path: '',
    data: { name: 'static-pages' },
    loadChildren: () =>
      import('../static-pages/static-pages.module').then(
        (m) => m.StaticPagesModule
      ),
  },

  // this should be last
  /*
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  */
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  //scrollPositionRestoration: "enabled",
  preloadingStrategy: PreloadStrategy,
  enableTracing: false,
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  providers: [PreloadStrategy],
  exports: [RouterModule],
})
export class AppRoutingModule {}
