import { Component, Input, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from 'src/app/global-state/services/app.service';
import { ILeaderboardChartRow } from '../../models/interfaces';

@Component({
  selector: 'leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderBoardComponent extends AppComponentBase implements OnInit {
  closeResult = '';

  currentTabId: number;
  /*************************************************/
  @Input()
  isForSidebar = true;

  private _rows: ILeaderboardChartRow[] = [];

  private intervalId: NodeJS.Timeout;

  constructor(app: AppService) {
    super(app);
  }

  ngOnInit(): void {
    this.intervalId = setInterval(this.updateRows, 1000 * 60);
    this.updateRows();
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  private updateRows = () => {
    this._rows = this.latest ? this.latest.chartData.slice(0, 50) : [];
  };

  onNavChange(changeEvent: NgbNavChangeEvent) {}

  // poor name, but kept for backwards compatibility, since it's referenced a lot in the component template:
  get show() {
    return !this.isForSidebar;
  }

  get rows() {
    return this._rows;
  }

  get myRank() {
    return this.myData?.rank;
  }
  get myData() {
    return this.leaderboard.myData;
  }

  get latest() {
    return this.leaderboard.latest;
  }
  get endTime() {
    return this.leaderboard.endTime;
  }
}
