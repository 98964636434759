import { Injectable } from '@angular/core';

import { AppEventsService } from 'src/app/global-state/services/app-events.service';
import { sleep } from 'src/app/util/timer-util';
import { ITokenSettingsData } from '../settings/interfaces';
import { TokenSettings } from '../settings/token-settings';
import { TokenSymbol } from '../settings/token-symbols';
import { ITokenSettingsService } from './interfaces';

@Injectable()
export class TokenSettingsService implements ITokenSettingsService {
  private settings: { [symbol: string]: TokenSettings } = {
    [TokenSymbol.FUN]: new TokenSettings({
      currency_symbol: TokenSymbol.FUN,
      currency_precision: 8,
      currency_price_usd: 0.01,
      max_win_amount: 1000000,
      supports_memo: false,
      minimum_withdrawal_amount: '0',
    }),
  };

  private _isReady = false;
  private start = Date.now();

  constructor(readonly events: AppEventsService) {
    events.publicDataReceivedEvent.subscribe((data) => {
      //console.log({ tokens: data.coinPrices });

      this.syncSettings(data.coinPrices);
    });
  }

  private syncSettings(settingsData: ITokenSettingsData[]) {
    settingsData.forEach(
      (data) => (this.settings[data.currency_symbol] = new TokenSettings(data))
    );

    if (!this.isReady) {
      this._isReady = true;

      console.log('CurrencyService Ready Time: ' + (Date.now() - this.start));
    }
  }

  async getAllTokens() {
    await this.waitForInit();

    const settings: TokenSettings[] = [];

    for (const symbol in this.settings) {
      settings.push(this.settings[symbol]);
    }

    return settings;
  }

  async waitForInit() {
    while (!this.isReady) {
      await sleep(100);
    }
  }

  getPrice(tokenSymbol: string) {
    const settings = this.getSettings(tokenSymbol);

    return settings.priceInUSD;
  }

  getSettings(tokenSymbol: string) {
    const settings = this.settings[tokenSymbol];

    if (!settings) {
      throw new Error('token settings NOT FOUND: ' + tokenSymbol);
    }

    return settings;
  }

  get isReady() {
    return this._isReady;
  }
}
