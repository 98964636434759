import { Injectable } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { AppSettings } from 'src/app/global-state/services/app-settings.service';
import { LocalStorageData } from 'src/app/global-state/util/local-storage-util';
import {
  totalUsdDepositRequired,
  totalUsdVolumeRequired,
} from 'src/app/sign-up-bonus/data/confiig';
import { ISignUpBonusStateData } from '../data/interfaces';
import {
  CreateAccountCTA,
  DepositCTA,
  RealBetsCTA,
} from '../models/funnel-cta';
import { IFunnelCTA } from '../models/interfaces';
import { ISignUpBonusProgressData } from '../outputs/interfaces';

@Injectable()
export class SignUpBonusState {
  private localData: LocalStorageData<ISignUpBonusStateData> =
    new LocalStorageData('NewUserFunnelStateData', true);

  private _numOfDemoBetsPlaced = 0;
  private _learnedAboutDividends = false;

  private totalUsdDeposited = 0;
  private totalUsdBetVolume = 0;

  private _isDismissed = false;
  private _isCompleted = false;
  private _award = '';

  private _isEligibleForFunnel = false;

  private _isIndexPage = false;
  private isInitialized = false;

  private _isNewlyCreatedAccount = false;

  private isShownLandingPage = false;

  //private demoBetsCTA: DemoBetsCTA;
  //private dividendsCTA: DividendsCTA;
  private createAccountCTA: CreateAccountCTA;
  private depositCTA: DepositCTA;
  private realBetsCTA: RealBetsCTA;

  constructor(
    readonly settings: AppSettings,
    //private modal: OverlayModalService,
    private authState: AuthStateService
  ) {
    //this.demoBetsCTA = new DemoBetsCTA(app);
    //this.dividendsCTA = new DividendsCTA(app);
    this.createAccountCTA = new CreateAccountCTA(settings);
    this.depositCTA = new DepositCTA(settings);
    this.realBetsCTA = new RealBetsCTA(settings);

    this.loadSavedData();
  }

  init() {
    this.initFunnelTracking();

    this._isEligibleForFunnel = true;

    this.isInitialized = true;

    // this.redirectToHomePage();

    this.markLandingPageAsShown();
  }
  private initFunnelTracking() {
    const savedData = this.localData.get();

    if (savedData == null) {
      // create local storage object that tracks all relevant fields of funnel
      this.saveData();
    }
  }

  private loadSavedData() {
    const savedData = this.localData.get();

    if (savedData != null) {
      // load numOfDemoBetsPlaced
      this._numOfDemoBetsPlaced = savedData.numOfDemoBetsPlaced;

      // load learnedAboutDividends
      this._learnedAboutDividends = savedData.learnedAboutDividends;

      if (savedData.deposits) {
        this.totalUsdDeposited = savedData.deposits;
      }

      if (savedData.wagers) {
        this.totalUsdBetVolume = savedData.wagers;
      }

      // load isDismissed
      this._isDismissed = savedData.isDismissed;

      // load isCompleted
      this._isCompleted = savedData.isCompleted;

      if (savedData.award) {
        this._award = savedData.award;
      }

      this._isEligibleForFunnel = true;
    }
  }

  onLandingPage() {
    this._isIndexPage = true;

    // this.redirectToHomePage();

    this.markLandingPageAsShown();
  }
  leftLandingPage() {
    this._isIndexPage = false;
  }

  private markLandingPageAsShown() {
    if (this.isIndexPage && this.isInitialized && !this.isShownLandingPage) {
      this.isShownLandingPage = true;
    }
  }

  get shouldShowFunnelLandingPage() {
    return (
      !this.isLoggedIn &&
      (!this.isAccountCreated || this.isEligibleForFunnel) &&
      !this.isDismissed &&
      !this.isCompleted
    );
  }

  set numOfDemoBetsPlaced(value: number) {
    this._numOfDemoBetsPlaced = value;

    this.saveData();
  }

  onLearnedAboutDividends() {
    this.learnedAboutDividends = true;
    //this.app.dividends.stopDemo();
  }
  private set learnedAboutDividends(value: boolean) {
    this._learnedAboutDividends = value;

    this.saveData();
  }

  set isNewlyCreatedAccount(value: boolean) {
    if (value === false || this.isEligibleForFunnel) {
      this._isNewlyCreatedAccount = value;
    }
  }

  sync(data: ISignUpBonusProgressData, isAccountImported = false) {
    if (!this.isEligibleForFunnel) {
      return;
    }

    if (data.isAwarded && !this.isCompleted) {
      this._award = data.award;

      const hasReceivedAward = data.award != null;

      // add awarded amount to balance
      if (hasReceivedAward) {
        this.settings.balances.addAssetAmount(data.award);
      }

      this.isCompleted = true;

      /*** TODO: notify user that BET have been awarded! ***/
      /*
      if (!isAccountImported && hasReceivedAward) {
        this.modal.openFunnelChecklist();
      }
      */
    }

    // *** sync totalUSDDeposited from server ***
    if (data.totalDeposits != null) {
      const previousDeposits = this.totalUsdDeposited;

      this.totalUsdDeposited = Number(data.totalDeposits);

      if (
        this.totalUsdDeposited >= totalUsdDepositRequired &&
        previousDeposits < totalUsdDepositRequired
      ) {
        /*** TODO: notify user that deposits have been completed ***/
        //this.modal.openFunnelChecklist();
      }
    }

    // *** sync totalUSDBetVolume from server ***
    if (data.totalWagered != null) {
      this.totalUsdBetVolume = Number(data.totalWagered);
    }

    this.saveData();
  }

  private set isCompleted(value: boolean) {
    this._isCompleted = value;

    this.saveData();
  }

  dismiss() {
    this._isDismissed = true;

    this.saveData();
  }

  private saveData() {
    this.localData.save(this.stateData);
  }

  private get stateData(): ISignUpBonusStateData {
    return {
      numOfDemoBetsPlaced: this.numOfDemoBetsPlaced,
      learnedAboutDividends: this.learnedAboutDividends,
      deposits: this.totalUsdDeposited,
      wagers: this.totalUsdBetVolume,
      isDismissed: this.isDismissed,
      isCompleted: this.isCompleted,
      award: this.award,
    };
  }

  get award() {
    return this._award;
  }

  get currentStep(): IFunnelCTA | undefined {
    if (this.isCompleted) {
      return undefined;
    }

    /*
        if (this.demoBetsProgress < 100) {
            return this.demoBetsCTA;
        }

        if (this.dividendsProgress < 100) {
            return this.dividendsCTA;
        }
        */

    if (this.createAccountProgress < 100) {
      return this.createAccountCTA;
    }

    if (this.depositProgress < 100) {
      return this.depositCTA;
    }

    if (this.betsVolumeProgress < 100) {
      return this.realBetsCTA;
    }
  }

  get totalProgress() {
    return (
      // this.demoBetsProgress +
      // this.dividendsProgress +

      (this.createAccountProgress +
        this.depositProgress +
        this.betsVolumeProgress) /
      3
    );
  }
  /*
    get demoBetsProgress() {
        return this.numOfDemoBetsPlaced > 5 ?
                100 :
                this.numOfDemoBetsPlaced / 5 * 100;
    }
    get dividendsProgress() {
        return this.learnedAboutDividends ? 100 : 0;
    }
    */
  get createAccountProgress() {
    return this.isAccountCreated ? 100 : 0;
  }
  get depositProgress() {
    return this.totalUsdDeposited > totalUsdDepositRequired
      ? 100
      : (this.totalUsdDeposited / totalUsdDepositRequired) * 100;
  }
  get betsVolumeProgress() {
    return this.totalUsdBetVolume > totalUsdVolumeRequired
      ? 100
      : (this.totalUsdBetVolume / totalUsdVolumeRequired) * 100;
  }

  get numOfDemoBetsPlaced() {
    return this._numOfDemoBetsPlaced;
  }
  private get learnedAboutDividends() {
    return this._learnedAboutDividends;
  }
  private get isDismissed() {
    return this._isDismissed;
  }
  private get isCompleted() {
    return this._isCompleted;
  }

  get shouldShowProgressBar() {
    return (
      (!this.isIndexPage || !this.shouldShowFunnelLandingPage) &&
      !this.hasChosenToDismissProgressBar &&
      this.isLoggedIn &&
      this.shouldShowProgressBarIfLoggedIn
    );
  }

  private get isLoggedIn(): boolean {
    return this.authState.isLoggedIn;
  }

  private get shouldShowProgressBarIfLoggedIn(): boolean {
    /*
        DO NOT SHOW Progress Bar to Logged In User IF:
            1. They are a Legacy User who was already on-boarded, pre-funnel
            2. if they have chosen to dimiss it OR
            3. they have completed all the steps
        */
    return (
      this.isEligibleForFunnel &&
      !this.hasChosenToDismissProgressBar &&
      !this.hasCompletedAllSteps
    );
  }

  private get hasChosenToDismissProgressBar(): boolean {
    return this._isDismissed;
  }
  get hasCompletedAllSteps(): boolean {
    return this._isCompleted;
  }

  get isAccountCreated(): boolean {
    return Boolean(this.authState.authToken);
  }

  get isIndexPage() {
    return this._isIndexPage;
  }

  get isEligibleForFunnel() {
    return this._isEligibleForFunnel;
  }

  get isNewlyCreatedAccount() {
    return this._isNewlyCreatedAccount;
  }
}
