import { Injectable } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';

import { LeaderboardChart } from '../models/leaderboard-chart';
import { ILeaderboardData } from '../outputs/interfaces';
import { LeaderboardApiService } from './leaderboard-api.service';

@Injectable()
export class LeaderboardCharts {
  private chartsData: { [periodId: number]: ILeaderboardData } = {};

  private charts: LeaderboardChart[];
  private selectedIndex = 0;
  private leaderboardIds: number[] = [];

  private currentLeaderboardId = 0;

  constructor(
    private authState: AuthStateService,
    private api: LeaderboardApiService
  ) {}

  setCurrentLeaderboardData(data: ILeaderboardData) {
    this.setLeaderboardData(data);

    const isInit = this.currentLeaderboardId == 0;

    if (this.currentLeaderboardId != data.leaderboardPeriodId) {
      this.currentLeaderboardId = data.leaderboardPeriodId;

      this.createCharts();
    }

    this.charts[this.lastIndex] = new LeaderboardChart(data, this.authState);

    if (isInit) {
      this.selectedIndex = this.lastIndex;
    }
  }

  private createCharts() {
    this.generateLeaderboardIds(this.currentLeaderboardId);

    this.charts = new Array(this.leaderboardIds.length);
  }

  private generateLeaderboardIds(currentLeaderboardId: number) {
    this.leaderboardIds = [];

    for (let i = currentLeaderboardId; i >= 1; i--) {
      this.leaderboardIds.push(i);
    }

    this.leaderboardIds.sort((a, b) => a - b);
  }

  next() {
    if (this.hasNext) {
      this.selectedIndex++;

      return this.loadSelectedChart();
    }
  }

  previous() {
    if (this.hasPrevious) {
      this.selectedIndex--;

      return this.loadSelectedChart();
    }
  }

  private async loadSelectedChart() {
    let chart = this.selected;

    if (!chart) {
      const data = await this.getDataForChart(
        this.leaderboardIds[this.selectedIndex]
      );

      chart = new LeaderboardChart(data, this.authState);

      this.charts[this.selectedIndex] = chart;
    }
  }

  private async getDataForChart(
    leaderboardPeriodId: number
  ): Promise<ILeaderboardData> {
    let data: ILeaderboardData = this.chartsData[leaderboardPeriodId];

    if (!data) {
      data = await this.api.getLeaderboardByPeriod(leaderboardPeriodId);

      this.setLeaderboardData(data);
    }

    return data;
  }

  private setLeaderboardData(data: ILeaderboardData) {
    this.chartsData[data.leaderboardPeriodId] = data;
  }

  get selected() {
    return this.charts ? this.charts[this.selectedIndex] : undefined;
  }

  get latest() {
    return this.charts ? this.charts[this.lastIndex] : undefined;
  }

  get hasNext() {
    return this.selectedIndex < this.lastIndex;
  }
  get hasPrevious() {
    return this.selectedIndex > 0;
  }

  private get lastIndex() {
    return this.leaderboardIds.length - 1;
  }
}
