<div class="container">
  <div class="holder">
    <div class="content">
      <div class="logo">Featured games</div>
      <ng-carousel #carouselRef="ngCarousel" [config]="config">
        <ng-template [ngCarouselSlide]="carouselRef" let-item>
          <a
            routerLink="/games/{{ item.category }}/{{ item.producer }}/{{
              item.identifier
            }}"
          >
            <img
              class="card"
              src="assets/images/featured_games/{{ item.producer }}/{{
                item.identifier2
              }}.png"
            />
          </a>
        </ng-template>
      </ng-carousel>
      <div class="gradient"></div>
      <div class="gradient-2"></div>
      <button (click)="carouselRef.prev()">
        <img src="assets/icons/function/arrow_left.svg" />
      </button>
      <button (click)="carouselRef.next()">
        <img src="assets/icons/function/arrow_right.svg" />
      </button>
    </div>
  </div>
</div>
