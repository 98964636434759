import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselConfig } from 'ng-carousel-cdk/lib/carousel-config.type';
import { CarouselAlignMode, CarouselWidthMode } from 'ng-carousel-cdk';

import { SoundsHelperService } from 'src/app/global-state/services/sounds.service';
import { GamesListService } from 'src/app/external-games/services/games-list.service';
import { IExternalGame } from 'src/app/external-games/data/interfaces';
import { NavigationService } from 'src/app/navigation/navigation.service';

@Component({
  selector: 'featured-games',
  templateUrl: './featured-games.component.html',
  styleUrls: ['./featured-games.component.scss'],
})
export class FeaturedGamesComponent implements OnInit {
  config: CarouselConfig<IExternalGame> = {
    items: [],
  };

  constructor(
    private sounds: SoundsHelperService,
    private games: GamesListService,
    private navigator: NavigationService
  ) {
    this.loadGames();
  }

  ngOnInit(): void {}

  private async loadGames() {
    // TODO: can't find this game: curse of the werewolf

    const gameIds = [
      'pragmaticexternal:GorillaMayhem',
      'pragmaticexternal:DragoJewelsofFortune',
      'pragmaticexternal:MadameDestinyMegaways1',
      'pragmaticexternal:ExtraJuicyMegaways',
      'pragmaticexternal:FuryofOdinMegaways',
    ];

    const promises = gameIds.map((identifier) =>
      this.games.getGameByIdentifier(identifier)
    );

    const games = await Promise.all(promises);

    this.config = {
      widthMode: CarouselWidthMode.PX,
      alignMode: CarouselAlignMode.CENTER,
      autoplayEnabled: true,
      autoplayDelay: 10,
      slideWidth: 200,
      transitionDuration: 3000,
      shouldLoop: true,

      items: games,
    };
  }

  play(game: IExternalGame) {
    console.log('item clicked!');

    this.sounds.playLandingGame();

    this.navigator.gotoPage(
      'games/' + game.category + '/' + game.producer + '/' + game.identifier
    );
  }
}
