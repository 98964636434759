import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { RouterEvent } from '@angular/router';

import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from 'src/app/global-state/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppComponentBase {
  constructor(app: AppService) {
    super(app);
  }

  ngOnInit() {
    this.app.init();
  }

  onActivate(event: RouterEvent) {
    this.app.navigator.scrollToTopOfPage();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.settings.updateInnerWidth(window.innerWidth);
  }
}
