import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AlertService } from 'src/app/notifications/services/alert.service';
import { PostRequestPerformer } from '../../global-state/services/interfaces';

@Injectable()
export class FormsService {
  constructor(readonly alert: AlertService) {}

  async handleFormSubmission<T>(
    form: NgForm,
    performPostRequest: PostRequestPerformer<T>
  ) {
    console.log(form.value);

    this.alert.clear();

    if (form.invalid) {
      return;
    }

    try {
      this.alert.warning('Please Wait...');

      const response = await performPostRequest();

      this.alert.clear();

      // return response, if it is successful
      return response;
    } catch (error) {
      // the magic! display field specific errors on the form
      this.mapErrorsToFormFields(error, form);

      throw error;
    }
  }

  private mapErrorsToFormFields(
    error: {
      errorsMap?: { [fieldName: string]: { [errorName: string]: string } };
      message?: string;
    },
    form: NgForm
  ) {
    const { errorsMap } = error;

    if (errorsMap) {
      for (const fieldName in errorsMap) {
        const errors = errorsMap[fieldName];

        const allErrors: { [name: string]: boolean } = {};

        for (const errorName in errors) {
          allErrors[errors[errorName]] = true;
        }

        const control = form.controls[fieldName];

        if (control) {
          control.setErrors(allErrors);
        } else {
          console.error('control not found on form: ' + fieldName);
        }
      }

      this.alert.error('Check form for errors...');
    } else {
      console.error(error);

      this.alert.error(error.message as string);
    }
  }
}
