import { Component, OnInit } from '@angular/core';

import { GlobalVariablesService } from 'src/app/global-state/services/global-variables.service';
import { AppService } from 'src/app/global-state/services/app.service';
import { AppComponentBase } from 'src/app/global-state/components/app-component-base';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends AppComponentBase implements OnInit {
  incognito: boolean;

  constructor(
    private variableService: GlobalVariablesService,
    app: AppService
  ) {
    super(app);
  }

  ngOnInit(): void {
    this.variableService.currentPrivacy.subscribe(
      (privacyStatus) => (this.incognito = privacyStatus)
    );
  }

  public togglePrivacy(): void {
    this.variableService.updatePrivacy(!this.incognito);
  }

  closeMobileMenu() {
    if (this.isMobile) {
      this.sidebar.closePanel();
    }
  }
}
