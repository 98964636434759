import { ActivatedRoute } from '@angular/router';

export function getUrlParameterByName(name: string) {
  let url = window.location.href;

  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getPathForRoute(route: ActivatedRoute) {
  const url = route.snapshot.url[0].path;
  console.log({ url });

  return url;
}
