import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'level-badge',
  templateUrl: './level-badge.component.html',
  styleUrls: ['./level-badge.component.scss']
})
export class LevelBadgeComponent implements OnInit {
  @Input() level:string; // global value
  @Input() userRole?:string = 'user'; // moderator, admin, support, user

  rankReturn(level:any){
    if(level>=150){
      return '13'
    }
    if(level>=140){
      return '12'
    }
    if(level>=130){
      return '11'
    }
    if(level>=120){
      return '10'
    }
    if(level>=110){
      return '9'
    }
    if(level>=100){
      return '8'
    }
    if(level>=90){
      return '7'
    }
    if(level>=80){
      return '6'
    }
    if(level>=70){
      return '5'
    }
    if(level>=60){
      return '4'
    }
    if(level>=50){
      return '3'
    }
    if(level>=25){
      return '2'
    }
    if(level>=1){
      return '1'
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
