import { io } from 'socket.io-client';

import { IServerConfig } from './interfaces';

export abstract class SocketClientBase {
  // CLASS members:

  // INSTANCE members:
  constructor(private config: IServerConfig, private nameSpace: string) {}

  // *** ESTABLISH CONNECTION to SocketServer ***
  protected connectSocket(socketOptions?: any) {
    // connect scoket
    const url =
      'http' +
      (this.config.ssl === true ? 's' : '') +
      '://' +
      this.config.host +
      ':' +
      this.config.port +
      this.nameSpace;

    console.log({ socketUrl: url });

    const socket = io(url, socketOptions);

    socket.on('connect', this.onConnect);
    socket.on('disconnect', this.onConnect);

    socket.on('connect_error', this.onError);
    socket.on('error', this.onError);

    return socket;
  }
  private onConnect = () => {
    this.onConnected();
  };
  protected abstract onConnected(): void;
  private onError = (error: Error) => {
    console.log('onError');
    console.log(error);
  };
  private onDisconnect = () => {
    this.onDisconnected();
  };
  protected abstract onDisconnected(): void;
}
