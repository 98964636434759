<div class="bonus" [class.mobile]="isMobile">
  <div
    ngbDropdown
    #drop="ngbDropdown"
    class="d-inline-block"
    (mouseover)="onMouseOver(drop)"
    [placement]="buttonPlacement"
  >
    <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
      <img src="assets/images/banners/gift_box.png" alt="" />
    </button>
    <div
      ngbDropdownMenu
      (mouseout)="onMouseOut(drop)"
      aria-labelledby="dropdownBasic1"
      [attr.placement]="menuPlacement"
    >
      <div class="triangle"></div>
      <div class="info">
        <h6>FREE $50 in BTC</h6>
        <p>
          <ngb-progressbar
            type=""
            [value]="depositProgress"
            [class.full]="depositProgress == 100"
          >
            <div class="checkpoint c-1">
              <span>Deposit</span>
            </div>
            <div class="checkpoint c-3">
              <span>$100</span>
            </div>
          </ngb-progressbar>
        </p>
        <p>
          <ngb-progressbar
            type=""
            [value]="wagerProgress"
            [class.full]="wagerProgress == 100"
          >
            <div class="checkpoint c-1">
              <span>Wager</span>
            </div>
            <div class="checkpoint c-3">
              <span>$5000</span>
            </div>
          </ngb-progressbar>
        </p>
      </div>
    </div>
  </div>
</div>
