import { ITranslation } from './translation';
import { TranslationId } from './translation-id';

class English implements ITranslation {
  translationId = TranslationId.ENGLISH;
  isEnglish = true;
  altForFlag = 'EN';

  theMinimumBetIs: string = 'The minimum bet is';
  theMaximumBetIs: string = 'The maximum bet is';

  // TODO:
  same: string;
  lower: string;
  lowerOrSame: string;
  higher: string;
  higherOrSame: string;

  placeBet: string = 'Place Bet';
  cashOut: string = 'Cash Out';
  clearCards: string = 'Clear Cards';
}

export const ENGLISH = new English();
