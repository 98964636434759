import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalVariablesService {
  private language = new BehaviorSubject('EN');
  currentLanguage = this.language.asObservable();

  private privacy = new BehaviorSubject(false);
  currentPrivacy = this.privacy.asObservable();

  private fullScreenState = new BehaviorSubject(false);
  currentfullScreenState = this.fullScreenState.asObservable();

  constructor() {
    console.log('GlobalVaariablesService instantiated');
  }

  updateLanguage(languageStatus: string): void {
    this.language.next(languageStatus);
  }

  updatePrivacy(privacyStatus: boolean): void {
    this.privacy.next(privacyStatus);
  }

  updateFullScreenState(currentfullScreenState: boolean): void {
    this.fullScreenState.next(currentfullScreenState);
    if (currentfullScreenState) {
      // TODO: close menu when full screen mode is activated
      //this.updateMenuState(false);
    }
  }
}
