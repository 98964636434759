import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TokensComponentBase } from '../base/tokens-component-base';
import { TokensService } from 'src/app/internal-games/services/tokens.service';

@Component({
  selector: 'div[currencyDropDown]',
  templateUrl: './currency-drop-down.component.html',
  styleUrls: ['./currency-drop-down.component.scss'],
  host: {
    class: 'form-input-holder',
  },
})
export class CurrencyDropDownComponent extends TokensComponentBase {
  @Input()
  shouldGlow = false;

  @Output()
  stopGlow = new EventEmitter();

  constructor(tokens: TokensService) {
    super(tokens);
  }
}
