import { Component, Input, OnInit } from '@angular/core';
import { IChatUser } from 'src/app/chat/outputs/interfaces';
import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from 'src/app/global-state/services/app.service';
import { TipsService } from '../../services/tips.service';
import Big from 'big.js';

@Component({
  selector: 'tip-user',
  templateUrl: './tip-user.component.html',
})
export class TipUserComponent extends AppComponentBase implements OnInit {
  @Input() modal: any;

  @Input() user: IChatUser;

  amount = 0;

  constructor(app: AppService, private service: TipsService) {
    super(app);
  }

  ngOnInit(): void {}

  async sendTip() {
    if (!this.user) {
      return;
    }

    if (!this.isLoggedIn) {
      this.alert.warning('Please login first to send a tip...');
    }

    const amount = this.selectedToken.getCurrencyAmount(this.amount);

    if (amount.integer == 0) {
      return;
    }

    const maxAmount = this.selectedTokenBalanceForWithdrawal;

    if (new Big(amount.decimal).gt(maxAmount)) {
      this.alert.error(
        'The maximum tip you can send is: ' +
          maxAmount +
          ' ' +
          this.selectedToken.symbol
      );

      return;
    }

    this.alert.warning('Please Wait...');

    await this.service.sendTip({
      to_user_id: this.user.user_id,
      currency_symbol: this.selectedToken.symbol,
      decimalAmount: amount.decimal,
    });

    this.close();
  }

  close() {
    this.modal.close('Save click');
  }

  get username() {
    return this.user?.username;
  }
}
