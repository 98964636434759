import { Injectable } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { BankrollService } from 'src/app/internal-games/services/bankroll-service';
import { TokensService } from 'src/app/internal-games/services/tokens.service';
import { LeaderboardService } from 'src/app/leaderboard/services/leaderboard.service';
import { ModalsService } from 'src/app/modals/modals.service';
import { NavigationService } from 'src/app/navigation/navigation.service';
import { AlertService } from 'src/app/notifications/services/alert.service';
import { SidebarStateService } from 'src/app/sidebar/services/sidebar-state.service';
import { SignUpBonusService } from 'src/app/sign-up-bonus/services/sign-up-bonus.service';
import { DividendsService } from 'src/app/dividends/services/dividends.service';

import { AppApiService } from './app-api.service';
import { AppEventsService } from './app-events.service';
import { AppSettings } from './app-settings.service';
import { AudioService } from './audio.service';
import { IAppService } from './interfaces';
import { NotificationsService } from './notifications.service';
import { UserStateService } from './user-state.service';

import { StakingStatisticsService } from 'src/app/staking/services/staking-stats.service';
import { ResolvedBets } from 'src/app/bets/services/resolved-bets';
import { ExternalGamesClient } from 'src/app/external-games/services/external-games-client';
import { ExternalGameEventsService } from 'src/app/external-games/services/game-events.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { RainStateService } from 'src/app/rain/services/rain-state.service';

@Injectable()
// the purpose of this service is to combine multiple shared services
// no methods should be added directly to this service
export class AppService implements IAppService {
  constructor(
    readonly authState: AuthStateService,
    readonly settings: AppSettings,
    readonly events: AppEventsService,
    readonly bankroll: BankrollService,
    readonly sidebarState: SidebarStateService,
    readonly navigator: NavigationService,
    readonly audio: AudioService,
    readonly alert: AlertService,
    readonly signUpBonus: SignUpBonusService,
    readonly leaderboard: LeaderboardService,
    readonly dividends: DividendsService,
    readonly stakingStats: StakingStatisticsService,
    readonly bets: ResolvedBets,
    readonly userState: UserStateService,
    readonly notifications: NotificationsService,
    readonly errorHandler: ErrorHandlerService,
    readonly api: AppApiService,
    readonly tokens: TokensService,
    readonly modals: ModalsService,
    readonly externalGamesClient: ExternalGamesClient,
    readonly externalGameEvents: ExternalGameEventsService,
    readonly googleAnalytics: GoogleAnalyticsService,
    readonly rainState: RainStateService
  ) {}

  init() {
    this.events.init();

    this.notifications.connect();

    this.signUpBonus.state.init();

    this.audio.init();
    this.sidebarState.init(this.settings.isDesktop);
  }

  logout() {
    this.authState.onLogout();

    this.navigator.gotoHomePage();
  }

  get balances() {
    return this.settings.balances;
  }

  get userId() {
    return this.authState.userId;
  }
}
