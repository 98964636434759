import {
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from 'src/app/global-state/services/app.service';
import { RainApiService } from '../../services/rain-api.service';

@Component({
  selector: 'rain-banner',
  templateUrl: './rain-banner.component.html',
  styleUrls: ['./rain-banner.component.scss'],
})
export class RainBannerComponent
  extends AppComponentBase
  implements OnInit, OnDestroy
{
  animationRain: AnimationItem;
  optionsRain: AnimationOptions = {
    path: 'assets/animations/banner/rain.json',
    loop: true,
    autoplay: true,
  };

  @Output()
  closed = new EventEmitter<null>();

  private isRaining = false;
  private _progress = 0;

  private intervalId: NodeJS.Timeout;

  constructor(
    app: AppService,
    private api: RainApiService,
    private ngZone: NgZone
  ) {
    super(app);
  }

  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      const state = this.app.rainState;

      this.isRaining = state.isRaining;
      this._progress = state.progress;
    }, 1000);
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  // to start the rain animations, will this solve the bug with the animations on home page?
  animationCreated(animationItem: AnimationItem): void {
    this.animationRain = animationItem;
    this.ngZone.runOutsideAngular(() => {
      this.animationRain.play();
    });
  }

  login() {
    this.app.navigator.gotoPage('login');
  }

  async claim() {
    const eventId = this.app.rainState.eventId;

    const response = await this.api.claim();

    this.app.rainState.onRainClaimed(eventId);
  }

  close() {
    this.closed.emit();
  }

  get stage() {
    // 1 not logged in
    // 2 waiting for rain
    // 3 rain but not claimed
    // 4 raining but claimed
    return this.isLoggedIn
      ? this.isRaining
        ? this.app.rainState.isClaimed
          ? 4
          : 3
        : 2
      : 1;
  }

  get progress() {
    return this._progress;
  }
}
