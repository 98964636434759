import { Injectable } from '@angular/core';

enum SidebarTab {
  Chat = 'chat',
  Menu = 'menu',
  Leaderboard = 'leaderboard',
}

@Injectable()
export class SidebarStateService {
  private _isPanelOpen = false;

  private _currentTab = SidebarTab.Chat;

  constructor() {}

  init(openPanel: boolean) {
    console.log('sidebar init:', { openPanel });
    this._isPanelOpen = openPanel;
  }

  togglePanel(isForMobile = false) {
    if (isForMobile) {
      this.showMenu();
    }
    this._isPanelOpen = !this._isPanelOpen;

    console.log('toggleSidebarPanel:', { isPanelOpen: this.isPanelOpen });
  }

  showChat() {
    this.currentTab = SidebarTab.Chat;

    // TODO: is this still relevant?
    //this.chat.state.resetUnseenMessages();
  }

  showMenu() {
    this.currentTab = SidebarTab.Menu;
  }

  showLeaderboard() {
    this.currentTab = SidebarTab.Leaderboard;
  }

  closePanel() {
    this._isPanelOpen = false;

    console.log('sidebar panel closed');
  }

  set currentTab(tab: SidebarTab) {
    this._currentTab = tab;
  }
  get currentTab() {
    return this._currentTab;
  }

  /*
  get showChatTab(): boolean {
    return this.isPanelOpen && this.currentTab == SidebarTab.Chat;
  }

  get showLeaderboardTab(): boolean {
    return this.isPanelOpen && this.currentTab == SidebarTab.Leaderboard;
  }
  */

  get isPanelOpen() {
    return this._isPanelOpen;
  }
}
