<div class="tab-table">
    <div class="header">
        <span class="col-4 col-md-5">Game</span>
        <span class="col-3 col-md-2">Bets</span>
        <span class="col-5 col-md-3">Total Wagered</span>
    </div>
    <div class="tab-card" *ngFor="let game of userData.gameStatistics; let i = index">
        <div class="row">
            <div class="col-4 col-md-5">
                <span>{{ game.gameName }}</span>
            </div>
            <div class="col-3 col-md-2">
                <span>{{ game.bets | number }}</span>
            </div>
            <div class="col-5 col-md-3">
                <span>${{ game.totalWagered | number }}</span>
            </div>
        </div>
    </div>
</div>