import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LevelBadgeComponent } from './components/level-badge/level-badge.component';
import { UserBetStatisticsComponent } from './components/user-bet-statistics/user-bet-statistics.component';
import { UserGameStatisticsComponent } from './components/user-game-statistics/user-game-statistics.component';
import { UserStatsComponent } from './components/user-stats/user-stats.component';
import { UserTrophiesComponent } from './components/user-trophies/user-trophies.component';

// we create a shared module that will contain the components we want to share
@NgModule({
  imports: [NgbModule, CommonModule],
  declarations: [
    // let's add the UserStatsComponent to this shared module as well, we might use it in other places other than the chat module
    UserStatsComponent,
    UserBetStatisticsComponent,
    UserGameStatisticsComponent,
    UserTrophiesComponent,
    LevelBadgeComponent,
  ],

  exports: [
    // we need to export the components so that they are available in the other modules that import this module
    UserStatsComponent,
    UserBetStatisticsComponent,
    UserGameStatisticsComponent,
    UserTrophiesComponent,
    LevelBadgeComponent,

    NgbModule,
    CommonModule,
  ],
})
export class SharedUsersModule {}
