import { Injectable } from '@angular/core';

import { TokenSettingsService } from 'src/app/currency/tokens/services/token-settings.service';

import {
  IUserStakeTableRow,
  IStakeRecordRow,
  IUnstakeRecordRow,
} from '../models/interfaces';
import {
  getStakingLevelIndex,
  stakingLevels,
} from '../models/staking-level-data';
import { StakeRecord, UnstakeRecord } from '../models/staking-records';

@Injectable()
export class StakingData {
  private _levelIndex: number | undefined;

  private _info: IUserStakeTableRow;
  stakedRecords: StakeRecord[] = [];
  unstakedRecords: UnstakeRecord[] = [];

  constructor(readonly tokenSettings: TokenSettingsService) {}

  update({
    info,
    stakedRecords,
    unstakedRecords,
  }: {
    info: IUserStakeTableRow;
    stakedRecords: IStakeRecordRow[];
    unstakedRecords: IUnstakeRecordRow[];
  }) {
    // set info
    this.info = info;
    // set staked records
    this.setStakedRecords(stakedRecords);
    // set unstaked records
    this.setUnstakedRecords(unstakedRecords);
  }

  set info(value: IUserStakeTableRow) {
    this._info = value;
    this.setLevel();
  }
  private setLevel() {
    this._levelIndex = getStakingLevelIndex(this.stakeWeight);
  }

  private setStakedRecords(value: IStakeRecordRow[]) {
    this.stakedRecords = [];

    for (const data of value) {
      this.stakedRecords.push(new StakeRecord(data));
    }
  }

  private setUnstakedRecords(value: IUnstakeRecordRow[]) {
    this.unstakedRecords = [];

    for (const data of value) {
      const stakeRecord = this.deleteStakeRecord(data.stake_id);

      if (stakeRecord) {
        this.unstakedRecords.push(new UnstakeRecord(data, stakeRecord));
      }
    }
  }
  private deleteStakeRecord(stake_id: number) {
    for (let i = 0; i < this.stakedRecords.length; i++) {
      if (this.stakedRecords[i].id == stake_id) {
        return this.stakedRecords.splice(i, 1)[0];
      }
    }
  }

  get dividendsPercentage(): number {
    return this.info ? Number(this.info.share_percentage || 0) / 100 : 0;
  }

  get houseEdgeReductionAsDecimal() {
    return this.houseEdgeReduction / 100;
  }
  get houseEdgeReduction() {
    return this.level ? this.level.houseEdgeReduction : 0;
  }
  get baccaratEdgeReduction() {
    return this.level ? 1.06 - this.level.baccaratHouseEdge : 0;
  }

  get level() {
    return this.levelIndex ? stakingLevels[this.levelIndex] : null;
  }

  get levelIndex() {
    return this._levelIndex;
  }

  get stakePoints() {
    return this.stakeWeight;
  }
  get stakeWeight() {
    return this.info ? this.info.total_stake_weight : '0';
  }

  get totalTokensStaked(): number {
    const quantity = this.info
      ? this.info.total_stake_amount.toString()
      : '0 BET';

    return Number(quantity.split(' ')[0]);
  }

  get accountName() {
    return this.info.account;
  }

  get info() {
    return this._info;
  }
}
