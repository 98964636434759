<div
  class="background"
  [class.transaction]="transaction"
  [ngClass]="'bg-' + stage"
  *ngIf="stage == 1"
>
  <div class="buttons-claim">
    <div class="bg-holder">
      <img src="assets/images/banners/claim_banner_img_1.svg" />
    </div>
    <div class="info">
      <p>SIGN UP FOR $50 BTC</p>
      <button (click)="navigator.gotoPage('register')">Register</button>
    </div>
  </div>
</div>

<div
  class="background"
  [class.transaction]="transaction"
  [ngClass]="'bg-' + stage"
  *ngIf="stage == 2"
>
  <div class="buttons-claim">
    <div class="bg-holder">
      <img
        class="small-background"
        src="assets/images/banners/claim_banner_img_2.svg"
      />
      <img
        class="big-background"
        src="assets/images/banners/claim_banner_img_2_big.svg"
      />
    </div>
    <div class="info">
      <h6 *ngIf="!transaction">FREE $50 in BTC</h6>
      <h6 *ngIf="transaction">ALMOST READY TO CLAIM YOUR FREE $50 IN BTC</h6>
      <p>
        <span class="icon deposit-icon" [class.full]="depositProgress == 100">
          <img src="" alt="" />
        </span>
        <ngb-progressbar
          type=""
          [value]="depositProgress"
          [class.full]="depositProgress == 100"
          placement="top-right"
          [popoverTitle]="'$' + depositProgressAmount + '/$100 completed'"
          ngbPopover="Deposit
          more to claim
          $50 in BTC"
          triggers="mouseenter:mouseleave"
          [disablePopover]="depositProgress == 100"
        >
          <div class="checkpoint c-1">
            <span>Deposit</span>
          </div>
          <div class="checkpoint c-3">
            <span>$100</span>
          </div>
        </ngb-progressbar>
      </p>
      <p>
        <span class="icon wager-icon" [class.full]="wagerProgress == 100">
          <img src="" alt="" />
        </span>
        <ngb-progressbar
          type=""
          [value]="wagerProgress"
          [class.full]="wagerProgress == 100"
          placement="top-right"
          [popoverTitle]="wagerProgressAmount + '% completed'"
          ngbPopover="Wager
          more to claim
          $50 in BTC"
          triggers="mouseenter:mouseleave"
          [disablePopover]="wagerProgress == 100"
        >
          <div class="checkpoint c-1">
            <span>Wager</span>
          </div>
          <div class="checkpoint c-3">
            <span>$5000</span>
          </div>
        </ngb-progressbar>
      </p>
    </div>
  </div>
</div>

<div
  class="background"
  [class.transaction]="transaction"
  [ngClass]="'bg-' + stage"
  *ngIf="stage == 3 && !transaction"
>
  <div class="buttons-claim">
    <div class="bg-holder">
      <img src="assets/images/banners/claim_banner_img_1.svg" />
    </div>
    <div class="info">
      <p>CONGRATULATIONS!</p>
      <button>Claim $50 in BTC</button>
    </div>
  </div>
</div>
