import { environment } from '../../../environments/environment';

enum ScriptName {
  ClipboardJS,
  EosJs,
  EosJsEcc,
  SocketIo,
  WaxJs,
  ScatterJs,
  ScatterJsEosPlugin,
  Bip39WordListEnglish,
  Bip39Sjcl,
  Bip39Js,
  Recaptcha,
}

interface IJavascriptFile {
  name: ScriptName;
  url: string;
}

interface IJavascriptFileInfo {
  url: string;
  isLoaded: boolean;
}

const scripts: IJavascriptFile[] = [
  {
    name: ScriptName.ClipboardJS,
    url: 'https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.0/clipboard.min.js',
  },
  {
    name: ScriptName.SocketIo,
    url: 'assets/js/socket.io.js',
  },
  {
    name: ScriptName.EosJs,
    url: 'https://cdn.jsdelivr.net/npm/eosjs@16.0.9/lib/eos.min.js',
  },
  {
    name: ScriptName.EosJsEcc,
    url: 'https://cdn.jsdelivr.net/npm/eosjs-ecc@4.0.4/lib/eosjs-ecc.min.js',
  },
  {
    name: ScriptName.ScatterJs,
    url: 'assets/js/scatterjs-core.min.js?v=0.0.3',
  },
  {
    name: ScriptName.ScatterJsEosPlugin,
    url: 'assets/js/scatterjs-plugin-eosjs.min.js?v=0.0.3',
  },
  {
    name: ScriptName.Bip39WordListEnglish,
    url: 'assets/js/jsbip39/wordlist_english.js',
  },
  {
    name: ScriptName.Bip39Sjcl,
    url: 'assets/js/jsbip39/sjcl-bip39.js',
  },
  {
    name: ScriptName.Bip39Js,
    url: 'assets/js/jsbip39/jsbip39.js',
  },
  {
    name: ScriptName.WaxJs,
    url: 'assets/js/waxjs.js?v=2',
  },
  {
    name: ScriptName.Recaptcha,
    url: `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaSiteKey}`,
  },
];

const status: { [name: number]: IJavascriptFileInfo } = {};

for (const script of scripts) {
  status[script.name] = {
    url: script.url,
    isLoaded: false,
  };
}

export function loadClipboardJs() {
  return loadScript(ScriptName.ClipboardJS);
}
export function loadEosJs() {
  return loadScript(ScriptName.EosJs);
}
export function loadEosJsEcc() {
  return loadScript(ScriptName.EosJsEcc);
}
export function loadSocketIo() {
  return loadScript(ScriptName.SocketIo);
}
export async function loadScatterJs() {
  await loadScript(ScriptName.ScatterJs);
  await loadScript(ScriptName.ScatterJsEosPlugin);
}
export async function loadBip39() {
  await Promise.all([
    loadScript(ScriptName.Bip39WordListEnglish),
    loadScript(ScriptName.Bip39Sjcl),
    loadScript(ScriptName.Bip39Js),
  ]);
}
export function loadWaxJs() {
  return loadScript(ScriptName.WaxJs);
}

export async function loadRecaptcha(id: string) {
  await loadScript(ScriptName.Recaptcha, id);
}

function loadScript(name: ScriptName, id?: string) {
  return new Promise<any>((resolve, reject) => {
    const info = status[name];

    // resolve if already loaded
    if (info.isLoaded) {
      resolve({ script: name, loaded: true, status: 'Already Loaded' });
    } else {
      // load script
      const script: any = document.createElement('script');
      script.type = 'text/javascript';

      script.id = id || '';
      script.src = info.url;

      if (script.readyState) {
        // IE
        script.onreadystatechange = () => {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;

            status[name].isLoaded = true;

            console.log('LOADED: ' + info.url);

            resolve({ script: name, loaded: true, status: 'Loaded' });
          }
        };
      } else {
        // Others
        script.onload = () => {
          status[name].isLoaded = true;

          console.log('LOADED: ' + info.url);

          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
      }

      script.onerror = (error: any) =>
        reject({ script: name, loaded: false, status: 'Loaded' });

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  });
}
