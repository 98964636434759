<div class="modal-width stats">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="stats-header">
      <div class="username">
        <level-badge level="65" userRole="user"></level-badge>
        <p>Player6238</p>
      </div>
      <span class="date">Joined on March 20, 2021</span>
      <div class="user-actions">
        <button>
          <img src="assets/icons/function/dollar_sign.svg" />
          <span>Tip</span>
        </button>
        <button>
          <img src="assets/icons/function/eye_crossed.svg" />
          <span>Ignore</span>
        </button>
      </div>
    </div>
    <ul ngbNav #navUser="ngbNav" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink>Statistics</a>
        <ng-template ngbNavContent>
          <user-bet-statistics></user-bet-statistics>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Game stats</a>
        <ng-template ngbNavContent>
          <user-game-statistics></user-game-statistics>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Trophies</a>
        <ng-template ngbNavContent>
          <user-trophies></user-trophies>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="navUser"></div>
  </div>
  <div class="modal-footer"></div>
</div>
