import { BigSource } from 'big.js';

import { CurrencyAmount } from '../../amount/currency-amount';
import { ICurrencyToken, ICurrencyTokenData } from './interfaces';

export class CurrencyToken implements ICurrencyToken {
  constructor(private data: ICurrencyTokenData) {}

  getCurrencyAmount(value: BigSource) {
    return new CurrencyAmount(this, value);
  }

  get symbol() {
    return this.data.symbol;
  }

  get precision() {
    return this.data.precision;
  }
}
