<div ngbDropdown>
  <button
    *ngIf="selectedToken"
    type="button"
    class="btn btn-outline-primary"
    id="dropdownBasic2"
    ngbDropdownToggle
  >
    {{ selectedToken.balance }}
    <img
      src="assets/icons/crypto/{{
        selectedToken.symbol.toLowerCase()
      }}-logo.svg"
    />
  </button>

  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
    <div class="scroll-holder">
      <div *ngFor="let token of tokens">
        <button
          (click)="settings.selectCurrency(token.symbol)"
          [class.zeroAmount]="hideZeroValues && isZero(token.balance)"
          ngbDropdownItem
        >
          <span>{{ token.balance }}</span>
          <span>
            <img
              src="assets/icons/crypto/{{
                token.symbol.toLowerCase()
              }}-logo.svg"
            />
            {{ token.symbol }}
          </span>
        </button>
      </div>
    </div>

    <!-- hide zero balances -->
    <div class="toggler-holder">
      <label class="toggle-switch-simple">
        <input type="checkbox" [(ngModel)]="hideZeroValues" />
        <div class="slider" [ngClass]="hideZeroValues ? 'ON' : 'OFF'">
          <span
            class="icognito-icon"
            [ngClass]="hideZeroValues ? 'ON' : 'OFF'"
            >{{ hideZeroValues ? "On" : "Off" }}</span
          >
        </div>
      </label>
      <span>Hide zero balances</span>
    </div>
  </div>
</div>

<a routerLink="/deposit" (mouseenter)="hoverMenu()" class="system-btn"
  >Deposit</a
>
<span
  class="walletChange"
  [class.win]="showBalanceIncrease"
  [class.lose]="showBalanceDecrease"
  >{{ balanceChangeAmount }}</span
>

<!--
<button (click)="testBalanceChange()">Test</button>
-->
