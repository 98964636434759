import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-game-statistics',
  templateUrl: './user-game-statistics.component.html',
  styleUrls: ['./user-game-statistics.component.scss']
})
export class UserGameStatisticsComponent implements OnInit {
  userData = {
    gameStatistics:[
      {gameName:'Blackjack', bets:1000, totalWagered: 1225255},
      {gameName:'Dice', bets:1000, totalWagered: 1225255},
      {gameName:'Baccarat', bets:1000, totalWagered: 1225255},
      {gameName:'Hilo', bets:1000, totalWagered: 1225255},
      {gameName:'Crash', bets:1000, totalWagered: 1225255},
      {gameName:'Slots', bets:1000, totalWagered: 1225255},
      {gameName:'Live Game Shows', bets:1000, totalWagered: 1225255},
      {gameName:'Table Games', bets:1000, totalWagered: 1225255},
      {gameName:'Live Casino', bets:1000, totalWagered: 1225255}
    ],
  }
  constructor() { }

  ngOnInit(): void {
  }

}
