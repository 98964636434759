import { IStakingLockUpPeriod } from './interfaces';

export const stakingLockUpPeriods: IStakingLockUpPeriod[] = [
  {
    days: 1,
    pointsMultiplier: 1,
    label: '1 Day',
    shortLabel: '1 D',
  },
  {
    days: 7,
    pointsMultiplier: 1.1,
    label: '7 Days',
    shortLabel: '7 D',
  },
  {
    days: 30,
    pointsMultiplier: 1.25,
    label: '30 Days',
    shortLabel: '30 D',
  },
  {
    days: 90,
    pointsMultiplier: 1.5,
    label: '90 Days',
    shortLabel: '90 D',
  },
  {
    days: 365,
    pointsMultiplier: 2.25,
    label: '1 Year',
    shortLabel: '1 Y',
  },
  {
    days: 730,
    pointsMultiplier: 5,
    label: '2 Years',
    shortLabel: '2 Y',
  },
  {
    days: 1825,
    pointsMultiplier: 8.88,
    label: '5 Years',
    shortLabel: '5 Y',
  },
];

export const longestLockUpPeriod =
  stakingLockUpPeriods[stakingLockUpPeriods.length - 1];

export function getLockupPeriod(days: number): IStakingLockUpPeriod {
  for (const period of stakingLockUpPeriods) {
    if (period.days == days) {
      return period;
    }
  }

  throw new Error('Lock Up Period Not Found, Days: ' + days);
}
