import { Injectable } from '@angular/core';

import { AudioService } from './audio.service';

@Injectable()
// TODO: to be deprecated and use Audio Service instead?
export class SoundsHelperService {
  constructor(private audio: AudioService) {}

  playCardFlip() {
    this.audio.playFile('assets/sounds/CardFlip_v3.mp3');
  }
  playChipsAdd() {
    this.audio.playFile('assets/sounds/ChipsOnTable_v2.mp3');
  }
  playConfetti() {
    this.audio.playFile('assets/sounds/Confetti_v2.mp3');
  }
  playDepositHover() {
    this.audio.playFile('assets/sounds/DepositHover_v1.mp3');
  }
  playDiceRoll() {
    this.audio.playFile('assets/sounds/DiceRoll_v3.mp3');
  }
  playDiceScroll() {
    return this.audio.playFile('assets/sounds/DiceRoll_v3.mp3');
  }
  playDiceWin() {
    this.audio.playFile('assets/sounds/DiceWin_v1.mp3');
  }
  playLandingGame() {
    this.audio.playFile('assets/sounds/LandingPage_Click_v4.mp3');
  }
  playLoseGame() {
    this.audio.playFile('assets/sounds/LoseSound_v4.mp3');
  }
  playWinGame() {
    this.audio.playFile('assets/sounds/WinSoundGreenBox_v3.mp3');
  }
  playPlaceBetClick() {
    this.audio.playFile('assets/sounds/PlaceBet_Click.mp3');
  }
  playPlaceBetHover() {
    this.audio.playFile('assets/sounds/PlaceBet_Hover.mp3');
  }
}
