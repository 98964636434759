import { ENGLISH } from '../models/english';
import { ITranslation } from '../models/translation';

import { ITranslationManager, ITranslationManagerListener } from './interfaces';
import { TranslationManagerBase } from './translation-manager-base';

export class TranslationManager
  extends TranslationManagerBase<ITranslation>
  implements ITranslationManager
{
  private listener: ITranslationManagerListener;

  constructor() {
    super();
  }

  setListener(listener: ITranslationManagerListener) {
    this.listener = listener;
  }

  selectLanguage(id: string) {
    super.selectLanguage(id);

    if (this.listener) {
      this.listener.onLanguageChanged();
    }
  }

  protected getEnglish() {
    return ENGLISH;
  }

  protected getChinese(): ITranslation {
    throw new Error('unimplemented');
  }
  protected getKorean(): ITranslation {
    throw new Error('unimplemented');
  }

  protected getJapanese(): ITranslation {
    // return JAPANESE;
    // console.log('getJapanese');

    throw new Error('unimplemented');
  }
}
