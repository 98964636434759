import { CountdownData } from 'src/app/util/date-util';
import { IStakeRecordRow, IUnstakeRecordRow } from './interfaces';
import { longestLockUpPeriod } from './lock-up-periods';

abstract class RecordBase {
  private readonly expiry: CountdownData;

  constructor(datetime: number) {
    const expiryDate = new Date(datetime);

    this.expiry = new CountdownData(expiryDate);
  }

  get timeLeft() {
    // if at least 1 day then show number of Days

    // if less than 1 day then show HH:MM:SS

    // unlocked

    if (this.isExpired) {
      return this.labelForExpiry;
    } else {
      if (this.expiry.days > 0) {
        return this.expiry.days + ' Day' + (this.expiry.days > 1 ? 's' : '');
      } else if (this.expiry.hours > 0) {
        return this.expiry.hours + ' Hour' + (this.expiry.hours > 1 ? 's' : '');
      } else if (this.expiry.minutes > 0) {
        return (
          this.expiry.minutes + ' Minute' + (this.expiry.minutes > 1 ? 's' : '')
        );
      } else {
        return (
          this.expiry.seconds + ' Second' + (this.expiry.seconds > 1 ? 's' : '')
        );
      }

      /*
            return this.expiry.days + ':' +
                    this.expiry.hours + ':' +
                    this.expiry.minutes + ':' +
                    this.expiry.seconds;
            */
    }
  }

  protected get labelForExpiry() {
    return 'Unlocked';
  }

  get weeksRemainingForEmergencyUnstake() {
    return Math.ceil(
      (this.expiry.endTime.getTime() - Date.now()) / (1000 * 60 * 60 * 24 * 7)
    );
  }

  get isExpired() {
    return Date.now() >= this.expiry.endTime.getTime();
  }
}

export class StakeRecord extends RecordBase {
  constructor(private readonly data: IStakeRecordRow) {
    super(data.unstake_time);
  }

  get points() {
    return this.data.stake_weight;
  }

  get tokensStaked() {
    return Number(this.data.amount);
  }

  get stakePeriod() {
    const days = this.days;

    return days + ' Day' + (days > 1 ? 's' : '');
  }

  get days() {
    return this.data.stake_period;
  }

  get isUpgradeable() {
    return this.data.stake_period < longestLockUpPeriod.days;
  }

  get isUnlocked() {
    return this.isExpired;
  }

  get id() {
    return this.data.stake_id;
  }

  get amount() {
    return this.data.amount;
  }
}

export class UnstakeRecord extends RecordBase {
  readonly stakePeriod: string;
  readonly tokensStaked: number;

  constructor(data: IUnstakeRecordRow, record: StakeRecord) {
    super(data.send_time as number);

    this.stakePeriod = record.stakePeriod;
    this.tokensStaked = record.tokensStaked;
  }
}
