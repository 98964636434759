import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card-scroll',
  templateUrl: './card-scroll.component.html',
  styleUrls: ['./card-scroll.component.scss'],
})
export class CardScrollComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  pause = [false, false, false, false];

  pauseAnimation(i: number) {
    this.pause[i] = !this.pause[i];
  }
}
