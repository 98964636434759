import { LocalStorageData } from 'src/app/global-state/util/local-storage-util';
import { getUrlParameterByName } from 'src/app/http/util/url-util';
import { ITranslationBase } from '../models/interfaces';
import { TranslationId } from '../models/translation-id';
import { ITranslationManager } from './interfaces';

class BrowserLanguageCode {
  static ENGLISH = 'en';
  static CHINESE = 'zh';
  static KOREAN = 'ko';
  static JAPANESE = 'ja';
}

export abstract class TranslationManagerBase<T extends ITranslationBase>
  implements ITranslationManager
{
  private savedLanguage: LocalStorageData<string> = new LocalStorageData(
    'selectedLanguage',
    false
  );

  private _translation: T;

  constructor() {}
  init() {
    const lang = getUrlParameterByName('lang');

    const isLanguageInUrl = lang != null;

    if (isLanguageInUrl) {
      this.selectLanguage(lang as string);
    } else {
      let lang = this.savedLanguage.get();

      if (lang == null) {
        lang = this.getBrowserLanguage();
      }

      this.selectLanguage(lang);
    }
  }

  private getBrowserLanguage() {
    let lang = window.navigator.languages
      ? window.navigator.languages[0]
      : null;

    const navigator = window.navigator as any;

    lang =
      lang ||
      window.navigator.language ||
      navigator['browserLanguage'] ||
      navigator['userLanguage'];

    let id = TranslationId.ENGLISH;

    if (!lang) {
      return id;
    }

    if (lang.indexOf('-') !== -1) {
      lang = lang.split('-')[0];
    }

    if (lang.indexOf('_') !== -1) {
      lang = lang.split('_')[0];
    }

    lang = lang.toLowerCase();

    switch (lang) {
      case BrowserLanguageCode.CHINESE:
        id = TranslationId.CHINESE;
        break;

      case BrowserLanguageCode.KOREAN:
        id = TranslationId.KOREAN;
        break;

      case BrowserLanguageCode.JAPANESE:
        id = TranslationId.JAPANESE;
        break;
    }

    return id;
  }

  selectLanguage(languageId: string) {
    switch (languageId) {
      case TranslationId.CHINESE:
        this.chinese();
        break;

      case TranslationId.KOREAN:
        this.korean();
        break;

      case TranslationId.JAPANESE:
        this.japanese();
        break;

      default:
        this.english();
    }
  }

  english() {
    this._translation = this.getEnglish();
    this.savedLanguage.save(TranslationId.ENGLISH);
  }
  protected abstract getEnglish(): T;

  chinese() {
    this._translation = this.getChinese();
    this.savedLanguage.save(TranslationId.CHINESE);
  }
  protected abstract getChinese(): T;

  korean() {
    this._translation = this.getKorean();
    this.savedLanguage.save(TranslationId.KOREAN);
  }
  protected abstract getKorean(): T;

  japanese() {
    this._translation = this.getJapanese();
    this.savedLanguage.save(TranslationId.JAPANESE);
  }
  protected abstract getJapanese(): T;

  get isEnglish() {
    return this.translation.translationId == TranslationId.ENGLISH;
  }
  get isChinese() {
    return this.translation.translationId == TranslationId.CHINESE;
  }
  get isKorean() {
    return this.translation.translationId == TranslationId.KOREAN;
  }
  get isJapanese() {
    return this.translation.translationId == TranslationId.JAPANESE;
  }

  get translation() {
    return this._translation;
  }
}
