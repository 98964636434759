import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthStateService } from '../services/auth-state.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(private service: AuthStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.isLoggedIn;
  }
}
