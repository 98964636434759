import { IEmitter } from './interfaces';

interface ISocketRequestInput {
  messagePrefix: string;
  messageName: string;
  messageArguments: any[];
  socket: IEmitter;
}

interface ISocketRequestError {
  message: string;
  data?: any;
}

interface ISocketRequestResponse<T> {
  error?: ISocketRequestError;
  result?: T;
}

declare type SocketResponseHandler<T> = (
  response: ISocketRequestResponse<T>
) => void;

export function sendSocketRequest<T>({
  messagePrefix,
  messageName,
  messageArguments,
  socket,
}: ISocketRequestInput): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    const args: any[] = [messagePrefix, messageName];
    for (let i = 0; i < messageArguments.length; i++) {
      args.push(messageArguments[i]);
    }

    const responseHandler = (response: ISocketRequestResponse<T>) => {
      if (response.error) {
        reject(response.error);
      } else if (response.result) {
        resolve(response.result);
      }
    };

    args.push(responseHandler);

    socket.emit.apply(socket, args);
  });
}
