import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { ProtectedAPIService } from 'src/app/http/services/protected-api.service';
import { sleep } from 'src/app/util/timer-util';
import { environment } from 'src/environments/environment';
import { ISignUpBonusProgressData } from '../outputs/interfaces';

import { SignUpBonusState } from './sign-up-bonus-state';

@Injectable()
export class SignUpBonusService extends ProtectedAPIService {
  private lastRequestAt: number | undefined = undefined;
  private isWaitingForRequest = false;

  constructor(
    readonly state: SignUpBonusState,
    http: HttpClient,
    errorHandler: ErrorHandlerService
  ) {
    super(
      `${environment.apiUrl}/welcome-bonus`,
      http,
      state.settings.balances.authState,
      errorHandler
    );

    const { events } = state.settings.balances.stakingData.tokenSettings;

    events.userDataReceivedEvent.subscribe((data) => {
      state.sync(data.welcomeBonus);
    });

    console.log('SignUpBonus service instantiated...');
  }

  async syncProgressForSignUpBonus(
    // TODO: is this parameter still relevant?
    isAccountImported: boolean = false
  ) {
    // do not sync progress if ineligible or if the reward is already received
    if (
      !this.state.isEligibleForFunnel ||
      this.state.hasCompletedAllSteps ||
      this.isWaitingForRequest
    ) {
      return;
    }

    let timeToWait = 0;

    if (this.lastRequestAt != undefined) {
      // wait at least 30 seconds between requests
      const secondsToWait = 30;
      const minimumTimeBetweenRequests = secondsToWait * 1000;

      const timeSinceLastRequest = Date.now() - this.lastRequestAt;

      // timeToWait should not be less than 0
      timeToWait = Math.max(
        minimumTimeBetweenRequests - timeSinceLastRequest,
        0
      );
    }

    this.lastRequestAt = Date.now();
    this.isWaitingForRequest = true;

    //console.log('waiting ' + timeToWait + ' to fetch progress for bonus...');

    await sleep(timeToWait);

    try {
      const data = await this.postWithAuthToken<ISignUpBonusProgressData>(
        'progress?v=' + Date.now(),
        {}
      );

      this.state.sync(data, isAccountImported);
    } catch (error) {}

    this.isWaitingForRequest = false;
  }
}
