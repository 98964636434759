import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IErrorHandlerService } from './interfaces';

export abstract class ApiServiceBase {
  protected constructor(
    private basePath: string,
    private http: HttpClient,
    protected errorHandler: IErrorHandlerService
  ) {}

  protected get<T>(path: string, authToken?: string) {
    let headers = new HttpHeaders();

    if (authToken) {
      headers = headers.append('Authorization', `Bearer ${authToken}`);
    }

    return this.handleRequest(
      this.http.get<T>(this.getFullUrl(path), { headers })
    );
  }

  protected post<T>(path: string, data?: any, authToken?: string) {
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');

    if (authToken) {
      headers = headers.append('Authorization', `Bearer ${authToken}`);
    }

    return this.handleRequest(
      this.http.post<T>(this.getFullUrl(path), data || {}, {
        headers,
      })
    );
  }

  private handleRequest<T>(observable: Observable<T>) {
    return new Promise<T>((resolve, reject) => {
      observable.subscribe(
        (response) => {
          resolve(response);
        },
        (e) => {
          console.error('request error', e);
          const error = e.error;

          // only reject if it's not the unauthorized error
          this.errorHandler.handleRequestError(error, reject);
        }
      );
    });
  }

  private getFullUrl(path: string) {
    return this.basePath + '/' + path;
  }
}
