import { Injectable } from '@angular/core';
import { CurrencyBalances } from './balances';
import { StakingData } from 'src/app/staking/services/staking-data';

@Injectable()
export class UnrestrictedBalancesService extends CurrencyBalances {
  constructor(stakingData: StakingData) {
    super(stakingData);
  }
}
