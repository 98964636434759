<div class="chat">
  <div class="chat-content">
    <div class="gradient"></div>
    <div class="message-board" #msgBoard>
      <table>
        <tr *ngFor="let msg of messages; let i = index" [ngClass]="msg.role">
          <!-- TODO: show level and role -->
          <level-badge
            level="{{ msg.rank }}"
            userRole="{{ msg.role }}"
          ></level-badge>

          <span
            class="message"
            ngbDropdown
            [placement]="popUpPlacement"
            [class.pop-up-top]="i >= messages.length - 5"
          >
            <div
              type="button"
              class="info-holder"
              id="dropdownBasic3"
              ngbDropdownToggle
              placement="top"
              display="dynamic"
              (click)="popUpPositionChange(i)"
            >
              <span class="username">{{ msg.username }}</span>
              <span lang="en" class="message-text">: {{ msg.message }}</span>
            </div>

            <!-- TODO: show user chat options -->
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
              <div class="triangle"></div>
              <button (click)="openTipUser(msg)" ngbDropdownItem>
                Tip user
              </button>
              <!--
              <button (click)="modals.open(userStats)" ngbDropdownItem>
                Ignore
              </button>
              <button (click)="modals.open(muteUser)" ngbDropdownItem>
                Mute
              </button>
              <button (click)="modals.open(banUser)" ngbDropdownItem>
                Ban
              </button>
              -->
            </div>
          </span>
        </tr>
      </table>
    </div>

    <div class="chat-input">
      <input
        (keyup.enter)="sendMessage()"
        type="text"
        name="chat"
        [(ngModel)]="message"
        placeholder="Start typing..."
      />

      <a
        class="emoji"
        (click)="showEmojis = !showEmojis"
        [(emojiPickerIf)]="showEmojis"
        [emojiPickerDirection]="'left'"
        (emojiPickerSelect)="onEmojiSelected($event)"
      >
      </a>

      <!-- TODO: show tip rain -->
      <!--
      <a (click)="modals.open(rainBot)" class="rain"> </a>
      -->
    </div>
    <a (click)="modals.open(chatRules)" class="rules">Chat rules</a>

    <!-- banners -->
    <div class="chat-banners">
      <rain-banner
        *ngIf="showRainBanner"
        (closed)="showRainBanner = false"
      ></rain-banner>

      <bonus-banner *ngIf="!hasReceivedSignUpBonus"></bonus-banner>
    </div>
  </div>
</div>

<!-- tip user modal -->
<ng-template #tipUser let-modal>
  <tip-user [modal]="modal" [user]="selectedUser"></tip-user>
</ng-template>

<ng-template #muteUser let-modal>
  <mute-user [modal]="modal"></mute-user>
</ng-template>

<ng-template #banUser let-modal>
  <ban-user [modal]="modal"></ban-user>
</ng-template>

<ng-template #chatRules let-modal>
  <chat-rules [modal]="modal"></chat-rules>
</ng-template>

<ng-template #userStats let-modal>
  <user-stats [modal]="modal"></user-stats>
</ng-template>

<ng-template #rainBot let-modal>
  <rain-bot [modal]="modal"></rain-bot>
</ng-template>
