import { dispatchMessage } from './dispatch-message';
import { IEmitter, ISocket } from './interfaces';
import { sendSocketRequest } from './socket-request';

export class MessageManager {
  constructor(protected emitter: IEmitter, protected messagePrefix: string) {}

  public sendMessage(messageName: string, ...args: any[]) {
    const start = [this.messagePrefix, messageName];
    const argsArray = start.concat(args);

    this.emitter.emit.apply(this.emitter, argsArray);

    return argsArray;
  }
}

export class SocketMessageManager extends MessageManager {
  constructor(
    private socket: ISocket,
    messagePrefix: string,
    private messageReceiver: any
  ) {
    super(socket, messagePrefix);

    this.setupMessageListener();
  }

  private setupMessageListener() {
    // listen to all messages with message prefix
    const receiver = this.messageReceiver;

    this.socket.on(this.messagePrefix, function () {
      dispatchMessage(arguments, receiver);
    });
  }

  protected sendRequest<T>(messageName: string, ...args: any[]): Promise<T> {
    return sendSocketRequest<T>({
      messagePrefix: this.messagePrefix,
      messageName,
      messageArguments: args,
      socket: this.socket,
    });
  }
}
