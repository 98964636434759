<!-- DESKTOP: Top Header Bar -->
<div
  *ngIf="isNavBarVisible"
  class="toolbar desktop"
  [class.full-screen]="fullScreenStatus"
>
  <div class="cell-1">
    <button
      class="menu-toggle"
      [class.active]="isSidebarOpen"
      (click)="toggleSidebar()"
    >
      <img src="assets/icons/function/menu_toggler.svg" />
    </button>
    <!--
    <a routerLink="/home">
      <img src="assets/images/logos/earnbet_logo_color.svg" />
    </a>
    -->
  </div>

  <div class="cell-2">
    <search-bar [lightOn]="true"></search-bar>

    <span class="volume-dropdown" #soundDropdown="ngbDropdown" ngbDropdown>
      <span
        type="button"
        id="dropdownBasic0"
        (mouseenter)="$event.stopPropagation(); soundDropdown.open()"
        ngbDropdownToggle
      >
        <div
          class="sound"
          [class.mid]="volumeValue > 0 && volumeValue <= 0.75"
          [class.off]="volumeValue == 0"
        ></div>
      </span>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic0"
        (mouseleave)="$event.stopPropagation(); soundDropdown.close()"
      >
        <div class="volume-holder">
          <div class="triangle"></div>
          <mv-slider
            [(value)]="volumeValue"
            [step]="0.01"
            [reversed]="true"
            [min]="0"
            [max]="1"
            [enabled]="true"
            (slideStop)="onVolumeSliderStop()"
            orientation="vertical"
          ></mv-slider>
        </div>
      </div>
    </span>

    <span language class="flag-dropdown" ngbDropdown></span>
  </div>

  <div class="cell-3" [style]="isLoggedIn ? 'display: none' : ''">
    <a routerLink="/register" class="cyan-btn">
      <span>Register</span>
    </a>
    <a routerLink="/login" class="login-btn">
      <div class="account-icon"></div>
      <span>Login</span>
    </a>
  </div>

  <div class="cell-3" [style]="isLoggedIn ? '' : 'display: none'">
    <div class="info">
      <a
        class="login-btn"
        [ngClass]="incognito ? 'incognito' : 'visible'"
        routerLink="/profile"
      >
        <!--
        <level-badge level="139"></level-badge>
        -->
        <level-badge level="0"></level-badge>
        <span>{{ username }}</span>
      </a>
    </div>

    <!-- tokens -->
    <div tokenBalances></div>

    <!-- incognito button -->
    <!--
    <label class="toggle-switch">
      <input
        type="checkbox"
        (click)="togglePrivacy()"
        [(ngModel)]="incognito"
      />
      <div class="slider" [ngClass]="incognito ? 'ON' : 'OFF'">
        <span class="icognito-icon" [ngClass]="incognito ? 'ON' : 'OFF'"></span>
      </div>
    </label>
    -->
  </div>

  <div *ngIf="showBonusProgress" bonusProgress></div>
</div>

<!-- MOBILE: Top Header Bar -->
<div class="stickyHeader mobile" *ngIf="stickyHeader && isNavBarVisible">
  <!--
  <a routerLink="/">
    <img src="assets/icons/menu/mobile_logo.svg" alt="earnbet logo" />
  </a>
  -->

  <div class="info" [style]="isLoggedIn ? 'display: none' : ''">
    <a routerLink="/register" class="cyan-btn">
      <span>Register</span>
    </a>
    <a routerLink="/login" class="login-btn">
      <div class="account-icon"></div>
      <span>Login</span>
    </a>

    <span language class="flag-dropdown" ngbDropdown></span>
  </div>

  <div class="info" [style]="!isLoggedIn ? 'display: none' : ''">
    <div class="deposit">
      <div tokenBalances></div>
    </div>

    <a class="login-btn" routerLink="/profile">
      <div class="account-icon"></div>
    </a>

    <span language class="flag-dropdown" ngbDropdown></span>
  </div>
</div>

<!-- MOBILE: Lower Menu -->
<div
  *ngIf="!stickyHeader && isNavBarVisible"
  class="toolbar mobile"
  [class.full-screen]="fullScreenStatus"
>
  <div class="content">
    <a
      class="home-link"
      routerLink="/"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{
        exact: true
      }"
    >
      <div class="icon"></div>
      <span>Home</span>
    </a>
    <a
      class="menu-link"
      (click)="toggleSidebar(true)"
      [ngClass]="isSidebarOpen ? 'active' : ''"
    >
      <div class="icon"></div>
      <span>Menu</span>
    </a>
    <a
      class="chat-link"
      [ngClass]="message ? 'new' : ''"
      routerLink="/chat"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="icon"></div>
      <span>Chat</span>
    </a>
    <a
      class="search-link"
      routerLink="/search-games"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
    >
      <div class="icon"></div>
      <span>Search Game</span>
    </a>
    <!-- <a class="live-chat-link">
      <div class="icon"></div>
      <span>LiveChat</span>
    </a> -->

    <div bonusProgress *ngIf="showBonusProgress" [isMobile]="true"></div>
  </div>
</div>

<ng-template #migration let-modal>
  <migration-modal [modal]="modal"></migration-modal>
</ng-template>
