<span type="button" id="dropdownBasic2" ngbDropdownToggle
  ><img
    class="flag"
    src="assets/icons/flags/{{ language }}.png"
    alt="Flag icon"
/></span>
<div ngbDropdownMenu aria-labelledby="dropdownBasic2">
  <button *ngIf="language != 'EN'" (click)="changeLanguage('EN')">
    <img class="flag" src="assets/icons/flags/EN.png" alt="Flag icon" />
  </button>
  <!-- TODO: enable other languages -->
  <!--
  <button *ngIf="language != 'CH'" (click)="changeLanguage('CH')">
    <img class="flag" src="assets/icons/flags/CH.png" alt="Flag icon" />
  </button>
  <button *ngIf="language != 'KO'" (click)="changeLanguage('KO')">
    <img class="flag" src="assets/icons/flags/KO.png" alt="Flag icon" />
  </button>
  -->
</div>
