import { Component, Input } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SignUpBonusState } from '../../services/sign-up-bonus-state';

@Component({
  selector: 'div[bonusProgress]',
  templateUrl: './bonus-progress.component.html',
  styleUrls: ['./bonus-progress.component.scss'],
})
export class BonusProgressComponent {
  @Input()
  isMobile = false;

  constructor(readonly state: SignUpBonusState) {}

  onMouseOver(drop: NgbDropdown) {
    drop.open();
  }
  onMouseOut(drop: NgbDropdown) {
    drop.close();
  }

  get buttonPlacement() {
    return this.isMobile ? 'top-right' : 'bottom-right';
  }
  get menuPlacement() {
    return this.isMobile ? 'top' : 'left-bottom';
  }

  get depositProgress() {
    return this.state.depositProgress;
  }

  get wagerProgress() {
    return this.state.betsVolumeProgress;
  }

  get stage(): number {
    return this.state.hasCompletedAllSteps
      ? 3
      : this.state.isAccountCreated
      ? 2
      : 1;
  }
}
