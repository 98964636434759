<div class="responsive-holder">
  <nav-bar
    [stickyHeader]="true"
    (toggleMenu)="toggleSidebar()"
    class="nav-bar"
  ></nav-bar>
  <ng-sidebar-container
    class="sidebar-container"
    [class.show-nav]="isNavBarVisible"
  >
    <!-- sidebar -->
    <ng-sidebar [opened]="isSidebarOpen" mode="push">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="sidebar.currentTab"
        class="nav-tabs"
      >
        <li [ngbNavItem]="'menu'">
          <a ngbNavLink>Menu</a>
          <ng-template ngbNavContent>
            <menu></menu>
          </ng-template>
        </li>
        <li [ngbNavItem]="'chat'" destroyOnHide="false">
          <a ngbNavLink>Chat</a>
          <ng-template ngbNavContent>
            <chat></chat>
          </ng-template>
        </li>

        <li [ngbNavItem]="'leaderboard'">
          <a ngbNavLink>Leaderboard</a>
          <ng-template ngbNavContent>
            <leaderboard [isForSidebar]="true"></leaderboard>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </ng-sidebar>

    <!-- Page content -->
    <div ng-sidebar-content>
      <!-- alert component: -->
      <div alert></div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <footer *ngIf="isNavBarVisible"></footer>
    </div>
  </ng-sidebar-container>

  <!-- lower menu for mobile -->
  <nav-bar
    [stickyHeader]="false"
    *ngIf="isMobile"
    (toggleMenu)="toggleSidebar()"
    class="nav-bar"
  ></nav-bar>
</div>

<!-- TODO: connect livechat support -->
<a href="" class="support">
  <img src="assets/icons/menu/mobile_logo.svg" alt="EarnBet cyan logo" />
  <span class="text"> Support </span>
</a>
