import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ChatComponent } from './components/chat/chat.component';
import { BanUserComponent } from './components/ban-user/ban-user.component';
import { ChatRulesComponent } from './components/chat-rules/chat-rules.component';
import { MuteUserComponent } from './components/mute-user/mute-user.component';
import { SharedUsersModule } from '../users-shared/shared-users.module';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';

import { SignUpBonusModule } from '../sign-up-bonus/sign-up-bonus.module';
import { RainComponentsModule } from '../rain/rain-components.module';
import { TipsModule } from '../tips/ttips.module';
@NgModule({
  imports: [
    FormsModule,

    NgxEmojiPickerModule,

    // chat module needs SharedUsersModule for the LevelBadge
    SharedUsersModule,
    SignUpBonusModule,

    RainComponentsModule,
    TipsModule,
  ],

  declarations: [
    ChatComponent,
    BanUserComponent,
    ChatRulesComponent,
    MuteUserComponent,
  ],

  providers: [],

  exports: [
    ChatComponent,

    // export shared module, since ChatModule is imported by Sidebar module
    SharedUsersModule,
    SignUpBonusModule,
  ],
})
export class ChatComponentsModule {}
