import { NgModule } from '@angular/core';

import { CurrencyBalancesService } from '../currency/balance/services/currency-balances.service';
import { BankrollService } from '../internal-games/services/bankroll-service';
import { AppEventsService } from './services/app-events.service';

import { AppSettings } from './services/app-settings.service';
import { AppService } from './services/app.service';
import { AudioService } from './services/audio.service';
import { FormsService } from '../forms/services/forms.service';

import { GlobalVariablesService } from './services/global-variables.service';
import { ReferrerService } from './services/referrer.service';
import { SoundsHelperService } from './services/sounds.service';
import { UserStateService } from './services/user-state.service';
import { AppApiService } from './services/app-api.service';
import { TokensService } from '../internal-games/services/tokens.service';
import { ResolvedBets } from '../bets/services/resolved-bets';
import { NotificationsService } from './services/notifications.service';
import { LeaderboardServicesModule } from '../leaderboard/leaderboard-services.module';
import { SidebarServicesModule } from '../sidebar/sidebar-services.module';
import { HttpHelperModule } from '../http/http-helper.module';
import { ModalsModule } from '../modals/modals.module';
import { ChatServiceModule } from '../chat/chat-service.module';
import { StakingServicesModule } from '../staking/staking-services.module';

import { AccountMigrationServicesModule } from '../account-migration/migration-services.module';
import { TokenSettingsService } from '../currency/tokens/services/token-settings.service';
import { DividendsService } from '../dividends/services/dividends.service';
import { ExternalGamesClient } from '../external-games/services/external-games-client';
import { ExternalGameEventsService } from '../external-games/services/game-events.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { RainServicesModule } from '../rain/rain-services.module';
import { UnrestrictedBalancesService } from '../currency/balance/services/unrestricted-balances.service';

@NgModule({
  imports: [
    HttpHelperModule,
    LeaderboardServicesModule,
    SidebarServicesModule,
    ModalsModule,
    ChatServiceModule,
    StakingServicesModule,
    AccountMigrationServicesModule,
    RainServicesModule,
  ],
  providers: [
    // TODO: move these to separate modules that get imported
    // these are located in other "modules", but still require state to be in the global scope
    TokenSettingsService,
    CurrencyBalancesService,
    UnrestrictedBalancesService,
    BankrollService,
    FormsService,
    TokensService,
    ResolvedBets,
    DividendsService,

    ExternalGamesClient,
    ExternalGameEventsService,
    /**********************/

    GlobalVariablesService, // TODO: to be deprecated?

    SoundsHelperService, // TODO: to be deprecated
    AudioService,

    AppSettings,
    AppEventsService,
    ReferrerService,
    UserStateService,
    AppApiService,
    NotificationsService,

    GoogleAnalyticsService,

    AppService,
  ],
})
/** IMPORTANT: this module should only be imported once by the Main App Root Module,
 * in order to ensure that the services it exposes are singletons ***/
export class GlobalStateModule {}
