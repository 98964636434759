export interface IUniqueItem {
  id: string;
}

export class QueueOfUniqueItems<T extends IUniqueItem> {
  private map: { [itemId: string]: T } = {};
  private list: T[] = [];

  constructor() {}

  addAll(items: T[]) {
    for (let item of items) {
      this.add(item);
    }
  }
  add(item: T) {
    if (!this.hasItemWithId(item.id)) {
      this.map[item.id] = item;

      this.list.push(item);
    }
  }

  removeItemWithId(itemId: string) {
    delete this.map[itemId];

    for (let i = 0; i < this.list.length; i++) {
      const item = this.list[i];

      if (itemId == item.id) {
        this.list.splice(i, 1);
        break;
      }
    }
  }

  hasItemWithId(itemId: string): boolean {
    return this.map[itemId] != undefined;
  }

  getItemWithId(itemId: string): T {
    return this.map[itemId];
  }

  get items(): T[] {
    return this.list.slice();
  }
}

export class FIFOList<T> {
  private _items: T[] = [];

  constructor(private maxNumOfItems: number) {}

  addAsFirst(newItem: T) {
    const end = this.items.slice(0, this.maxNumOfItems - 1);
    this._items = [newItem].concat(end);
  }
  addAsLast(newItem: T) {
    let start: T[] = [];

    if (this.items.length < this.maxNumOfItems) {
      start = this.items.slice();
    } else {
      start = this.items.slice(1, this.maxNumOfItems);
    }

    const merged = start.concat([newItem]);

    this._items = merged;
  }

  get items() {
    return this._items;
  }
}
