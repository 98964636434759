import { Component, Input, OnInit } from '@angular/core';

import { chipList } from 'src/app/internal-games/data/gameAssets';
import { cryptoList } from 'src/app/shared/data/cryptoList';

@Component({
  selector: 'rain-bot',
  templateUrl: './rain-bot.component.html',
})
export class RainBotComponent implements OnInit {
  @Input() modal: any;
  chips = chipList;
  currency = cryptoList;
  selected = 'Bitcoin';
  currencyIcon = this.currency.filter((obj) => {
    return obj.fullName === this.selected;
  });
  constructor() {}

  ngOnInit(): void {}

  changeCurrency(currency: string) {
    this.selected = currency;
    this.currencyIcon = this.currency.filter((obj) => {
      return obj.fullName === this.selected;
    });
  }
}
