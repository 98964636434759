import { Injectable } from '@angular/core';

import { getUrlParameterByName } from 'src/app/http/util/url-util';

const referralCodeKey = 'frBonusCode';

@Injectable()
export class ReferrerService {
  private referralFromLocalStorage: string | null;

  constructor() {}

  retrieveReferrer() {
    // parse url for referrer
    const referrerFromUrl = getUrlParameterByName('ref');
    const bonusFromUrl = getUrlParameterByName('bonus');

    const referrer = referrerFromUrl != null ? referrerFromUrl : bonusFromUrl;

    if (referrer != null) {
      localStorage.setItem(referralCodeKey, referrer);

      // after saving the referral code to local storage, we redirect
      // if we ever need to parse multiple url variables we need to refactor to only redirect once
      window.location.href = window.location.origin;
    } else {
      // load from local storage
      this.referralFromLocalStorage = localStorage.getItem(referralCodeKey);

      console.log('*** bonus: ', this.referralFromLocalStorage);
    }
  }

  get referrer() {
    return this.referralFromLocalStorage ? this.referralFromLocalStorage : '';
  }
}
