import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

// for rain animations:
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { RainBannerComponent } from './components/rain-banner/rain-banner.component';
import { RainBotComponent } from './components/rain-bot/rain-bot.component';
import { RainApiService } from './services/rain-api.service';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    LottieModule.forRoot({
      player: () => {
        return player;
      },
    }),

    HttpClientModule,
  ],
  declarations: [RainBannerComponent, RainBotComponent],
  providers: [RainApiService],
  exports: [RainBannerComponent, RainBotComponent],
})
export class RainComponentsModule {}
