export class LocalStorageData<T> {
  constructor(private dataKey: string, private isObject: boolean) {}

  isSaved(): boolean {
    return this.get() != null;
  }

  get(): T {
    const data = localStorage.getItem(this.dataKey);

    return data != null ? (this.isObject ? JSON.parse(data) : data) : null;
  }

  save(data: T) {
    localStorage.setItem(
      this.dataKey,
      this.isObject ? JSON.stringify(data) : '' + data
    );
  }

  clear() {
    localStorage.clear();
  }
}
