import { Injectable } from '@angular/core';

import { StakingData } from 'src/app/staking/services/staking-data';
import { IUserStakingDataOutput } from 'src/app/staking/outputs/interfaces';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';

import { ICurrencyBalancesService } from './interfaces';
import { sleep } from 'src/app/util/timer-util';
import { IBetResult } from 'src/app/bets/outputs/interfaces';
import { CurrencyBalances } from './balances';

@Injectable()
export class CurrencyBalancesService
  extends CurrencyBalances
  implements ICurrencyBalancesService
{
  constructor(readonly authState: AuthStateService, stakingData: StakingData) {
    super(stakingData);
  }

  // staking data should only need to be updated after a stake or unstake action is performed
  updateStakingData(data: IUserStakingDataOutput) {
    const { info, betBalance } = data;

    const _24HoursInMilliseconds = 24 * 60 * 60 * 1000;

    const records: {
      stake_id: number;
      amount: string;
      stake_weight: string;
      stake_period: number;
      unstake_time: number;
      send_time: number | undefined;
    }[] = data.records.map((record) => ({
      stake_id: record.id,
      amount: record.staked_tokens,
      stake_weight: record.points_awarded,
      stake_period: record.staking_time_period_id,
      unstake_time: record.staked_until,
      // user must wait 24 hours from the time of unstaking before they receive the tokens
      send_time: record.unstaked_at
        ? record.unstaked_at + _24HoursInMilliseconds
        : undefined,
    }));

    this.stakingData.update({
      info: {
        account: this.accountName as string,
        share_percentage: info.sharePercentage,
        total_stake_amount: info.totalTokensStaked,
        total_stake_weight: info.totalStakePoints,
      },
      stakedRecords: records,
      unstakedRecords: records.filter(
        (record) => record.send_time != undefined
      ),
    });

    // update BET token balance
    this.setBalance(betBalance);
  }

  async updateBalances(result: IBetResult) {
    /*** CALCULATE NEW TOKEN BALANCES IN ADVANCE
     *  of the Bet being Resolved on-chain ***/

    // NOT CURRENTLY DOING BET AIRDROP
    //const bet = Number(result.amount) / this.selectedToken.betTokenAirDropRate;

    let profit = result.profit;

    // TODO: for jackpot
    /*
    if (result.isJackpotBet) {
      profit -= Number(this.selectedToken.jackpot.ticketPrice);
    }
    */
    /***/

    await sleep(result.game == 'baccarat' ? 5000 : 2000);

    // START balance change animation
    this.stakingData.tokenSettings.events.balanceChangeEmitter.emit(profit);

    await sleep(500);

    /*** UPDATE TOKEN BALANCES ***/
    // update main token balance
    this.addToBalance(result.tokenSymbol, profit);

    // update BET token balance (NOT CURRENTLY DOING BET AIRDROP)
    //this.wallet.addToBalance(TokenSymbol.BET, bet);
    /***/
  }

  addAssetAmount(quantity: string) {
    const parts = quantity.split(' ');

    if (parts.length != 2) {
      throw new Error('Invalid Asset Amount: ' + quantity);
    }

    const amount = parts[0];
    const symbol = parts[1];

    this.addToBalance(symbol, amount);
  }

  get accountName() {
    return this.authState.username;
  }

  get isLoggedIn() {
    return this.authState.isLoggedIn;
  }
}
