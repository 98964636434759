<div class="leaderboard-component">
  <div class="gradient"></div>
  <count-down *ngIf="5 > currentTabId" [endTime]="endTime"></count-down>
  <div *ngIf="show" class="trophies">
    <div class="d-none d-lg-block col-lg-2"></div>
    <div class="col-xs-12 col-lg-8 row justify-content-lg-center podium">
      <div
        *ngFor="let row of rows | slice : 0 : 3"
        class="col-4 col-lg-3"
        [class.gold]="row.rank == 1"
        [class.silver]="row.rank == 2"
        [class.bronze]="row.rank == 3"
      >
        <div class="cup"></div>
        <div class="backdrop"></div>
        <div class="info">
          <span class="name">{{ row.user }}</span>
          <span
            class="badge"
            [class.yellow]="row.rank == 1"
            [class.light-gray]="row.rank == 2"
            [class.light-brown]="row.rank == 3"
            >${{ row.points }}</span
          >
          <div class="reward">
            <span *ngFor="let reward of row.rewards"
              >{{ reward.amount }}
              <img
                src="assets/icons/crypto/{{
                  reward.token.toLowerCase()
                }}-logo.svg"
            /></span>
          </div>
        </div>
      </div>
    </div>

    <!-- MY RANK -->
    <div class="d-none d-lg-flex col-lg-2 p-0">
      <span class="player-rank desktop"
        >YOUR RANK: <span class="badge cyan">{{ myRank || "?" }}</span></span
      >
    </div>
    <span class="player-rank mobile"
      >YOUR RANK: <span class="badge cyan">{{ myRank || "?" }}</span></span
    >
  </div>
  <div class="leaderboard" [class.side-menu]="!show">
    <ul
      ngbNav
      #nav="ngbNav"
      (navChange)="onNavChange($event)"
      [(activeId)]="currentTabId"
      class="nav-tabs"
    >
      <!-- Daily -->
      <!--
      <li [ngbNavItem]="1">
        <a ngbNavLink>Daily</a>
        <ng-template ngbNavContent>
          <div class="date">
            <img src="assets/icons/function/arrow_left.svg" />
            <div>
              <h2>Leaderboard</h2>
              <h5>OCT, 26</h5>
            </div>
            <img src="assets/icons/function/arrow_right.svg" />
          </div>
          <table>
            <tr class="header row">
              <th class="col-2">Rank</th>
              <th class="col-3">User</th>
              <th *ngIf="show" class="col-4 pl-0 pl-sm-2 pl-md-3 pl-lg-4">
                Wagered
              </th>
              <th *ngIf="!show" class="col-4">Wagered</th>
              <th *ngIf="show" class="col-2 pl-5 ml-4">Reward</th>
              <th *ngIf="!show" class="col-3">Reward</th>
            </tr>
            <tr
              class="data row"
              *ngFor="let person of ranks"
              [class.glow]="user == person.name"
              [class.rank-1]="person.rank == 1"
              [class.rank-2]="person.rank == 2"
              [class.rank-3]="person.rank == 3"
            >
              <td class="col-2 rank">
                <span>
                  {{ person.rank }}
                </span>
              </td>
              <td *ngIf="show" class="col-3 name">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="!show" class="col-3 name" style="padding: 0">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="show" class="col-4 pl-0 pl-sm-3 pl-lg-5 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td *ngIf="!show" class="col-4 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td class="col-3 reward" [class.side-rewards]="!show">
                <span *ngIf="person.reward1 != 0"
                  >{{ person.reward1 }}
                  <img src="assets/icons/crypto/usdt-logo.svg"
                /></span>
                <span *ngIf="person.reward2 != 0"
                  >{{ person.reward2 }}
                  <img src="assets/icons/crypto/bet-logo.svg"
                /></span>
              </td>
            </tr>
          </table>
        </ng-template>
      </li>
      -->
      <li [ngbNavItem]="2">
        <a ngbNavLink>Weekly</a>
        <ng-template ngbNavContent>
          <div class="date">
            <!--
            <img src="assets/icons/function/arrow_left.svg" />
            -->
            <div>
              <h2>Leaderboard</h2>
              <h5>{{ endTime | date : "MMM d, y" }}</h5>
            </div>
            <!--
            <img src="assets/icons/function/arrow_right.svg" />
            -->
          </div>
          <table>
            <tr class="header row">
              <th class="col-2">Rank</th>
              <th class="col-3">User</th>
              <th *ngIf="show" class="col-4 pl-0 pl-sm-2 pl-md-3 pl-lg-4">
                Wagered
              </th>
              <th *ngIf="!show" class="col-4">Wagered</th>
              <th class="col-3">Reward</th>
            </tr>

            <!-- NFT -->
            <!--
            <tr class="data nft row">
              <div class="message off">
                Wager At least $1,000,000 To be Eligible To Win This Unique NFT
                From EarnBet’s Genesis Collection!
              </div>
              <td class="col-2 rank">
                <span> NFT </span>
              </td>
              <td class="col-3 name">
                <span>
                  {{ firstRank[0].name }}
                </span>
              </td>
              <td class="col-4 wagered">
                <span> ${{ firstRank[0].wagered | number }} </span>
              </td>
              <td class="col-3 p-0 reward">
                <img
                  (click)="open(nftModal)"
                  src="assets/images/leader_board/nft.png"
                  alt="NFT"
                />
              </td>
            </tr>
            -->
            <tr
              class="data row"
              *ngFor="let row of rows"
              [class.glow]="myRank === row.rank"
              [class.rank-1]="row.rank == 1"
              [class.rank-2]="row.rank == 2"
              [class.rank-3]="row.rank == 3"
            >
              <td class="col-2 rank">
                <span>
                  {{ row.rank }}
                </span>
              </td>
              <td *ngIf="show" class="col-3 name">
                <span>
                  {{ row.user }}
                </span>
              </td>
              <td *ngIf="!show" class="col-3 name" style="padding: 0">
                <span>
                  {{ row.user }}
                </span>
              </td>
              <td *ngIf="show" class="col-4 pl-0 pl-sm-3 pl-lg-5 wagered">
                <span> ${{ row.points }} </span>
              </td>
              <td *ngIf="!show" class="col-4 wagered">
                <span> ${{ row.points }} </span>
              </td>
              <td class="col-3 reward" [class.side-rewards]="!show">
                <span *ngFor="let reward of row.rewards"
                  >{{ reward.amount }}
                  <img
                    src="assets/icons/crypto/{{
                      reward.token.toLowerCase()
                    }}-logo.svg"
                /></span>

                <!--
                <span *ngIf="person.reward1 != 0"
                  >{{ person.reward1 }}
                  <img src="assets/icons/crypto/usdt-logo.svg"
                /></span>
                <span *ngIf="person.reward2 != 0"
                  >{{ person.reward2 }}
                  <img src="assets/icons/crypto/bet-logo.svg"
                /></span>
                -->
              </td>
            </tr>
          </table>
        </ng-template>
      </li>
      <!--
      <li [ngbNavItem]="3">
        <a ngbNavLink>Monthly</a>
        <ng-template ngbNavContent>
          <div class="date">
            <img src="assets/icons/function/arrow_left.svg" />
            <div>
              <h2>Leaderboard</h2>
              <h5>OCTOBER, 2021</h5>
            </div>
            <img src="assets/icons/function/arrow_right.svg" />
          </div>
          <table>
            <tr class="header row">
              <th class="col-2">Rank</th>
              <th class="col-3">User</th>
              <th *ngIf="show" class="col-4 pl-0 pl-sm-2 pl-md-3 pl-lg-4">
                Wagered
              </th>
              <th *ngIf="!show" class="col-4">Wagered</th>
              <th class="col-3">Reward</th>
            </tr>
            <tr class="data nft row">
              <div class="message off">
                Wager At least $1,000,000 To be Eligible To Win This Unique NFT
                From EarnBet’s Genesis Collection!
              </div>
              <td class="col-2 rank">
                <span> NFT </span>
              </td>
              <td class="col-3 name">
                <span>
                  {{ firstRank[0].name }}
                </span>
              </td>
              <td class="col-4 wagered">
                <span> ${{ firstRank[0].wagered | number }} </span>
              </td>
              <td class="col-3 p-0 reward">
                <img
                  (click)="open(nftModal)"
                  src="assets/images/leader_board/nft.png"
                  alt="NFT"
                />
              </td>
            </tr>
            <tr
              class="data row"
              *ngFor="let person of ranks"
              [class.glow]="user == person.name"
              [class.rank-1]="person.rank == 1"
              [class.rank-2]="person.rank == 2"
              [class.rank-3]="person.rank == 3"
            >
              <td class="col-2 rank">
                <span>
                  {{ person.rank }}
                </span>
              </td>
              <td *ngIf="show" class="col-3 name">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="!show" class="col-3 name" style="padding: 0">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="show" class="col-4 pl-0 pl-sm-3 pl-lg-5 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td *ngIf="!show" class="col-4 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td class="col-3 reward" [class.side-rewards]="!show">
                <span *ngIf="person.reward1 != 0"
                  >{{ person.reward1 }}
                  <img src="assets/icons/crypto/usdt-logo.svg"
                /></span>
                <span *ngIf="person.reward2 != 0"
                  >{{ person.reward2 }}
                  <img src="assets/icons/crypto/bet-logo.svg"
                /></span>
              </td>
            </tr>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Yearly</a>
        <ng-template ngbNavContent>
          <div class="date">
            <img src="assets/icons/function/arrow_left.svg" />
            <div>
              <h2>Leaderboard</h2>
              <h5>2021</h5>
            </div>
            <img src="assets/icons/function/arrow_right.svg" />
          </div>
          <table>
            <tr class="header row">
              <th class="col-2">Rank</th>
              <th class="col-3">User</th>
              <th *ngIf="show" class="col-4 pl-0 pl-sm-2 pl-md-3 pl-lg-4">
                Wagered
              </th>
              <th *ngIf="!show" class="col-4">Wagered</th>
              <th class="col-3">Reward</th>
            </tr>
            <tr class="data nft row">
              <div class="message off">
                Wager At least $1,000,000 To be Eligible To Win This Unique NFT
                From EarnBet’s Genesis Collection!
              </div>
              <td class="col-2 rank">
                <span> NFT </span>
              </td>
              <td class="col-3 name">
                <span>
                  {{ firstRank[0].name }}
                </span>
              </td>
              <td class="col-4 wagered">
                <span> ${{ firstRank[0].wagered | number }} </span>
              </td>
              <td class="col-3 p-0 reward">
                <img
                  (click)="open(nftModal)"
                  src="assets/images/leader_board/nft.png"
                  alt="NFT"
                />
              </td>
            </tr>
            <tr
              class="data row"
              *ngFor="let person of ranks"
              [class.glow]="user == person.name"
              [class.rank-1]="person.rank == 1"
              [class.rank-2]="person.rank == 2"
              [class.rank-3]="person.rank == 3"
            >
              <td class="col-2 rank">
                <span>
                  {{ person.rank }}
                </span>
              </td>
              <td *ngIf="show" class="col-3 name">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="!show" class="col-3 name" style="padding: 0">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="show" class="col-4 pl-0 pl-sm-3 pl-lg-5 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td *ngIf="!show" class="col-4 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td class="col-3 reward" [class.side-rewards]="!show">
                <span *ngIf="person.reward1 != 0"
                  >{{ person.reward1 }}
                  <img src="assets/icons/crypto/usdt-logo.svg"
                /></span>
                <span *ngIf="person.reward2 != 0"
                  >{{ person.reward2 }}
                  <img src="assets/icons/crypto/bet-logo.svg"
                /></span>
              </td>
            </tr>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>All time</a>
        <ng-template ngbNavContent>
          <div class="date">
            <img src="assets/icons/function/arrow_left.svg" />
            <div>
              <h2>Leaderboard</h2>
              <h5>All time</h5>
            </div>
            <img src="assets/icons/function/arrow_right.svg" />
          </div>
          <table>
            <tr class="header row">
              <th class="col-2">Rank</th>
              <th class="col-3">User</th>
              <th *ngIf="show" class="col-4 pl-0 pl-sm-2 pl-md-3 pl-lg-4">
                Wagered
              </th>
              <th *ngIf="!show" class="col-4">Wagered</th>
              <th class="col-3">Reward</th>
            </tr>
            <tr
              class="data row"
              *ngFor="let person of ranks"
              [class.glow]="user == person.name"
              [class.rank-1]="person.rank == 1"
              [class.rank-2]="person.rank == 2"
              [class.rank-3]="person.rank == 3"
            >
              <td class="col-2 rank">
                <span>
                  {{ person.rank }}
                </span>
              </td>
              <td *ngIf="show" class="col-3 name">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="!show" class="col-3 name" style="padding: 0">
                <span>
                  {{ person.name }}
                </span>
              </td>
              <td *ngIf="show" class="col-4 pl-0 pl-sm-3 pl-lg-5 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td *ngIf="!show" class="col-4 wagered">
                <span> ${{ person.wagered | number }} </span>
              </td>
              <td class="col-3 reward" [class.side-rewards]="!show">
                <span *ngIf="person.reward1 != 0"
                  >{{ person.reward1 }}
                  <img src="assets/icons/crypto/usdt-logo.svg"
                /></span>
                <span *ngIf="person.reward2 != 0"
                  >{{ person.reward2 }}
                  <img src="assets/icons/crypto/bet-logo.svg"
                /></span>
              </td>
            </tr>
          </table>
        </ng-template>
      </li>
      -->
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>

  <ng-template #nftModal let-modal>
    <nft-modal [modal]="modal"></nft-modal>
  </ng-template>
</div>
