import { EventEmitter, Injectable } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { SocketMessageManager } from 'src/app/socket/message-manager';
import { SocketClientBase } from 'src/app/socket/socket-client-base';
import { FIFOList } from 'src/app/util/collections';
import { environment } from 'src/environments/environment';
import { IChatMessage } from '../data/interfaces';
import { IChatMessageData } from '../outputs/interfaces';
import { ChatMessageName } from '../socket/messages';

const NAMESPACE = '/chat';

@Injectable()
export class ChatService extends SocketClientBase {
  private chatEmitter = new EventEmitter<IChatMessageData>();

  private messagesList = new FIFOList<IChatMessage>(1000);
  private _messages: IChatMessage[] = [];

  private messageManager: SocketMessageManager;

  constructor(private authState: AuthStateService) {
    super(environment.chatServer, NAMESPACE);

    authState.authenticatedEvent.subscribe(this.connect);

    console.log('chat service initialized');

    this.connect();
  }

  private connect = () => {
    console.log('chat client connecting...');

    let options = undefined;

    if (this.authState.isLoggedIn) {
      options = {
        extraHeaders: {
          Authorization: `Bearer ${this.authState.authToken}`,
        },
      };
    }

    console.log({ options });

    const socket = this.connectSocket(options);

    this.messageManager = new SocketMessageManager(
      socket,
      ChatMessageName.PREFIX,
      this
    );
  };

  onConnected() {
    console.log('chat client connected to Chat Server!');
  }

  sendChatMessage(message: string) {
    this.messageManager.sendMessage(ChatMessageName.CHAT, message);
  }

  /*** MESSAGE LISTENERS: ***/
  onRecentMessages(messages: IChatMessageData[]) {
    messages.reverse();
    console.log({ messages });

    messages.forEach(this.addChatMessage);
  }
  onChat(data: IChatMessageData) {
    this.addChatMessage(data);
  }
  /***/

  private addChatMessage = (data: IChatMessageData) => {
    this.messagesList.addAsLast(this.convertMessageData(data));
    this.updateMessages();

    this.chatEmitter.emit(data);
  };

  private convertMessageData(data: IChatMessageData) {
    return {
      ...data,
      username: data.user.username,
      //  TODO: put user's actual role:
      role: 'user',
      // TODO: put user's actual rank
      rank: 1,
    };
  }

  private updateMessages() {
    this._messages = this.messagesList.items;
  }

  onDisconnected() {
    //console.log('chat client disconnected');
  }

  get chatEvent() {
    return this.chatEmitter.asObservable();
  }

  get messages() {
    return this._messages;
  }
}
