import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { NavigationService } from 'src/app/navigation/navigation.service';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input()
  lightOn: boolean;

  @Input()
  blog: boolean;

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  query = '';

  constructor(private navigator: NavigationService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  focus() {
    this.searchInput.nativeElement.focus();
  }

  search() {
    this.navigator.gotoPage('search-games?query=' + this.query);
  }
}
