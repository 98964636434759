<div class="modal-width migration">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    <h4 class="modal-title" id="modal-basic-title">
      Welcome to the New Earnbet
    </h4>
    <h5>
      EarnBet users will need to migrate to our new account system.
      <a href=""> Learn more </a>
    </h5>
    <div class="buttons">
      <button class="cyan-btn" (click)="gotoPage('validate-earnbet-account')">
        Migrate EarnBet Account
      </button>

      <button class="cyan-btn" (click)="gotoPage('validate-wax-account')">
        Migrate WAX Account
      </button>
    </div>

    <h5>Or create a new account</h5>
    <button
      (click)="close()"
      routerLink="/register"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="cyan-btn-empty"
    >
      Sign Up
    </button>
  </div>
  <div class="modal-footer"></div>
</div>
