import { accountCrypto, currencyList } from './interfaces';

export const cryptoAmount: accountCrypto[] = [
  {
    amount: 0.0,
    src: 'assets/icons/crypto/btc-logo.svg',
    fullName: 'Bitcoin',
    name: 'BTC',
  },
  {
    amount: 0.00001,
    src: 'assets/icons/crypto/eth-logo.svg',
    fullName: 'Ethereum',
    name: 'ETH',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/eos-logo.svg',
    fullName: 'EOS',
    name: 'EOS',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/ltc-logo.svg',
    fullName: 'Litecoin',
    name: 'LTC',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/bch-logo.svg',
    fullName: 'Bitcoin Cash',
    name: 'BCH',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/wax-logo.svg',
    fullName: 'WAX',
    name: 'WAX',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/xrp-logo.svg',
    fullName: 'Ripple',
    name: 'XRP',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/bnb-logo.svg',
    fullName: 'Binance',
    name: 'BNB',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/trx-logo.svg',
    fullName: 'Tron',
    name: 'TRX',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/link-logo.svg',
    fullName: 'Chainlink',
    name: 'LINK',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/dai-logo.svg',
    fullName: 'DAI',
    name: 'DAI',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/usdc-logo.svg',
    fullName: 'USD Coin',
    name: 'USDC',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/usdt-logo.svg',
    fullName: 'Tether',
    name: 'USDT',
  },
  {
    amount: 0.00002,
    src: 'assets/icons/crypto/bet-logo.svg',
    fullName: 'EarnBet',
    name: 'BET',
  },
];

export const cryptoList: currencyList[] = [
  {
    src: 'assets/icons/crypto/btc-logo.svg',
    fullName: 'Bitcoin',
    name: 'BTC',
  },
  {
    src: 'assets/icons/crypto/eth-logo.svg',
    fullName: 'Ethereum',
    name: 'ETH',
  },
  {
    src: 'assets/icons/crypto/eos-logo.svg',
    fullName: 'EOS',
    name: 'EOS',
  },
  {
    src: 'assets/icons/crypto/ltc-logo.svg',
    fullName: 'Litecoin',
    name: 'LTC',
  },
  {
    src: 'assets/icons/crypto/bch-logo.svg',
    fullName: 'Bitcoin Cash',
    name: 'BCH',
  },
  {
    src: 'assets/icons/crypto/wax-logo.svg',
    fullName: 'WAX',
    name: 'WAX',
  },
  {
    src: 'assets/icons/crypto/xrp-logo.svg',
    fullName: 'Ripple',
    name: 'XRP',
  },
  {
    src: 'assets/icons/crypto/bnb-logo.svg',
    fullName: 'Binance',
    name: 'BNB',
  },
  {
    src: 'assets/icons/crypto/trx-logo.svg',
    fullName: 'Tron',
    name: 'TRX',
  },
  {
    src: 'assets/icons/crypto/link-logo.svg',
    fullName: 'Chainlink',
    name: 'LINK',
  },
  {
    src: 'assets/icons/crypto/dai-logo.svg',
    fullName: 'DAI',
    name: 'DAI',
  },
  {
    src: 'assets/icons/crypto/usdc-logo.svg',
    fullName: 'USD Coin',
    name: 'USDC',
  },
  {
    src: 'assets/icons/crypto/usdt-logo.svg',
    fullName: 'Tether',
    name: 'USDT',
  },
  {
    src: 'assets/icons/crypto/bet-logo.svg',
    fullName: 'EarnBet',
    name: 'BET',
  },
];
