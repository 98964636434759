import Big from 'big.js';

import { AssetAmount } from 'src/app/currency/amount/integer-math';
import { getStakingLevelName } from 'src/app/staking/models/staking-level-data';
import { IResolvedBet } from '../inputs/interfaces';
import { IBetResult } from './interfaces';
import { roundDownWithoutTrailingZeros } from 'src/app/util/math-util';
import { TokenSettingsService } from 'src/app/currency/tokens/services/token-settings.service';

export class BetResult implements IBetResult {
  readonly id: number;
  readonly userId: number;
  readonly time: Date;
  readonly bettor: string;

  readonly game: string;

  readonly betAmount: AssetAmount;

  readonly amount: string;
  readonly tokenSymbol: string;
  readonly amountInUSD: number;

  readonly payoutFactor: number;
  readonly payout: string;
  readonly payoutInUSD: number;

  readonly stakeWeight: string;
  readonly stakingLevelName: string | undefined;

  readonly isWin: boolean;

  readonly profit: number;

  // TODO:
  readonly hidden = false;

  constructor(data: IResolvedBet, service: TokenSettingsService) {
    this.id = data.id;
    this.time = new Date(data.resolvedMilliseconds);

    this.bettor = data.bettor;

    this.game = data.game;

    this.payoutFactor = Number(data.payoutFactor);

    const parts = data.amount.split(' ');

    this.betAmount = new AssetAmount(data.amount);

    this.payout = roundDownWithoutTrailingZeros(
      data.payout.toFixed(this.betAmount.precision + 1),
      this.betAmount.precision
    );

    this.amount = this.betAmount.decimalWithoutTrailingZeros;

    this.tokenSymbol = parts[1];

    this.stakeWeight = data.stakeWeight || '0';

    this.stakingLevelName = getStakingLevelName(this.stakeWeight);

    this.userId = data.userId;

    this.isWin = data.isWin;

    this.profit = Number(
      this.isWin
        ? new Big(this.payout).minus(this.amount)
        : new Big(this.amount).times(-1)
    );

    const price = service.getPrice(this.tokenSymbol);

    this.amountInUSD = price * Number(this.amount);
    this.payoutInUSD = price * Number(this.payout);
  }
}
