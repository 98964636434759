<div>
    <div class="tab-table total">
        <div class="header">
            <span class="col-4 col-md-2">Total Bets</span>
            <span class="col-5 col-md-3">Total Wagered</span>
        </div>
        <div class="tab-card">
            <div class="row">
                <div class="col-4 col-md-2">
                    <span>{{ userData.betStatistics.totalBets | number }}</span>
                </div>
                <div class="col-5 col-md-3">
                    <span>${{ userData.betStatistics.totalWagered | number }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-table bets">
        <div class="header">
            <span class="col-4 col-md-5">Coin</span>
            <span class="col-3 col-md-2">Bets</span>
            <span class="col-5 col-md-3">Total Wagered</span>
        </div>
        <div class="tab-card" *ngFor="let item of
            userData.betStatistics.currency; let i= index">
            <div class="row">
                <span class="col-4 col-md-5">
                    <img [attr.src]="getSource(item.name)"
                        [alt]="item.currency"
                        /> {{item.fullName}}
                </span>
                <div class="col-4 col-md-2">
                    <span>{{ item.bets | number }}</span>
                </div>
                <div class="col-5 col-md-3">
                    <span>{{ item.wagered | number: "1.8-8" }}</span>

                </div>
            </div>
        </div>
    </div>
</div>