import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-trophies',
  templateUrl: './user-trophies.component.html',
  styleUrls: ['./user-trophies.component.scss']
})
export class UserTrophiesComponent implements OnInit {
  userData = {
    trophies:[
      {type:'gold', date:'7 January 2022'},
      {type:'silver', date:'10 December 2021'},
      {type:'silver', date:'3 December 2021'},
      {type:'bronze', date:'1 October 2021'},
      {type:'silver', date:'19 November 2021'},
      {type:'gold', date:'20 August 2021'},
    ],
  }
  constructor() { }

  ngOnInit(): void {
  }

}
