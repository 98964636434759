import { BigSource } from 'big.js';
import { CurrencyMath } from './currency-math';
import { ICurrencyData } from './interfaces';

export class CurrencyAmount extends CurrencyMath {
  constructor(currency: ICurrencyData, decimalValue: BigSource = 0) {
    super(currency.precision, currency.symbol);

    this.initWithDecimal(decimalValue);
  }

  get quantity() {
    return this.assetString;
  }
}
