import { Component, Input } from '@angular/core';

import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from '../services/app.service';

@Component({ template: '' })
export abstract class CountdownComponentBase extends AppComponentBase {
  private _endTime: Date;

  @Input()
  set endTime(value: Date) {
    this._endTime = value;
  }
  get endTime() {
    return this._endTime;
  }

  private _millisecondsRemaining = 0;

  private intervalId: NodeJS.Timeout;

  constructor(app: AppService) {
    super(app);

    this.intervalId = setInterval(this.updateMillisecondsRemaining, 1000);
  }

  ngOnChanges() {
    this.updateMillisecondsRemaining();
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  private updateMillisecondsRemaining = () => {
    const endTime = this.endTime;

    if (endTime) {
      const diff = endTime.getTime() - Date.now();

      this._millisecondsRemaining = diff > 0 ? diff : 0;
    }
  };

  get days() {
    return Math.floor(this.millisecondsRemaining / (1000 * 60 * 60 * 24));
  }

  get hours() {
    return Math.floor((this.millisecondsRemaining / (1000 * 60 * 60)) % 24);
  }

  get minutes() {
    return Math.floor((this.millisecondsRemaining / 1000 / 60) % 60);
  }

  get seconds() {
    return Math.floor((this.millisecondsRemaining / 1000) % 60);
  }

  protected get millisecondsRemaining() {
    return this._millisecondsRemaining;
  }
}
