<div class="badge">
  <div [ngClass]="userRole" *ngIf="userRole == 'user'">
    <div class="level-badge" [ngClass]="'r' + rankReturn(level)">
      <img src="" />
      <span class="rank">{{ level }}</span>
    </div>
  </div>

  <div [ngClass]="userRole" *ngIf="userRole == 'admin'">
    <div class="staff-badge">
      <img
        src="assets/images/level_badge/logo_white.svg"
        alt="White EarnBet logo"
      />
    </div>
    <div class="staff-badge--2">
      <span>Admin</span>
    </div>
  </div>

  <div [ngClass]="userRole" *ngIf="userRole == 'moderator'">
    <div class="staff-badge">
      <img
        src="assets/images/level_badge/ace_cyan.svg"
        alt="Small cyan spade symbol"
      />
      <span>{{ level }}</span>
    </div>
    <div class="staff-badge--2">
      <span>Moderator</span>
    </div>
  </div>

  <div [ngClass]="userRole" *ngIf="userRole == 'support'">
    <div class="staff-badge">
      <img
        src="assets/images/level_badge/logo_black.svg"
        alt="Black EarnBet logo"
      />
    </div>
    <div class="staff-badge--2">
      <span>Support</span>
    </div>
  </div>
</div>
