import { TokensService } from 'src/app/internal-games/services/tokens.service';

export abstract class TokensComponentBase {
  constructor(private tokensService: TokensService) {}

  get selectedToken() {
    return this.tokensService.selectedToken;
  }

  get tokens() {
    return this.tokensService.tokens;
  }

  get settings() {
    return this.tokensService.settings;
  }
}
