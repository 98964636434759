import { NgModule } from '@angular/core';
import { TipUserComponent } from './components/tip-user/tip-user.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { TipsService } from './services/tips.service';
import { CurrencyTokensModule } from '../currency/tokens/currency-tokens.module';

@NgModule({
  imports: [CommonModule, NgbModule, HttpClientModule, CurrencyTokensModule],
  declarations: [TipUserComponent],
  providers: [TipsService],
  exports: [TipUserComponent],
})
export class TipsModule {}
