import { Injectable, EventEmitter } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { LoginOutput } from 'src/app/users/outputs/login.output';
import { IUserDataOutput, IPublicDataOutput } from '../outputs/interfaces';

@Injectable()
export class AppEventsService {
  readonly userDataRequestEmitter = new EventEmitter<null>();
  readonly userDataReceivedEmitter = new EventEmitter<IUserDataOutput>();

  private publicDataRequestEmitter = new EventEmitter<null>();
  readonly publicDataReceivedEmitter = new EventEmitter<IPublicDataOutput>();

  readonly balanceChangeEmitter = new EventEmitter<number>();

  constructor(readonly authState: AuthStateService) {}

  async init() {
    // check existing local auth token
    this.authState.init();

    if (this.authState.isLoggedIn) {
      // if existing local auth token is found, then fetch user data
      this.userDataRequestEmitter.emit();
    } else {
      // if no existing auth token is found, load public data
      this.publicDataRequestEmitter.emit();
    }
  }

  // TODO: LoginOuput should contain user data and public data
  async onSuccessfulLogin(data: LoginOutput) {
    this.authState.onSuccessfulLogin(data);

    // TODO: this is temporary:
    // (in the future it should be part of the login response)
    this.userDataRequestEmitter.emit();
  }

  get userDataRequestEvent() {
    return this.userDataRequestEmitter.asObservable();
  }
  get userDataReceivedEvent() {
    return this.userDataReceivedEmitter.asObservable();
  }

  get publicDataRequestEvent() {
    return this.publicDataRequestEmitter.asObservable();
  }
  get publicDataReceivedEvent() {
    return this.publicDataReceivedEmitter.asObservable();
  }

  get balanceChangeEvent() {
    return this.balanceChangeEmitter.asObservable();
  }
}
