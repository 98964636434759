export enum TokenSymbol {
  BTC = 'BTC',
  ETH = 'ETH',
  EOS = 'EOS',
  BET = 'BET',
  LTC = 'LTC',
  XRP = 'XRP',
  BCH = 'BCH',
  BNB = 'BNB',
  WAX = 'WAX',
  TRX = 'TRX',
  LINK = 'LINK',

  BET_ETH = 'BET_ETH',

  DAI = 'DAI',
  USDC = 'USDC',
  USDT = 'USDT',

  STACK = 'STACK',

  FUN = 'FUN',
}
