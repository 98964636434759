import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CarouselModule } from 'ng-carousel-cdk';

import { CardScrollComponent } from './components/card-scroll/card-scroll.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { GamesListService } from './services/games-list.service';
import { FeaturedGamesComponent } from './components/featured-games/featured-games.component';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, CarouselModule],
  declarations: [
    CardScrollComponent,
    SearchBarComponent,
    FeaturedGamesComponent,
  ],
  exports: [
    CardScrollComponent,
    SearchBarComponent,

    FeaturedGamesComponent,

    CommonModule,
    RouterModule,
    CarouselModule,
  ],
  providers: [GamesListService],
})
export class GameComponentsModule {}
