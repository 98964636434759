import { NgModule } from '@angular/core';

import { AuthModule } from '../auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { NavigationService } from './navigation.service';

@NgModule({
  imports: [AuthModule, AppRoutingModule],
  providers: [NavigationService],
  exports: [AppRoutingModule, AuthModule],
})
export class NavigationModule {}
