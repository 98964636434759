import { EventEmitter, Injectable } from '@angular/core';
import { IRainEventData } from '../inputs/interfaces';
import { AppEventsService } from 'src/app/global-state/services/app-events.service';
import { AlertService } from 'src/app/notifications/services/alert.service';
import { sleep } from 'src/app/util/timer-util';

const RAIN_DURATION = 2 * 60 * 1000;

@Injectable()
export class RainStateService {
  private currentEvent: IRainEventData;

  private claimedEventId: number;
  private notifiedEventId: number;

  private isRainingState = false;
  private rainingStateEmitter = new EventEmitter<boolean>();

  constructor(events: AppEventsService, private alert: AlertService) {
    events.publicDataReceivedEvent.subscribe((data) =>
      this.onRainEventUpdate(data.rainEvent)
    );
  }

  onRainEventUpdate(data: IRainEventData) {
    this.currentEvent = data;

    if (this.isRainingState != this.isRaining) {
      this.isRainingState = this.isRaining;

      this.rainingStateEmitter.emit(this.isRainingState);
    }

    if (this.isRaining && !this.isNotified) {
      this.notifiedEventId = this.eventId;

      this.alert.warning(
        "It's raining!!! Please open the site chat to claim your free BTC!"
      );
    }

    /*
    const currentTime = Date.now();

    console.log({
      rainStartTime: this.rainStartTime,
      rainEndTime: this.rainEndTime,
      currentTime,
      timeUntilStart: (this.rainStartTime - currentTime) / (1000 * 60),
      isRaining: this.isRaining,
      progress: this.progress,
    });
    console.log({ rainEvent: data });
    */
  }

  onRainClaimed(eventId: number) {
    this.claimedEventId = eventId;
  }

  get progress() {
    return this.isRaining
      ? ((Date.now() - this.rainStartTime) / RAIN_DURATION) * 100
      : 0;
  }

  get isRaining() {
    return Date.now() >= this.rainStartTime && this.rainEndTime >= Date.now();
  }

  get rainEndTime() {
    return this.currentEvent ? this.rainStartTime + RAIN_DURATION : 0;
  }

  get rainStartTime() {
    return this.currentEvent
      ? new Date(this.currentEvent.claim_start_time).getTime()
      : 0;
  }

  get isDistributed() {
    return this.currentEvent;
  }

  get isNotified() {
    return this.notifiedEventId === this.currentEvent?.id;
  }

  get isClaimed() {
    return this.claimedEventId === this.currentEvent?.id;
  }

  get eventId() {
    return this.currentEvent?.id;
  }

  get rainStateChanged() {
    return this.rainingStateEmitter.asObservable();
  }
}
