import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CountDownComponent } from './components/count-down/count-down.component';
import { LeaderBoardComponent } from './components/leaderboard/leaderboard.component';
import { NftComponent } from './components/nft/nft.component';

@NgModule({
  imports: [NgbModule, CommonModule],
  declarations: [CountDownComponent, LeaderBoardComponent, NftComponent],
  exports: [LeaderBoardComponent, NgbModule, CommonModule],
})
export class LeaderboardComponentsModule {}
