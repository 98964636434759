<div class="modal-width ban">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body mute">
    <h4 class="modal-title" id="modal-basic-title">Ban user</h4>
    <h5>Ban this user for a certain time</h5>
    <form style="display: intial">
      <div class="form-input-holder">
        <label>DURATION</label>
        <div ngbDropdown class="dropdown-holder">
          <button
            type="button"
            class="btn"
            id="dropdownBasic1"
            [ngClass]="selected"
            ngbDropdownToggle
          >
            {{ selected }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              (click)="changeDuration('1 hour')"
              ngbDropdownItem
              value="oneHour"
            >
              1 hour
            </button>
            <button
              (click)="changeDuration('6 hours')"
              ngbDropdownItem
              value="fiveHours"
            >
              6 hours
            </button>
            <button
              (click)="changeDuration('12 hours')"
              ngbDropdownItem
              value="tweleHours"
            >
              12 hours
            </button>
            <button
              (click)="changeDuration('1 day')"
              ngbDropdownItem
              value="oneDay"
            >
              1 day
            </button>
            <button
              (click)="changeDuration('1 week')"
              ngbDropdownItem
              value="oneWeek"
            >
              1 week
            </button>
            <button
              (click)="changeDuration('1 month')"
              ngbDropdownItem
              value="oneMonth"
            >
              1 month
            </button>
            <button
              (click)="changeDuration('Forever')"
              ngbDropdownItem
              value="forever"
            >
              Forever
            </button>
          </div>
        </div>
      </div>
      <div class="form-input-holder simple">
        <label>Reason</label>
        <input type="number" value="0" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="modal.close('Save click')" class="cyan-btn">
      BAN USER
    </button>
  </div>
</div>
