import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';

import { ChatComponentsModule } from '../chat/chat-components.module';
import { LeaderboardComponentsModule } from '../leaderboard/leaderboard-components.module';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    NgxBootstrapSliderModule,

    ChatComponentsModule,
    LeaderboardComponentsModule,
  ],
  declarations: [MenuComponent],
  exports: [
    MenuComponent,
    ChatComponentsModule,
    LeaderboardComponentsModule,

    FormsModule,
    NgxBootstrapSliderModule,
  ],
})
export class SidebarComponentsModule {}
