import { Component } from '@angular/core';

import { GlobalVariablesService } from 'src/app/global-state/services/global-variables.service';

@Component({
  selector: 'span[language]',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent {
  language: string = 'EN';

  constructor(private variableService: GlobalVariablesService) {}

  ngOnInit() {
    this.variableService.currentLanguage.subscribe(
      (languageStatus) => (this.language = languageStatus)
    );
  }

  public changeLanguage(language: string): void {
    this.variableService.updateLanguage((this.language = language));
  }
}
