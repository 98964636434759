import { Injectable } from '@angular/core';

import { ICurrencyAmountData } from 'src/app/currency/amount/interfaces';
import { CurrencyBalancesService } from 'src/app/currency/balance/services/currency-balances.service';
import { IUserStakingDataOutput } from 'src/app/staking/outputs/interfaces';

import { IUserDataOutput } from '../outputs/interfaces';
import { IUserStateService } from './interfaces';
import { ReferrerService } from './referrer.service';
import { IUserProfileData } from 'src/app/user-profile/outputs/interfaces';
import { UnrestrictedBalancesService } from 'src/app/currency/balance/services/unrestricted-balances.service';

@Injectable()
export class UserStateService implements IUserStateService {
  private isBalancesSynced = false;
  private isStakingDataLoaded = false;

  seedForRandomness = '';

  private _myReferralCode: string;
  private _profile: IUserProfileData;

  constructor(
    referrerService: ReferrerService,
    private balances: CurrencyBalancesService,
    readonly unrestrictedBalances: UnrestrictedBalancesService
  ) {
    // extract referrer from URL
    referrerService.retrieveReferrer();

    const { events } = balances.stakingData.tokenSettings;

    events.userDataReceivedEvent.subscribe(this.onUserDataReceived);
  }

  private onUserDataReceived = (data: IUserDataOutput) => {
    console.log('*** UserState.onUserDataReceived ***', data);

    this._myReferralCode = data.referrerInfo.referral_code;

    this._profile = data.profile;

    // sync user's token balances
    this.syncTokenBalances(data.balances);
    this.unrestrictedBalances.syncTokenBalances(data.unrestrictedBalances);

    // sync staking data
    this.updateStakingData(data.stakingData);
  };

  private syncTokenBalances(balances: ICurrencyAmountData[]) {
    this.balances.syncTokenBalances(balances);

    this.isBalancesSynced = true;
  }

  private updateStakingData(data: IUserStakingDataOutput) {
    this.balances.updateStakingData(data);

    this.isStakingDataLoaded = true;
  }

  get isBetBalanceLoaded() {
    return this.isBalancesSynced && this.isStakingDataLoaded;
  }

  get myReferralCode() {
    return this._myReferralCode;
  }

  get profile() {
    return this._profile;
  }
}
