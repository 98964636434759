import { TokenSymbol } from './token-symbols';

const binanceDepositAddress = 'bnb1k22unce9aqqva6ne3dz6w95ejskl0gvxxemgvv';
const rippleDepositAddress = 'r4nPUjprmhPh2e1v3TY8VXsh8THquBePFw';
const eosDepositAddress = 'earnbetdepo1';

// TODO: will we support WAX deposits?
const waxDepositAddress = '';

const DIVISOR_FOR_MIN_BET = 1e6;
const DIVISOR_FOR_JACKPOT_TICKET = 1e4;
const DIVISOR_FOR_BET_AIR_DROP = 40;
const DIVISOR_FOR_HIGH_ROLLERS = 80;

export const highRollerAmountInUSD = 100;

const commonTokens = [
  TokenSymbol.BTC,
  TokenSymbol.ETH,
  TokenSymbol.EOS,
  TokenSymbol.LTC,
  TokenSymbol.BCH,
  TokenSymbol.WAX,
  TokenSymbol.XRP,
  TokenSymbol.BNB,
  TokenSymbol.TRX,
  TokenSymbol.LINK,

  TokenSymbol.DAI,
  TokenSymbol.USDC,
  TokenSymbol.USDT,
];

export const tokensForBetting = [
  ...commonTokens,

  //TokenSymbol.FUN
];

export const tokensForDeposits = [...commonTokens, TokenSymbol.BET];
