<div class="modal-width ban">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>

  <div class="modal-body">
    <h4 class="modal-title" id="modal-basic-title">Tip user</h4>
    <h5>Send tip to: {{ username }}</h5>
    <form>
      <div currencyDropDown></div>

      <div class="form-input-holder simple">
        <label>AMOUNT</label>
        <input type="number" name="amount" [(ngModel)]="amount" />
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="sendTip()" class="cyan-btn">SEND TIP</button>
  </div>
</div>
