<!-- TODO: private mode is hidden for now -->
<div
  class="block info mobile"
  [style]="isLoggedIn && false ? '' : 'display: none'"
>
  <label class="toggle-switch">
    <input type="checkbox" (click)="togglePrivacy()" [(ngModel)]="incognito" />
    <div class="slider" [ngClass]="incognito ? 'ON' : 'OFF'">
      <span class="icognito-icon" [ngClass]="incognito ? 'ON' : 'OFF'"></span>
    </div>
  </label>
  <span class="toggle-label">Private mode</span>
</div>

<div class="menu-component">
  <div class="block first">
    <a
      class="link"
      routerLink="/profile"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/function/account_circle_gray.svg" alt="" />
      <span>My Profile</span>
    </a>
    <a
      class="link"
      routerLink="/favorites"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/fav_games.svg" alt="favorite games icon" />
      <span>My Favorite Games</span>
    </a>
    <a
      class="link"
      routerLink="/blackjack"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/blackjack.svg" alt="blackjack icon" />
      <span>Blackjack</span>
    </a>
    <a
      class="link"
      routerLink="/dice"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/dice.svg" alt="dice icon" />
      <span>Dice</span>
    </a>
    <a
      class="link"
      routerLink="/baccarat"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/baccarat.svg" alt="baccarat icon" />
      <span>Baccarat</span>
    </a>
    <a
      class="link"
      routerLink="/hilo"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/hilo.svg" />
      <span>Hilo</span>
    </a>
    <!--
    <a
      class="link"
      routerLink="/crash"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/crash.svg" />
      <span>Crash</span>
    </a>
    -->
    <a
      class="link"
      routerLink="/games/slots"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/slots.svg" />
      <span>Slots</span>
    </a>
    <a
      class="link"
      routerLink="/games/game-shows"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img class="live live-games" />
      <span>Live Game Shows</span>
    </a>
    <a
      class="link"
      routerLink="/games/table-games"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/table_games.svg" />
      <span>Table Games</span>
    </a>
    <a
      class="link"
      routerLink="/games/casino"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img class="live live-casino" />
      <span>Live Casino</span>
    </a>
    <!--
    <a class="link">
      <img src="assets/icons/menu/releases.svg" />
      <span>New Releases</span>
    </a>
    -->
  </div>
  <hr />
  <div class="block">
    <a
      class="link"
      routerLink="/bet-tokens"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/bet_tokens.svg" />
      <span>BET Tokens</span>
    </a>
    <a
      class="link"
      routerLink="/leaderboard"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/leaderboard.svg" />
      <span>Leaderboard</span>
    </a>
    <a
      class="link"
      routerLink="/vip"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/vip_club.svg" />
      <span>VIP MEMBER CLUB</span>
    </a>
    <!-- <a class="link">
            <img src="assets/icons/king-of-the-hill.png">
            <span>King Of The Hill</span>
        </a> -->
    <a
      class="link"
      routerLink="/affiliate"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/affiliate.svg" />
      <span>Affiliate Program</span>
    </a>

    <!--
    <a class="link">
      <img src="assets/icons/menu/provably_fair.svg" />
      <span>Provably Fair</span>
    </a>
    -->

    <!-- <a class="link">
            <img src="assets/icons/daily-rewards.png">
            <span>Daily Reward</span>
        </a> -->
    <a
      class="link"
      routerLink="/blog"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/blog.svg" />
      <span>Blog</span>
    </a>
    <!--
    <a class="link">
      <img src="assets/icons/menu/contacts.svg" />
      <span>Contact</span>
    </a>
    -->
    <a
      class="link"
      routerLink="/faq"
      routerLinkActive="active"
      (click)="closeMobileMenu()"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/icons/menu/faq.svg" />
      <span>FAQ</span>
    </a>
    <div class="button-group">
      <!-- link legacy account -->
      <button
        class="cyan-btn"
        *ngIf="isLoggedIn"
        (click)="app.navigator.gotoPage('link-legacy-account')"
      >
        Link Legacy Account
      </button>

      <!-- logout -->
      <button class="gray-btn-2" *ngIf="isLoggedIn" (click)="app.logout()">
        Log out
      </button>
    </div>
  </div>
</div>
