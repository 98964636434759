import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BonusBannerComponent } from './components/bonus-banner/bonus-banner.component';
import { SignUpBonusState } from './services/sign-up-bonus-state';
import { SignUpBonusService } from './services/sign-up-bonus.service';
import { BonusProgressComponent } from './components/bonus-progress/bonus-progress.component';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [BonusBannerComponent, BonusProgressComponent],
  providers: [SignUpBonusService, SignUpBonusState],
  exports: [BonusBannerComponent, BonusProgressComponent],
})
export class SignUpBonusModule {}
