import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from 'src/app/global-state/services/app.service';
import { sleep } from 'src/app/util/timer-util';
import { ChatService } from '../../services/chat.service';
import { IChatMessageData, IChatUser } from '../../outputs/interfaces';

const POPUP_PLACEMENT = ['top-start', 'bottom-start'];
@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent extends AppComponentBase implements OnInit {
  /*
  private array: IChatMessage[] = [
    { rank: '22', role: 'moderator', username: 'Samuel JJ', message: 'Open!' },
    { rank: '22', role: 'user', username: 'player5', message: 'Lets go!' },
    {
      rank: '48',
      role: 'user',
      username: 'blurrijs',
      message:
        'Needed a lot of testing test to see if two row messages or any amount or rows look fine...... Well I guess it does look ok',
    },
    { rank: '68', role: 'user', username: 'Adam5', message: 'I lost again...' },
    { rank: '50', role: 'admin', username: 'YooMan', message: 'Lets go!' },
    { rank: '50', role: 'support', username: 'YooMan', message: 'Need help?' },
    {
      rank: '158',
      role: 'user',
      username: 'Adam5',
      message: 'I lost again...',
    },
  ];
  */

  // for modal
  closeResult = '';

  /***************************************************************************/
  @ViewChild('msgBoard')
  msgBoard: any;

  @ViewChild('tipUser')
  tipUser: any;

  selectedUser: IChatUser;

  message: string = '';

  popUpPlacement = '';

  showEmojis: boolean = false;
  showRainBanner = true;

  constructor(private chat: ChatService, app: AppService) {
    super(app);

    chat.chatEvent.subscribe(this.showLastChatMessage);

    app.rainState.rainStateChanged.subscribe((isRaining) => {
      if (isRaining) {
        this.showRainBanner = true;
      }
    });
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    await sleep(300);
    this.showLastChatMessage();
  }

  popUpPositionChange(i: number) {
    if (i >= this.messages.length - 5) {
      this.popUpPlacement = POPUP_PLACEMENT[0];
    } else {
      this.popUpPlacement = POPUP_PLACEMENT[1];
    }
  }

  private showLastChatMessage = async () => {
    await sleep(100);

    const div = this.msgBoard.nativeElement;
    div.scrollTop = div.scrollHeight;
  };

  sendMessage() {
    if (!this.isLoggedIn) {
      this.alert.warning('You must be logged in to chat.');
      return;
    }

    this.chat.sendChatMessage(this.message);

    this.message = '';
  }

  openTipUser(message: IChatMessageData) {
    this.selectedUser = message.user;

    this.modals.open(this.tipUser);
  }

  /***/

  onEmojiSelected(event: any) {
    console.log(event.char);
    this.message += event.char;
  }

  get messages() {
    return this.chat.messages;
  }

  get hasReceivedSignUpBonus() {
    return this.app.signUpBonus.state.hasCompletedAllSteps;
  }
}
