import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiServiceBase } from 'src/app/http/services/api-base.service';
import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { environment } from 'src/environments/environment';
import { ISignedEarnBetAccountData } from '../inputs/interfaces';
import { SignatureService } from './signature.service';

@Injectable()
export class LegacyUsersService extends ApiServiceBase {
  constructor(
    errorHandler: ErrorHandlerService,
    http: HttpClient,
    private signature: SignatureService
  ) {
    super(`${environment.apiUrl}/legacy-users`, http, errorHandler);
  }

  async claimEarnBetAccount() {
    // TODO: how will we handle referrals for claimed accounts?

    const signedData =
      await this.signature.signEarnBetAccountData<ISignedEarnBetAccountData>({
        userIdForClaim: this.errorHandler.authState.userId,
      });

    return this.post('claim-earnbet-account', signedData);
  }

  async claimWaxAccount(waxAccountName: string) {
    // TODO: how will we handle referrals for claimed accounts?

    return this.post('claim-wax-account', { waxAccountName });
  }
}
