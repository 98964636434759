import { StakingData } from 'src/app/staking/services/staking-data';
import { numStringToTotalDigits } from 'src/app/util/math-util';
import { CurrencyAmount } from '../../amount/currency-amount';
import { tokensForBetting } from '../../tokens/settings/token-config';
import { TokenSymbol } from '../../tokens/settings/token-symbols';
import { ICurrencyAmountData, ICurrencyData } from '../../amount/interfaces';
import { EventEmitter } from '@angular/core';
import { BigSource } from 'big.js';
import { sleep } from 'src/app/util/timer-util';

export class CurrencyBalances {
  private allTokens: ICurrencyData[] = [];

  private balances: { [symbol: string]: CurrencyAmount } = {};

  private _bettingBalances: { symbol: string; balance: string }[] = [];

  private balanceChangedEmitter = new EventEmitter<string>();

  private isInit = false;

  constructor(readonly stakingData: StakingData) {
    this.initBalances();
  }

  private async initBalances() {
    this.allTokens = await this.stakingData.tokenSettings.getAllTokens();

    this.allTokens.push({ symbol: 'FUN', precision: 8 });

    for (const token of this.allTokens) {
      this.balances[token.symbol] = new CurrencyAmount(token);
      this.balanceChangedEmitter.emit(token.symbol);
    }

    this.updateBettingBalances();

    this.isInit = true;
  }
  private updateBettingBalances() {
    this._bettingBalances = [];

    for (const symbol of tokensForBetting) {
      this._bettingBalances.push({
        symbol,
        balance: this.getBalance(symbol),
      });
    }
  }

  async syncTokenBalances(balances: ICurrencyAmountData[]) {
    while (!this.isInit) {
      await sleep(10);
    }

    balances.forEach(this.setBalance);
  }
  protected setBalance = (data: ICurrencyAmountData) => {
    const balance = this.balances[data.currency_symbol];

    if (balance) {
      balance.initWithDecimal(data.decimal_amount);
      this.balanceChangedEmitter.emit(data.currency_symbol);

      this.updateBettingBalances();
    }
  };

  addToBalance(tokenSymbol: string, amount: BigSource) {
    if (isNaN(amount as number)) {
      return;
    }

    const balance = this.balances[tokenSymbol];

    if (balance) {
      balance.addDec(amount);
      this.balanceChangedEmitter.emit(tokenSymbol);

      this.updateBettingBalances();
    }
  }

  get totalBetTokens() {
    return this.unstakedBetTokens + this.stakedBetTokens;
  }

  get stakedBetTokens() {
    return this.stakingData.totalTokensStaked;
  }

  get unstakedBetTokens() {
    return Number(this.betBalance);
  }

  get betBalance() {
    return this.getBalance(TokenSymbol.BET);
  }

  getBalance(tokenSymbol: string): string {
    const balance = this.balances[tokenSymbol];

    return numStringToTotalDigits(balance.decimal, 9, balance.precision);
  }

  getBalanceAmount(tokenSymbol: string) {
    return this.balances[tokenSymbol];
  }

  get bettingBalances() {
    return this._bettingBalances;
  }

  get balanceChangedEvent() {
    return this.balanceChangedEmitter.asObservable();
  }
}
