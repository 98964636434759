import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiServiceBase } from 'src/app/http/services/api-base.service';
import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { environment } from 'src/environments/environment';
import { ILeaderboardData } from '../outputs/interfaces';

@Injectable()
export class LeaderboardApiService extends ApiServiceBase {
  constructor(errorHandler: ErrorHandlerService, http: HttpClient) {
    super(`${environment.apiUrl}/leaderboard`, http, errorHandler);
  }

  getLeaderboardByPeriod(periodId: number) {
    return this.get<ILeaderboardData>('' + periodId);
  }
}
