export function sleep(forMilliseconds: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, forMilliseconds));
}

export interface ITimerListener {
  onTimerTick(): void;
}

export class CustomTimer {
  private intervalId: any = undefined;

  constructor(
    private intervalInMilliseconds: number,
    private listener: ITimerListener
  ) {}

  start() {
    if (this.isRunning) {
      return;
    }

    this.intervalId = setInterval(this.tick, this.intervalInMilliseconds);
  }
  private tick = () => {
    this.listener.onTimerTick();
  };

  stop() {
    if (!this.isRunning) {
      return;
    }

    clearInterval(this.intervalId);

    this.intervalId = undefined;
  }

  private get isRunning() {
    return this.intervalId != undefined;
  }
}
