import { Component } from '@angular/core';
import { AppService } from 'src/app/global-state/services/app.service';

import { SoundsHelperService } from 'src/app/global-state/services/sounds.service';

import { sleep } from 'src/app/util/timer-util';
import { TokensComponentBase } from '../base/tokens-component-base';

@Component({
  selector: 'div[tokenBalances]',
  templateUrl: './token-balances.component.html',
  styleUrls: ['./token-balances.component.scss'],
  host: {
    class: 'deposit-holder',
  },
})
export class TokenBalancesComponent extends TokensComponentBase {
  hideZeroValues = false;

  showBalanceChange = false;
  showBalanceIncrease = false;
  showBalanceDecrease = false;

  balanceChangeAmount = '';

  constructor(app: AppService, private playSound: SoundsHelperService) {
    super(app.tokens);

    app.events.balanceChangeEvent.subscribe(this.handleBalanceChange);
  }

  hoverMenu() {
    this.playSound.playDepositHover();
  }

  testBalanceChange() {
    const change = 0.5 - Math.random();
    this.handleBalanceChange(change);
  }

  private handleBalanceChange = async (changeInBalance: number) => {
    if (changeInBalance == 0) {
      return;
    }

    if (changeInBalance > 0) {
      this.showBalanceIncrease = true;
      this.balanceChangeAmount = '+';
    } else {
      this.showBalanceDecrease = true;
      this.balanceChangeAmount = '-';
    }

    this.balanceChangeAmount += changeInBalance.toString().substring(0, 10);

    this.showBalanceChange = true;

    await sleep(800);

    this.showBalanceChange = false;
    this.showBalanceIncrease = false;
    this.showBalanceDecrease = false;
  };

  isZero(balance: string) {
    return Number(balance) == 0;
  }
}
