import { Injectable } from '@angular/core';

import { SignUpBonusService } from 'src/app/sign-up-bonus/services/sign-up-bonus.service';
import { FIFOList } from 'src/app/util/collections';
import { IBetResult } from '../outputs/interfaces';
import { IUserDataOutput } from 'src/app/global-state/outputs/interfaces';
import { IRecentBetsLists, IResolvedBet } from '../inputs/interfaces';
import { BetResult } from '../outputs/bet-result';
import { CurrencyBalancesService } from 'src/app/currency/balance/services/currency-balances.service';

enum BetsTab {
  ALL,
  MY_BETS,
  HIGH_ROLLER,
  LUCKY_WINS,
}

const MAX_LENGTH_OF_LISTS = 20;

@Injectable()
export class ResolvedBets {
  private all = new FIFOList<IBetResult>(MAX_LENGTH_OF_LISTS);
  private mine = new FIFOList<IBetResult>(MAX_LENGTH_OF_LISTS);

  private highRollers = new FIFOList<IBetResult>(MAX_LENGTH_OF_LISTS);
  private luckyWins = new FIFOList<IBetResult>(MAX_LENGTH_OF_LISTS);

  private lists = [this.all, this.mine, this.highRollers, this.luckyWins];

  private _isPublicBetsLoaded = false;
  private isMyBetsLoaded = false;

  private _selectedTab: number = BetsTab.ALL;

  constructor(
    private signUpBonus: SignUpBonusService,
    readonly balances: CurrencyBalancesService
  ) {
    const events = balances.stakingData.tokenSettings.events;

    events.userDataReceivedEvent.subscribe(this.onUserDataReceived);
  }

  initRecentBets({ all, largeBets, luckyBets }: IRecentBetsLists) {
    if (!this.isPublicBetsLoaded) {
      this._isPublicBetsLoaded = true;

      this.addBetResultsToList(all, this.all);

      this.addResolvedBetsToList(largeBets, this.highRollers);
      this.addResolvedBetsToList(luckyBets, this.luckyWins);
    }
  }

  private onUserDataReceived = (data: IUserDataOutput) => {
    const { recentBets } = data;

    if (!this.isMyBetsLoaded) {
      this.isMyBetsLoaded = true;

      this.addResolvedBetsToList(recentBets, this.mine);
    }
  };

  private async addResolvedBetsToList(
    bets: IResolvedBet[],
    list: FIFOList<IBetResult>
  ) {
    await this.tokenSettings.waitForInit();

    bets
      .slice(0, MAX_LENGTH_OF_LISTS)
      .forEach((bet) => list.addAsLast(new BetResult(bet, this.tokenSettings)));
  }

  private addBetResultsToList(bets: IBetResult[], list: FIFOList<IBetResult>) {
    bets.forEach((bet) => list.addAsFirst(bet));
  }

  set selectedTab(value: number) {
    this._selectedTab = value;
  }
  get selectedTab() {
    return this._selectedTab;
  }

  onBetResolved(data: IResolvedBet) {
    const newBet = new BetResult(data, this.tokenSettings);

    this.onBetResult(newBet);
  }
  async onBetResult(newBet: IBetResult) {
    await this.tokenSettings.waitForInit();

    if (newBet.tokenSymbol !== 'FUN') {
      this.all.addAsFirst(newBet);
    }

    const isMyBet = this.isMyBet(newBet);

    if (isMyBet) {
      this.mine.addAsFirst(newBet);

      this.balances.updateBalances(newBet);
    }

    // Check for High Roller
    if (this.isHighRollerBet(newBet)) {
      this.highRollers.addAsFirst(newBet);
    }

    // Check for Lucky Win
    if (newBet.payoutFactor >= 10 && Number(newBet.payout) > 0) {
      this.luckyWins.addAsFirst(newBet);
    }

    if (isMyBet) {
      this.signUpBonus.syncProgressForSignUpBonus();
    }
  }

  private isHighRollerBet(newBet: IBetResult): boolean {
    const token = this.tokenSettings.getSettings(newBet.tokenSymbol);

    return (
      token.highRollerAmount != undefined &&
      Number(newBet.amount) >= token.highRollerAmount
    );
  }

  private isMyBet(bet: IBetResult): boolean {
    return bet.userId == this.authState.userId;
  }

  get isHighRollerTabSelected() {
    return this.selectedTab == BetsTab.HIGH_ROLLER;
  }

  get selectedList() {
    return this.lists[this.selectedTab].items;
  }

  get tokenSettings() {
    return this.balances.stakingData.tokenSettings;
  }

  private get authState() {
    return this.balances.authState;
  }

  get isPublicBetsLoaded() {
    return this._isPublicBetsLoaded;
  }
}
