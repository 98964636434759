import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  IPublicDataOutput,
  IUserDataOutput,
} from 'src/app/global-state/outputs/interfaces';
import { AppEventsService } from 'src/app/global-state/services/app-events.service';
import {
  IClaimDividendsOutput,
  IDividendsInfoOutput,
  IUserDividendsData,
} from '../outputs/interfaces';
import { ProtectedAPIService } from 'src/app/http/services/protected-api.service';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';

@Injectable()
export class DividendsService extends ProtectedAPIService {
  private _nextPayoutDate: Date;
  private info: IDividendsInfoOutput;

  private _totalUnclaimedDividends = 0;
  private _hasUnclaimedDividends = false;

  constructor(
    events: AppEventsService,
    errorHandler: ErrorHandlerService,
    http: HttpClient
  ) {
    super(
      `${environment.apiUrl}/dividends`,
      http,
      events.authState,
      errorHandler
    );

    events.publicDataReceivedEvent.subscribe(this.onPublicDataReceived);
    events.userDataReceivedEvent.subscribe(this.onUserDataReceived);
  }
  private onPublicDataReceived = (data: IPublicDataOutput) => {
    this.info = data.dividends;

    this._nextPayoutDate = new Date(this.info.next_payout_date);
  };
  private onUserDataReceived = (data: IUserDataOutput) => {
    const { dividends } = data;

    this.syncUserData(dividends);
  };

  syncUserData(dividends: IUserDividendsData) {
    this._totalUnclaimedDividends = 0;

    for (const record of dividends.unclaimed) {
      this._totalUnclaimedDividends += Number(record.usd_amount);
    }

    this._hasUnclaimedDividends = dividends.unclaimed.length > 0;
  }

  claimDividends() {
    return this.postWithAuthToken<IClaimDividendsOutput>('claim');
  }

  get nextPayoutDate() {
    return this._nextPayoutDate;
  }

  get pendingDividends() {
    return this.info?.pending_dividends_usd;
  }

  get totalDividendsPaid() {
    return this.info?.total_all_time_dividends_usd;
  }

  get totalUnclaimedDividends() {
    return this._totalUnclaimedDividends;
  }
  get hasUnclaimedDividends() {
    return this._hasUnclaimedDividends;
  }
}
