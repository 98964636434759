import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/navigation/navigation.service';
import { SignUpBonusState } from '../../services/sign-up-bonus-state';

@Component({
  selector: 'bonus-banner',
  templateUrl: './bonus-banner.component.html',
  styleUrls: ['./bonus-banner.component.scss'],
})
export class BonusBannerComponent implements OnInit {
  @Input() transaction?: Boolean;

  constructor(
    readonly state: SignUpBonusState,
    readonly navigator: NavigationService
  ) {}

  ngOnInit(): void {}

  get depositProgressAmount() {
    return Math.round(this.depositProgress);
  }

  get depositProgress() {
    return this.state.depositProgress;
  }

  get wagerProgressAmount() {
    return Math.round(this.wagerProgress);
  }

  get wagerProgress() {
    return this.state.betsVolumeProgress;
  }

  get stage(): number {
    return this.state.hasCompletedAllSteps
      ? 3
      : this.state.isAccountCreated
      ? 2
      : 1;
  }
}
