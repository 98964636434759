function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function getMethodNameForMessage(message: string): string {
  const parts = message.split('_');
  for (let i = 0; i < parts.length; i++) {
    parts[i] = capitalizeFirstLetter(parts[i]);
  }

  return 'on' + parts.join('');
}

export function dispatchMessage(args: IArguments, client: any) {
  const message: string = args[0];

  const methodName = getMethodNameForMessage(message);

  // console.log(methodName);
  // console.log(args);

  const argsArray = [];
  for (let i = 1; i < args.length; i++) {
    argsArray.push(args[i]);
  }

  const method = client[methodName];

  if (method == undefined) {
    console.log(methodName + ' DOES NOT EXIST!');

    return;
  }

  method.apply(client, argsArray);
}
