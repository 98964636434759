import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

export class PreloadStrategy implements PreloadingStrategy {
  private loadedModules: { [name: string]: boolean } = {};

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (!route.data?.name || this.loadedModules[route.data.name]) {
      return of(null);
    }

    this.loadedModules[route.data.name] = true;

    console.log('preloading module: ' + route.data.name);

    return load();
  }
}
