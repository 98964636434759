<div class="counter">
    <div class="content">
        <p>TIME REMAINING</p>
        <div class="timer">
            <div>
                <span class="time">{{days}}</span>
                <span class="units">Days</span>
            </div>
            <div>
                <span class="time">{{hours}}</span>
                <span class="units">Hours</span>
            </div>
            <div>
                <span class="time">{{minutes}}</span>
                <span class="units">Min</span>
            </div>
            <div>
                <span class="time">{{seconds}}</span>
                <span class="units">Sec</span>
            </div>
        </div>
    </div>
</div>
