<div class="modal-width rain-bot">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <h4 class="modal-title" id="modal-basic-title">Rain Bot</h4>
    <h5>Be ready! There will be 2 rains per hour at random times</h5>
    <span class="header-label">Next rain amount</span>
    <span class="amount cyan">$25.00</span>
    <form>
      <div class="form-input-holder">
        <label>CURRENCY</label>
        <div ngbDropdown class="dropdown-holder">
          <button
            type="button"
            class="btn currency-input"
            id="dropdownBasic1"
            [ngClass]="selected"
            ngbDropdownToggle
          >
            {{ selected }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              *ngFor="let item of currency"
              (click)="changeCurrency(item.fullName)"
              [(value)]="item.fullName"
              ngbDropdownItem
            >
              <img
                src="{{ item.src }}"
                alt="{{ item.fullName }}
                                icon"
              />
              {{ item.fullName }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-input-holder simple">
        <label>TIP TO NEXT RAIN</label>
        <input type="number" value="0" />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="modal.close('Save click')" class="cyan-btn">
      ADD TIP
    </button>
  </div>
</div>
