import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { SidebarModule } from 'ng-sidebar';

import { SidebarComponentsModule } from '../sidebar/sidebar-components.module';

import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { MigrationModalComponent } from './components/migration-modal/migration-modal.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { GameComponentsModule } from '../external-games/game-components.module';
import { GlobalStateModule } from '../global-state/global-state.module';
import { AlertComponent } from './components/alert/alert.component';
import { LanguageComponent } from './components/language/language.component';
import { CurrencyTokensModule } from '../currency/tokens/currency-tokens.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxEmojiPickerModule.forRoot(),

    NgbModule,
    SidebarModule.forRoot(),

    GlobalStateModule,

    GameComponentsModule,
    SidebarComponentsModule,
    CurrencyTokensModule,
  ],
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    MigrationModalComponent,
    AlertComponent,
    LanguageComponent,
  ],

  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
