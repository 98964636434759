import { Injectable } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { AlertService } from 'src/app/notifications/services/alert.service';

import { IErrorHandlerService } from './interfaces';

const UNAUTHORIZED_STATUS_CODE = 600;

@Injectable()
export class ErrorHandlerService implements IErrorHandlerService {
  constructor(
    readonly authState: AuthStateService,
    private alert: AlertService
  ) {}

  handleRequestError(
    error: {
      statusCode?: number;
      message?: string | string[];
    },
    reject: Function
  ) {
    if (error.statusCode == UNAUTHORIZED_STATUS_CODE) {
      this.promptToLogin();
      return;
    }

    if (typeof error.message == 'string') {
      this.alert.error(error.message);
    } else if (error.message && error.message[0]) {
      this.alert.error(error.message[0]);
    }

    console.error(error);

    // only reject if it's not the unauthorized error
    reject(error);
  }

  private promptToLogin() {
    this.authState.onLogout();
    this.navigator.gotoPage('login');
    this.alert.error('Please Login Again...');
  }

  get navigator() {
    return this.alert.navigator;
  }
}
