import { AppSettings } from 'src/app/global-state/services/app-settings.service';
import { IFunnelCTA } from './interfaces';

export class FunnelCTAName {
  static readonly DEMO_BETS = 'demoBets';
  static readonly DIVIDENDS = 'dividends';
  static readonly CREATE_ACCOUNT = 'createAccount';
  static readonly DEPOSIT = 'deposit';
  static readonly REAL_BETS = 'realBets';
}

abstract class FunnelCTA implements IFunnelCTA {
  constructor(readonly id: string, private settings: AppSettings) {}

  abstract get buttonLabel(): string;

  protected get translation() {
    return this.settings.translation;
  }
}

export class DemoBetsCTA extends FunnelCTA {
  constructor(settings: AppSettings) {
    super(FunnelCTAName.DEMO_BETS, settings);
  }

  get buttonLabel() {
    return 'Place Demo Bets';
    //TODO: return this.translation.placeDemoBets;
  }
}

export class DividendsCTA extends FunnelCTA {
  constructor(settings: AppSettings) {
    super(FunnelCTAName.DIVIDENDS, settings);
  }

  get buttonLabel() {
    return 'Learn About Dividends';
    //TODO: return this.translation.learnAboutDividends;
  }
}

export class CreateAccountCTA extends FunnelCTA {
  constructor(settings: AppSettings) {
    super(FunnelCTAName.CREATE_ACCOUNT, settings);
  }

  get buttonLabel() {
    return 'Sign Up';
    //TODO: return this.translation.signUp;
  }
}

export class DepositCTA extends FunnelCTA {
  constructor(settings: AppSettings) {
    super(FunnelCTAName.DEPOSIT, settings);
  }

  get buttonLabel() {
    return 'Depoist $100';
    //return this.translation.deposit75USD;
  }
}

export class RealBetsCTA extends FunnelCTA {
  constructor(settings: AppSettings) {
    super(FunnelCTAName.REAL_BETS, settings);
  }

  get buttonLabel() {
    return 'Start Betting!';
    //return this.translation.startBetting + '!';
  }
}
