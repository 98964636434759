import { Component, Input, OnInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { AppComponentBase } from 'src/app/global-state/components/app-component-base';
import { AppService } from 'src/app/global-state/services/app.service';
import { GlobalVariablesService } from 'src/app/global-state/services/global-variables.service';
import { NavigationService } from 'src/app/navigation/navigation.service';
import { SignUpBonusState } from 'src/app/sign-up-bonus/services/sign-up-bonus-state';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent extends AppComponentBase implements OnInit {
  @Input('soundDropdown') soundDropdown: NgbDropdown;
  @Input('stickyHeader') stickyHeader: boolean;

  incognito: boolean = false;
  fullScreenStatus: boolean = false;

  message: boolean = false;

  volumeValue: number;

  constructor(
    app: AppService,
    private variableService: GlobalVariablesService,
    readonly state: SignUpBonusState,
    readonly navigator: NavigationService
  ) {
    super(app);

    this.volumeValue = this.audio.volume;
  }

  ngOnInit(): void {
    this.setupListeners();
  }
  private setupListeners() {
    this.variableService.currentPrivacy.subscribe(
      (privacyStatus) => (this.incognito = privacyStatus)
    );

    this.variableService.currentfullScreenState.subscribe(
      (fullScreenState) => (this.fullScreenStatus = fullScreenState)
    );
  }

  onVolumeSliderStop() {
    this.audio.volume = this.volumeValue;
  }

  public togglePrivacy(): void {
    this.variableService.updatePrivacy(!this.incognito);
  }

  onHover(event: any): void {
    this.soundDropdown.open();
  }

  closeMobileMenu() {
    if (this.isMobile) {
      this.sidebar.closePanel();
    }
  }

  get showBonusProgress() {
    return this.stage < 3;
  }

  get stage(): number {
    return this.state.hasCompletedAllSteps
      ? 3
      : this.state.isAccountCreated
      ? 2
      : 1;
  }

  /*
  // FOR TESTING:
  get isLoggedIn(): boolean {
    return false;
  }
  */
}
