import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ban-user',
  templateUrl: './ban-user.component.html'
})
export class BanUserComponent implements OnInit {
  @Input() modal: any;
  selected = '1 hours';

  constructor() { }

  ngOnInit(): void {
  }

  changeDuration(duration:string){
    this.selected = duration;
  }
}
