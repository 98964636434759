import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';

import { NavigationService } from 'src/app/navigation/navigation.service';

import { IAlertMessage, IAlertService } from './interfaces';

@Injectable()
export class AlertService implements IAlertService {
  private subject = new Subject<IAlertMessage>();
  private keepAfterNavigationChange = false;

  constructor(router: Router, readonly navigator: NavigationService) {
    // clear alert message on route change
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(message: string, shouldScrollToShow: boolean = false) {
    // this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message });

    if (shouldScrollToShow) {
      this.scrollToTopOfPage();
    }
  }

  error(message: string) {
    this.showMessage({ type: 'error', message });
  }
  warning(message: string) {
    this.showMessage({ type: 'warning', message });
  }
  info(message: string) {
    this.showMessage({ type: 'info', message });
  }
  private showMessage({ type, message }: { type: string; message: string }) {
    // this.keepAfterNavigationChange = keepAfterNavigationChange;

    this.subject.next({ type, text: message });

    this.scrollToTopOfPage();
  }

  clear() {
    this.subject.next();
  }

  getMessage() {
    return this.subject.asObservable();
  }

  scrollToTopOfPage() {
    this.navigator.scrollToTopOfPage();
  }
}
