<label>CURRENCY</label>
<div ngbDropdown class="dropdown-holder">
  <button
    type="button"
    class="btn currency-input"
    [ngClass]="selectedToken?.symbol"
    [class.first-time-glow]="shouldGlow"
    id="dropdownBasic1"
    ngbDropdownToggle
    (click)="stopGlow.emit()"
  >
    {{ selectedToken?.symbol }}
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <button
      *ngFor="let token of tokens"
      (click)="settings.selectCurrency(token.symbol)"
      [(value)]="token.symbol"
      ngbDropdownItem
    >
      <img
        src="assets/icons/crypto/{{ token.symbol.toLowerCase() }}-logo.svg"
      />
      {{ token.symbol }}
    </button>
  </div>
</div>
