<div class="content">
  <div class="container up">
    <div class="upper">
      <div class="header">
        <div class="col-9 col-sm-10 col-md-11">
          <!--
          <a href="">
            <img
              class="logo"
              src="assets/images/logos/earnbet_logo_gray.svg"
              alt="Earnbet logo"
            />
          </a>
          -->
        </div>
        <div class="col-3 col-sm-2 col-md-1 social-icons">
          <a href="">
            <img src="assets/icons/social/telegram.svg" alt="Telegram logo" />
          </a>
          <a href="">
            <img
              src="assets/icons/social/twitter.svg"
              alt="Twitter
                            logo"
            />
          </a>
        </div>
      </div>
      <p>
        BeTheHouse.com is owned and operated by Aurora Tech N.V., a company
        registered and established under the laws of Curaçao, registration
        number 148186, and registered address at Abraham de Veerstraat 7
        Willemstad, Curaçao. All players must follow the laws in their
        jurisdiction. Must be 18 years or older to play.
      </p>
      <div class="docs">
        <a routerLink="/responsible-gambling">Responsible Gambling</a>
        <a routerLink="/aml">AML</a>
        <a routerLink="/terms">Terms & Conditions</a>
        <a routerLink="/privacy-policy">Privacy Policy</a>
      </div>
      <div class="links">
        <div class="info block">
          <h6>Info</h6>
          <!--
          <a>About us</a>
          -->
          <a
            routerLink="/affiliate"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Affiliate</a
          >
          <!--
          <a href="">Provably Fair</a>
          -->
          <a
            routerLink="/blog"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Blog</a
          >
        </div>
        <div class="support block">
          <h6>Support</h6>
          <a href="">Livechat</a>
          <a
            routerLink="/faq"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >FAQ</a
          >
        </div>
        <!--Game section-->
        <!--
        <div class="games block">
          <h6>Games</h6>
          <a href="">Popular games</a>
          <a
            routerLink="/dice"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Dice</a
          >
          <a
            routerLink="/blackjack"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Blackjack</a
          >
          <a
            routerLink="/baccarat"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Baccarat</a
          >
          <a
            routerLink="/crash"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Crash</a
          >
          <a
            routerLink="/hilo"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Hilo</a
          >
          <a
            routerLink="/games/slots"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Slots</a
          >
          <a
            routerLink="/games/games-shows"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Live game shows</a
          >
          <a
            routerLink="/games/table-games"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Table games</a
          >
          <a
            routerLink="/games/casino"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Live casino</a
          >
          <a>New releases</a>
        </div>
        -->
        <!--Social section-->
        <!--
        <div class="social-media block">
          <h6>Social Media</h6>
          <a href="https://twitter.com/earnbetcasino">Follow us on Twitter</a>
          <a href="https://t.me/earnbetcasino">Join our Telegram</a>
          <a
            routerLink="/blog"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            >Blog</a
          >
        </div>
        -->
      </div>
    </div>
  </div>
  <div class="container down">
    <div class="lower">
      <span class="col-md-6">
        © {{ currentYear }}, Aurora Tech N.V. v 3-P
      </span>
      <div class="col-md-6">
        <img
          class="img-1"
          src="assets/icons/social/plus18.svg"
          alt="Number 18 with a plus icon"
        />
        <img
          class="img-2"
          src="assets/images/logos/begamble_black.png"
          alt="Be Gamble aware logo"
        />
        <a
          target="_blank"
          href="https://licensing.gaming-curacao.com/validation/?lh=ff7b06bcb3a26358b67d93abf27d7b9f"
        >
          <img
            class="img-3"
            src="assets/images/logos/gaming_curacao.png"
            alt="Gaming Curacao logo"
        /></a>
      </div>
    </div>
  </div>
</div>
