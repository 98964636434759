import { CurrencyToken } from './currency-token';
import { ITokenSettings, ITokenSettingsData } from './interfaces';
import { highRollerAmountInUSD } from './token-config';
import { TokenSymbol } from './token-symbols';

export class TokenSettings extends CurrencyToken implements ITokenSettings {
  readonly priceInUSD: number;
  readonly highRollerAmount: number;

  readonly minimumBetAmount: number;
  readonly maximumWinAmount: number;

  readonly supportsMemoForTransfers: boolean;
  readonly minimumWithdrawalAmount: string;

  constructor(data: ITokenSettingsData) {
    super({ symbol: data.currency_symbol, precision: data.currency_precision });

    this.priceInUSD = data.currency_price_usd;

    this.highRollerAmount = highRollerAmountInUSD / this.priceInUSD;

    this.minimumBetAmount = 1 / Math.pow(10, data.currency_precision - 2);

    this.maximumWinAmount = data.max_win_amount;

    this.supportsMemoForTransfers = data.supports_memo;
    this.minimumWithdrawalAmount = data.minimum_withdrawal_amount;
  }

  get blockchainName() {
    switch (this.symbol) {
      case TokenSymbol.BTC:
        return 'Bitcoin Mainnet Chain';

      case TokenSymbol.EOS:
        return 'EOS Mainnet Chain';

      case TokenSymbol.LTC:
        return 'Litecoin Mainnet Chain';

      case TokenSymbol.BCH:
        return 'Bitcoin Cash Mainnet Chain';

      case TokenSymbol.WAX:
        return 'WAX Mainnet Chain';

      case TokenSymbol.XRP:
        return 'Ripple Mainnet Chain';

      case TokenSymbol.BNB:
        return 'Binance DEX Chain';

      case TokenSymbol.TRX:
        return 'Tron Mainnet Chain';

      case TokenSymbol.ETH:
      case TokenSymbol.LINK:
      case TokenSymbol.DAI:
      case TokenSymbol.USDC:
      case TokenSymbol.USDT:

      case TokenSymbol.BET:
        return 'Ethereum Mainnet Chain';

      default:
        return '';
    }
  }
}
