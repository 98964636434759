import { EventEmitter, Injectable } from '@angular/core';

import { LoginOutput } from 'src/app/users/outputs/login.output';

import { IAuthorizedUserData } from '../outputs/interfaces';

import { IAuthStateService } from './interfaces';

const userIdKey = 'userId';
const authTokenKey = 'userToken';
const usernameKey = 'userName';

@Injectable()
export class AuthStateService implements IAuthStateService {
  private _authToken: string | null = '';
  private _username: string | null = '';
  private _userId = 0;

  private authenticatedEmitter = new EventEmitter<IAuthorizedUserData>();

  constructor() {
    console.log('AuthStateService instantiated');
  }

  init() {
    this.loadAuthInfo();
    this.checkExistingToken();
  }

  private loadAuthInfo() {
    this._username = localStorage.getItem(usernameKey);
    this._authToken = localStorage.getItem(authTokenKey);
    this._userId = Number(localStorage.getItem(userIdKey) || 0);
  }

  private checkExistingToken() {
    // if logged in, then trigger authenticated event

    if (this.isLoggedIn) {
      this.authenticatedEmitter.emit(this.data);

      console.log('existing authentication event emitted...');
    }
  }

  onSuccessfulLogin(data: LoginOutput) {
    this.saveAuthInfo(data);
    this.authenticatedEmitter.emit(this.data);
  }

  private saveAuthInfo(data: LoginOutput) {
    this.saveUserId(data.id);
    this.saveUsername(data.username);
    this.saveAuthToken(data.token);
  }

  private saveAuthToken(value: string) {
    this._authToken = value;

    localStorage.setItem(authTokenKey, value);
  }

  private saveUsername(value: string) {
    this._username = value;

    localStorage.setItem(usernameKey, value);
  }

  private saveUserId(id: number) {
    this._userId = id;

    localStorage.setItem(userIdKey, id.toString());
  }

  onLogout() {
    this.removeAuthToken();
  }
  private removeAuthToken() {
    this._authToken = '';
    localStorage.removeItem(authTokenKey);
  }

  get isLoggedIn(): boolean {
    return Boolean(this.authToken);
  }

  get authToken() {
    return this._authToken;
  }

  get data(): IAuthorizedUserData {
    return {
      userId: this.userId,
      username: this.username,
    };
  }

  get username() {
    return this._username;
  }

  get userId() {
    return this._userId;
  }

  get authenticatedEvent() {
    return this.authenticatedEmitter.asObservable();
  }
}
