import { Component, HostBinding } from '@angular/core';

import { AlertService } from 'src/app/notifications/services/alert.service';
import { IAlertMessage } from 'src/app/notifications/services/interfaces';

@Component({
  selector: 'div[alert]',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  host: {
    class: 'alert',
  },
})
export class AlertComponent {
  message: IAlertMessage | null = null;

  constructor(service: AlertService) {
    const subscription = service.getMessage().subscribe((message) => {
      this.message = message;
    });
  }

  close() {
    this.message = null;
  }

  @HostBinding('class.alert-error')
  get isError() {
    return this.message?.type == 'error';
  }
  @HostBinding('class.alert-success')
  get isSuccess() {
    return this.message?.type == 'success';
  }
  @HostBinding('class.alert-warning')
  get isWarning() {
    return this.message?.type == 'warning';
  }
  @HostBinding('class.alert-info')
  get isInfo() {
    return this.message?.type == 'info';
  }

  @HostBinding('class.d-none')
  get hide() {
    return !this.isVisible;
  }

  get isVisible() {
    return this.message != null;
  }
}
