import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { ProtectedAPIService } from 'src/app/http/services/protected-api.service';
import { environment } from 'src/environments/environment';
import { IUserDataOutput, IPublicDataOutput } from '../outputs/interfaces';
import { AppEventsService } from './app-events.service';

@Injectable()
export class AppApiService extends ProtectedAPIService {
  constructor(
    private events: AppEventsService,
    errorHandler: ErrorHandlerService,
    http: HttpClient
  ) {
    super(`${environment.apiUrl}/app`, http, events.authState, errorHandler);

    events.userDataRequestEvent.subscribe(this.getUserData);
    events.publicDataRequestEvent.subscribe(this.getPublicData);

    // refresh public data every 1 minute
    setInterval(this.getPublicData, 1000 * 60);
  }

  getUserData = async () => {
    console.log('GetUserData requested!');

    const userData = await this.getWithAuthToken<IUserDataOutput>(
      'user-data?v=' + Date.now()
    );

    this.events.userDataReceivedEmitter.emit(userData);
    this.events.publicDataReceivedEmitter.emit(userData.publicData);
  };

  private getPublicData = async () => {
    //console.log('GetPublicData requested!');

    const publicData = await this.get<IPublicDataOutput>('public-data');

    this.events.publicDataReceivedEmitter.emit(publicData);
  };
}
