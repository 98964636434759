import { IEasyAccountData, easyAccountDataKey } from './data';

export class ExistingEasyAccount {
  private localData: IEasyAccountData;

  constructor() {
    this.load();
  }

  load() {
    const savedAccountData = localStorage.getItem(easyAccountDataKey);

    if (savedAccountData != null) {
      this.localData = JSON.parse(savedAccountData);
    }
  }

  login() {
    if (!this.isCreated) {
      return;
    }

    this.localData.isLoggedIn = true;
    this.save();
  }
  logout() {
    if (!this.isCreated) {
      return;
    }

    this.localData.isLoggedIn = false;
    this.save();
  }

  private save() {
    if (!this.isCreated) {
      return;
    }

    localStorage.setItem(easyAccountDataKey, JSON.stringify(this.localData));
  }

  get id() {
    return this.isCreated ? this.localData.id : 0;
  }

  get name() {
    return this.localData ? this.localData.name : '';
  }

  get publicKey() {
    return this.localData ? this.localData.pub : '';
  }

  get privateKey() {
    return this.localData ? this.localData.priv : '';
  }

  get isLoggedIn() {
    return this.isCreated ? this.localData.isLoggedIn : false;
  }

  get isCreated(): boolean {
    return this.localData != undefined && this.localData.name != undefined;
  }
}
