import { HttpClient } from '@angular/common/http';

import { IAuthStateService } from 'src/app/auth/services/interfaces';

import { ApiServiceBase } from './api-base.service';
import { IErrorHandlerService } from './interfaces';

export abstract class ProtectedAPIService extends ApiServiceBase {
  protected constructor(
    basePath: string,
    http: HttpClient,
    private authState: IAuthStateService,
    errorHandler: IErrorHandlerService
  ) {
    super(basePath, http, errorHandler);
  }

  protected getWithAuthToken<T>(path: string) {
    return this.get<T>(path, this.authState.authToken as string);
  }

  protected postWithAuthToken<T>(path: string, data?: any) {
    return this.post<T>(path, data, this.authState.authToken as string);
  }
}
