import { Injectable } from '@angular/core';
import { IPublicDataOutput } from 'src/app/global-state/outputs/interfaces';
import { AppEventsService } from 'src/app/global-state/services/app-events.service';
import { IStakingStatistics } from '../outputs/interfaces';

@Injectable()
export class StakingStatisticsService {
  private _stats: IStakingStatistics;

  constructor(events: AppEventsService) {
    events.publicDataReceivedEvent.subscribe(this.onPublicDataReceived);
  }

  private onPublicDataReceived = (data: IPublicDataOutput) => {
    this._stats = data.staking;
  };

  get stats() {
    return this._stats;
  }
}
