import { chips } from './interfaces';

// TODO: remove after all card games are integrated
export const chipList: chips[] = [
  {
    selected: true,
    name: 'gray',
    value: 0.1,
    src: 'assets/images/chips/chip_0_1.svg',
  },
  {
    selected: false,
    name: 'blue',
    value: 1,
    src: 'assets/images/chips/chip_1.svg',
  },
  {
    selected: false,
    name: 'red',
    value: 10,
    src: 'assets/images/chips/chip_10.svg',
  },
  {
    selected: false,
    name: 'green',
    value: 50,
    src: 'assets/images/chips/chip_50.svg',
  },
  {
    selected: false,
    name: 'black',
    value: 500,
    src: 'assets/images/chips/chip_500.svg',
  },
  {
    selected: false,
    name: 'purple',
    value: 2500,
    src: 'assets/images/chips/chip_2500.svg',
  },
  {
    selected: false,
    name: 'orange',
    value: 10000,
    src: 'assets/images/chips/chip_10000.svg',
  },
  {
    selected: false,
    name: 'gold',
    value: 999999,
    src: 'assets/images/chips/chip_MAX.svg',
  },
];
