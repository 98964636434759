<div class="container">
  <div
    class="holder"
    (click)="focus()"
    [class.blog]="blog"
    [ngClass]="lightOn ? 'light' : ''"
  >
    <form class="search-form">
      <button class="search-icon" (click)="search()"></button>

      <input
        type="text"
        class="search-input"
        #searchInput
        name="search"
        [placeholder]="lightOn ? 'Search game' : 'Search your game'"
        [(ngModel)]="query"
        (keyup)="$event.keyCode == 13 ? search() : undefined"
      />
    </form>
  </div>
</div>
