import { EventEmitter, Injectable } from '@angular/core';

import { TranslationManager } from 'src/app/translations/services/translation-manager';
import { CurrencyBalancesService } from 'src/app/currency/balance/services/currency-balances.service';

import { LocalStorageData } from '../util/local-storage-util';

import { IAppSettings } from './interfaces';
import { TokenSymbol } from 'src/app/currency/tokens/settings/token-symbols';
import { ITokenSettings } from 'src/app/currency/tokens/settings/interfaces';

const MOBILE_BREAK_POINT = 768;

@Injectable()
export class AppSettings implements IAppSettings {
  readonly translationManager: TranslationManager = new TranslationManager();

  private _selectedToken: ITokenSettings;
  private selectedCurrencyChangedEmitter = new EventEmitter<string>();

  private savedCurrency: LocalStorageData<string> = new LocalStorageData(
    'selectedCurrency',
    false
  );

  private currentInnerWidth: number;

  constructor(readonly balances: CurrencyBalancesService) {
    this.translationManager.init();

    this.init();

    this.currentInnerWidth = window.innerWidth;
  }

  private init() {
    let tokenSymbol = this.savedCurrency.get();

    if (tokenSymbol == null) {
      tokenSymbol = TokenSymbol.BTC;
    }

    this.selectCurrency(tokenSymbol);
  }

  updateInnerWidth(innerWidth: number) {
    this.currentInnerWidth = innerWidth;
  }

  async selectCurrency(symbol: string) {
    if (
      this.selectedToken == undefined ||
      symbol != this.selectedToken.symbol
    ) {
      const tokenSettings = this.tokenSettings;

      await tokenSettings.waitForInit();

      this._selectedToken = tokenSettings.getSettings(symbol);

      this.savedCurrency.save(symbol);

      this.selectedCurrencyChangedEmitter.emit(symbol);
    }
  }

  isSelectedCurrency(symbol: string) {
    return this.selectedToken && symbol == this.selectedToken.symbol;
  }

  get selectedCurrencyChangedEvent() {
    return this.selectedCurrencyChangedEmitter.asObservable();
  }

  get selectedTokenBalance() {
    return this.balances.getBalance(this.selectedToken.symbol);
  }

  get selectedToken(): ITokenSettings {
    return this._selectedToken;
  }

  get translation() {
    return this.translationManager.translation;
  }

  get isDesktop() {
    return !this.isMobile;
  }

  get isMobile() {
    return this.currentInnerWidth < MOBILE_BREAK_POINT;
  }

  get tokenSettings() {
    return this.balances.stakingData.tokenSettings;
  }
}
