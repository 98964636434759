import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CurrencyDropDownComponent } from './components/currency-drop-down/currency-drop-down.component';
import { TokenBalancesComponent } from './components/token-balances/token-balances.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [NgbModule, CommonModule, FormsModule, RouterModule],
  declarations: [CurrencyDropDownComponent, TokenBalancesComponent],
  providers: [],
  exports: [
    CurrencyDropDownComponent,
    TokenBalancesComponent,

    NgbModule,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
})
export class CurrencyTokensModule {}
