import { Injectable } from '@angular/core';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { AlertService } from 'src/app/notifications/services/alert.service';
import { SignUpBonusService } from 'src/app/sign-up-bonus/services/sign-up-bonus.service';
import { SocketClientBase } from 'src/app/socket/socket-client-base';
import { IConfirmedDepositData } from 'src/app/transactions/outputs/interfaces';
import { environment } from 'src/environments/environment';
import { AppSettings } from './app-settings.service';
import { ResolvedBets } from 'src/app/bets/services/resolved-bets';
import { IResolvedBet } from 'src/app/bets/inputs/interfaces';
import { RainStateService } from 'src/app/rain/services/rain-state.service';
import { IRainReward } from 'src/app/rain/outputs/interfaces';
import { ITipCreditedEvent } from 'src/app/tips/data/outputs';
import Big from 'big.js';

const NAMESPACE = '/notifications';

@Injectable()
export class NotificationsService extends SocketClientBase {
  constructor(
    private appState: AuthStateService,
    private alert: AlertService,
    private settings: AppSettings,
    private signUpBonus: SignUpBonusService,
    private bets: ResolvedBets,
    private rain: RainStateService
  ) {
    super(environment.gamesSocket, NAMESPACE);
  }

  connect() {
    const socket = this.connectSocket();

    socket.on('StreamNotificationEvent', (eventName: string, data) => {
      switch (eventName) {
        case 'CONFIRMED_DEPOSIT':
          this.onConfirmedDeposit(data);
          break;

        case 'NEW_RESOLVED_BET':
          this.onNewResolvedBet(data);
          break;

        case 'RAIN_EVENT_UPDATE':
          this.rain.onRainEventUpdate(data);
          break;

        case 'RAIN_REWARD_CREDITED':
          this.onRainRewardCredited(data);
          break;

        case 'TIP_CREDITED':
          this.onTipCredited(data);
          break;
      }
    });
  }

  private onConfirmedDeposit(data: IConfirmedDepositData) {
    const { user_id, currency_symbol, decimal_amount } = data;

    if (user_id == this.appState.userId) {
      this.alert.success(
        `Deposit for ${currency_symbol} has been confirmed! Your balance has been increased by: ${decimal_amount}`,
        true
      );

      this.settings.balances.addToBalance(currency_symbol, decimal_amount);

      // *** sync funnel progress with server ***
      this.signUpBonus.syncProgressForSignUpBonus();
    }
  }

  onRainRewardCredited(data: IRainReward) {
    const { user_id, currency_symbol, decimalAmount: decimal_amount } = data;

    if (user_id == this.appState.userId) {
      this.alert.success(
        `Your ${currency_symbol} Rain Reward has been received! Your balance has been increased by: ${decimal_amount}`,
        true
      );

      this.settings.balances.addToBalance(currency_symbol, decimal_amount);
    }
  }

  onTipCredited(data: ITipCreditedEvent) {
    const {
      from_user_id,
      to_user_id,
      currency_symbol,
      currency_amount,
      to_user_name,
      from_user_name,
    } = data;

    // debit from sender's balance
    if (from_user_id == this.appState.userId) {
      this.alert.success(
        `Your ${currency_amount} ${currency_symbol} Tip has been sent to ${to_user_name}!`,
        true
      );

      this.settings.balances.addToBalance(
        currency_symbol,
        new Big(currency_amount).times(-1)
      );
    }

    // credit to receiver's balance
    if (to_user_id == this.appState.userId) {
      this.alert.success(
        `You Received a Tip of ${currency_amount} ${currency_symbol} from ${from_user_name}!`,
        true
      );

      this.settings.balances.addToBalance(currency_symbol, currency_amount);
    }
  }

  private onNewResolvedBet(data: IResolvedBet) {
    this.bets.onBetResolved(data);
  }

  protected onConnected(): void {
    console.log('Notifications Client connected to Server!');
  }

  protected onDisconnected(): void {
    console.log('Notifications Client DISCONNECTED from Server!');
  }
}
