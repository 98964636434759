import { Injectable } from '@angular/core';

import { AppSettings } from 'src/app/global-state/services/app-settings.service';

import { IBankrollService } from './interfaces';

@Injectable()
export class BankrollService implements IBankrollService {
  constructor(private settings: AppSettings) {}

  getMaxBet(payoutFactor: number): number {
    // user's token balance (based on currency)
    const myBalance = Number(this.settings.selectedTokenBalance);

    const maxWinAmount = this.settings.selectedToken.maximumWinAmount;

    // take bet type/ odds into account
    const maxBet = this.calculateMaxBet(maxWinAmount as number, payoutFactor);

    // console.log('Max bet: '+maxBet);

    // take user's token balance into account
    return myBalance <= maxBet ? myBalance : maxBet;
  }

  private calculateMaxBet(maxWinAmount: number, payoutFactor: number): number {
    const maxBet = maxWinAmount / (payoutFactor - 1);

    return maxBet;
  }
}
