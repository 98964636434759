import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ErrorHandlerService } from 'src/app/http/services/error-handler.service';
import { ProtectedAPIService } from 'src/app/http/services/protected-api.service';
import { environment } from 'src/environments/environment';
import { ISendTipInput } from '../data/inputs';

@Injectable()
export class TipsService extends ProtectedAPIService {
  constructor(
    authState: AuthStateService,
    errorHandler: ErrorHandlerService,
    http: HttpClient
  ) {
    super(`${environment.apiUrl}/tips`, http, authState, errorHandler);
  }

  sendTip(input: ISendTipInput) {
    return this.postWithAuthToken('send', input);
  }
}
