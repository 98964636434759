import { Injectable } from '@angular/core';
import { ExistingEasyAccount } from '../easy-account/existing-account';
import { createEosSignature } from '../eos';
import { IWaxAccountData } from '../inputs/interfaces';

@Injectable()
export class SignatureService {
  private existingEarnBetAccount = new ExistingEasyAccount();

  private waxAccount: IWaxAccountData;
  private _privateKeyForEarnBetAccount: string;

  constructor() {
    console.log({ isEarnBetAccountKeyLoaded: this.isEarnBetAccountKeyLoaded });
  }

  storeWaxAccountCredentials(data: IWaxAccountData) {
    this.waxAccount = data;
  }

  storePrivateKeyForEarnBetAccount(privatekey: string) {
    this._privateKeyForEarnBetAccount = privatekey;
  }

  signEarnBetAccountData<T>(data: T) {
    if (this.privateKeyForEarnBetAccount) {
      return createEosSignature(data, this.privateKeyForEarnBetAccount);
    } else {
      throw new Error('no earnbet account credentials stored');
    }
  }

  get isEarnBetAccountKeyLoaded() {
    return this.privateKeyForEarnBetAccount != undefined;
  }

  private get privateKeyForEarnBetAccount() {
    return (
      this._privateKeyForEarnBetAccount ||
      (this.existingEarnBetAccount.isCreated
        ? this.existingEarnBetAccount.privateKey
        : undefined)
    );
  }
}
