import { NgModule } from '@angular/core';
import { NavigationModule } from '../navigation/navigation.module';

import { AlertService } from './services/alert.service';

@NgModule({
  imports: [NavigationModule],
  providers: [AlertService],
  exports: [NavigationModule],
})
export class NotificationsModule {}
