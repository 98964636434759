import { NgModule } from '@angular/core';
import { NotificationsModule } from '../notifications/notifications.module';

import { ErrorHandlerService } from './services/error-handler.service';

@NgModule({
  imports: [NotificationsModule],
  providers: [ErrorHandlerService],
  exports: [NotificationsModule],
})
export class HttpHelperModule {}
