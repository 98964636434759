import Big, { BigSource } from 'big.js';

export class CurrencyMath {
  private _integer: number;
  readonly precision: number;
  private _symbol: string;

  constructor(precision: number, symbol: string) {
    this._integer = 0;
    this.precision = precision;
    this._symbol = symbol;
  }

  initWithInteger(intVal: BigSource) {
    Big.RM = 0;
    this._integer = Number(new Big(intVal).toFixed(0));
  }
  initWithDecimal(intDec: BigSource) {
    Big.RM = 0;
    this._integer = Number(
      new Big(intDec).times(`1e${this.precision}`).toFixed(0)
    );
  }

  decToInt(valDecimal: BigSource): number {
    Big.RM = 0;
    return Number(new Big(valDecimal).times(`1e${this.precision}`).toFixed(0));
  }

  addInt(valInteger: BigSource) {
    Big.RM = 0;

    this._integer = Number(new Big(this._integer).plus(valInteger));
    return this;
  }
  addDec(valDecimal: BigSource) {
    Big.RM = 0;

    this._integer = Number(
      new Big(this._integer).add(this.decToInt(valDecimal))
    );
    return this;
  }

  subInt(valInteger: BigSource) {
    Big.RM = 0;

    this._integer = Number(new Big(this._integer).minus(valInteger));
    return this;
  }
  subDec(valDecimal: BigSource) {
    Big.RM = 0;

    this._integer = Number(
      new Big(this._integer).minus(this.decToInt(valDecimal))
    );
    return this;
  }

  divInt(valInteger: BigSource) {
    Big.RM = 0;

    this._integer = Number(new Big(this._integer).div(valInteger));
    return this;
  }
  divDec(valDecimal: BigSource) {
    Big.RM = 0;

    this._integer = Number(
      new Big(this._integer).div(this.decToInt(valDecimal))
    );
    return this;
  }

  multInt(valInteger: BigSource) {
    Big.RM = 0;

    this._integer = Number(new Big(this._integer).times(valInteger));
    return this;
  }

  multDec(valDecimal: BigSource) {
    Big.RM = 0;

    this._integer = Number(
      new Big(this._integer).times(this.decToInt(valDecimal))
    );
    return this;
  }

  get assetString(): string {
    return `${this.toDecimalString()} ${this.symbol}`;
  }
  get decimal(): string {
    return this.toDecimalString();
  }

  toDecimalString(): string {
    const decimal = new Big(this._integer).times(`1e-${this.precision}`);
    return decimal.toFixed(this.precision);
  }

  get integer(): number {
    return this._integer;
  }
  get symbol(): string {
    return this._symbol;
  }
}
