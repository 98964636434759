<div class="cards">
  <div class="holder">
    <h2>Game Providers</h2>
    <div
      (mouseenter)="pauseAnimation(0)"
      (mouseleave)="pauseAnimation(0)"
      class="content stop"
    >
      <div class="scrolling-wrapper">
        <div [class.stop]="pause[0]" class="card first">
          <div class="card-inner">
            <img src="assets/images/providers/earnbet.svg" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/pragmatic.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/nolimit.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/earnbet.svg" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/pragmatic.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/nolimit.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/earnbet.svg" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/pragmatic.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/nolimit.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/earnbet.svg" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/pragmatic.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/nolimit.png" />
          </div>
        </div>
      </div>
    </div>
    <div
      (mouseenter)="pauseAnimation(1)"
      (mouseleave)="pauseAnimation(1)"
      class="content"
    >
      <div class="scrolling-wrapper">
        <div [class.stop]="pause[1]" class="card first-2">
          <div class="card-inner">
            <img src="assets/images/providers/bgaming.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/evolution.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/netent.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/bgaming.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/evolution.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/netent.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/bgaming.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/evolution.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/netent.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/bgaming.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/evolution.png" />
          </div>
        </div>
        <div class="card">
          <div class="card-inner">
            <img src="assets/images/providers/netent.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="holder">
    <h2>Accepted Cryptocurrencies</h2>
    <div
      (mouseenter)="pauseAnimation(2)"
      (mouseleave)="pauseAnimation(2)"
      class="content"
    >
      <div class="scrolling-wrapper">
        <div [class.stop]="pause[2]" class="card first">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'BTC' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/BTC.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'ETH' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/ETH.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'EOS' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/EOS.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'BTC' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/BTC.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'ETH' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/ETH.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'EOS' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/EOS.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'BTC' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/BTC.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'ETH' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/ETH.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'EOS' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/EOS.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'BTC' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/BTC.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'ETH' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/ETH.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'EOS' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/EOS.png" />
          </a>
        </div>
      </div>
    </div>
    <div
      (mouseenter)="pauseAnimation(3)"
      (mouseleave)="pauseAnimation(3)"
      class="content"
    >
      <div class="scrolling-wrapper">
        <div [class.stop]="pause[3]" class="card first-2">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'WAX' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/WAXP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'USDT' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/USDT.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'XRP' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/XRP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'WAX' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/WAXP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'USDT' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/USDT.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'XRP' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/XRP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'WAX' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/WAXP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'USDT' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/USDT.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'XRP' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/XRP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'WAX' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/WAXP.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'USDT' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/USDT.png" />
          </a>
        </div>
        <div class="card">
          <a
            href=""
            routerLink="/deposit"
            [queryParams]="{ currency: 'XRP' }"
            class="card-inner"
          >
            <img src="assets/images/crypto/XRP.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
