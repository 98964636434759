export interface IStakingLevelData {
  name: string;
  stakePoints: number;
  houseEdgeReduction: number;
  baccaratHouseEdge: number;
}

export const stakingLevels: IStakingLevelData[] = [
  {
    name: 'shrimp',
    stakePoints: 1000,
    houseEdgeReduction: 0.05,
    baccaratHouseEdge: 1.03,
  },
  {
    name: 'crab',
    stakePoints: 10000,
    houseEdgeReduction: 0.1,
    baccaratHouseEdge: 0.99,
  },
  {
    name: 'octopus',
    stakePoints: 25000,
    houseEdgeReduction: 0.12,
    baccaratHouseEdge: 0.98,
  },
  {
    name: 'squid',
    stakePoints: 50000,
    houseEdgeReduction: 0.15,
    baccaratHouseEdge: 0.96,
  },
  {
    name: 'fish',
    stakePoints: 100000,
    houseEdgeReduction: 0.2,
    baccaratHouseEdge: 0.92,
  },
  {
    name: 'blowfish',
    stakePoints: 250000,
    houseEdgeReduction: 0.25,
    baccaratHouseEdge: 0.89,
  },
  {
    name: 'dolphin',
    stakePoints: 500000,
    houseEdgeReduction: 0.35,
    baccaratHouseEdge: 0.82,
  },
  {
    name: 'shark',
    stakePoints: 1000000,
    houseEdgeReduction: 0.5,
    baccaratHouseEdge: 0.71,
  },
  {
    name: 'whale',
    stakePoints: 2500000,
    houseEdgeReduction: 0.75,
    baccaratHouseEdge: 0.6,
  },
  {
    name: 'blue-whale',
    stakePoints: 5000000,
    houseEdgeReduction: 1,
    baccaratHouseEdge: 0.5,
  },
];

export function getHouseEdgeReductionAsDecimal(stakePoints: string): number {
  const levelIndex = getStakingLevelIndex(stakePoints);

  const level = levelIndex !== undefined && stakingLevels[levelIndex];

  return level ? level.houseEdgeReduction / 100 : 0;
}

export function getStakingLevelName(stakePoints: string): string | undefined {
  const levelIndex = getStakingLevelIndex(stakePoints);

  const level = levelIndex !== undefined && stakingLevels[levelIndex];

  return level ? level.name : undefined;
}

export function getStakingLevelIndex(stakePoints: string): number {
  const points = Number(stakePoints);

  const firstLevel = stakingLevels[0];

  if (points < firstLevel.stakePoints) {
    return -1;
  }

  for (let i = 0; i < stakingLevels.length; i++) {
    const nextLevel = stakingLevels[i + 1];

    if (!nextLevel || points < nextLevel.stakePoints) {
      return i;
    }
  }

  throw new Error('level not found?');
}
