import { NgModule } from '@angular/core';

import { AuthenticatedGuard } from './guards/authenticated.guard';
import { AuthStateService } from './services/auth-state.service';

@NgModule({
  imports: [],
  providers: [AuthenticatedGuard, AuthStateService],
})
export class AuthModule {}
