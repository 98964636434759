import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { SignUpBonusState } from '../sign-up-bonus/services/sign-up-bonus-state';

@Injectable()
export class NavigationService {
  private _isNavBarVisible = true;

  constructor(
    private router: Router,
    authState: AuthStateService,
    readonly signUpBonusState: SignUpBonusState
  ) {
    authState.authenticatedEvent.subscribe(() => this.redirectAfterLogin());
  }

  private redirectAfterLogin() {
    if (this.signUpBonusState.isNewlyCreatedAccount) {
      this.router.navigateByUrl('/deposit');

      return;
    }

    // TODO: is this still relevant?
    const isAccountImported = this.router.url == '/import';

    // TODO: does this need to be adjusted?
    const shouldRedirectToMain =
      this.router.url == '/login' ||
      //this.router.url == '/register' ||
      isAccountImported;

    if (shouldRedirectToMain) {
      this.gotoHomePage();
    }
  }

  gotoHomePage() {
    this.router.navigateByUrl('');
  }

  gotoPage(pageName: string) {
    this.router.navigateByUrl('/' + pageName);
  }

  scrollToTopOfPage() {
    window.scrollTo(0, 0);

    const elements = document.getElementsByClassName('ng-sidebar__content');
    const div = elements[0] as HTMLDivElement;

    // scroll to top of sidebar page content
    div.scrollTop = 0;
  }

  showNavBar() {
    this._isNavBarVisible = true;
  }
  hideNavBar() {
    this._isNavBarVisible = false;
  }

  get isNavBarVisible() {
    return this._isNavBarVisible;
  }
}
