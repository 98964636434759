import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalsService } from './modals.service';

@NgModule({
  imports: [NgbModule],
  providers: [ModalsService],
  exports: [NgbModule],
})
export class ModalsModule {}
