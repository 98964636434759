import { Component } from '@angular/core';

import { CountdownComponentBase } from 'src/app/global-state/components/countdown-component-base';
import { AppService } from 'src/app/global-state/services/app.service';

@Component({
  selector: 'count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
})
export class CountDownComponent extends CountdownComponentBase {
  constructor(app: AppService) {
    super(app);
  }
}
