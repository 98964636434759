import { AppService } from '../services/app.service';

export abstract class AppComponentBase {
  protected constructor(readonly app: AppService) {}

  toggleSidebar(isForMobile = false) {
    this.app.sidebarState.togglePanel(isForMobile);
  }
  showMenu() {
    this.app.sidebarState.showMenu();
  }

  get tokenSettings() {
    return this.balances.stakingData.tokenSettings;
  }

  get dividends() {
    return this.app.dividends;
  }

  // for backwards compatibility:
  get wallet() {
    return this.balances;
  }
  get balances() {
    return this.settings.balances;
  }

  get alert() {
    return this.app.alert;
  }

  get translation() {
    return this.settings.translation;
  }

  get selectedTokenBalanceForWithdrawal() {
    return this.app.userState.unrestrictedBalances.getBalance(
      this.selectedToken.symbol
    );
  }

  get selectedToken() {
    return this.settings.selectedToken;
  }

  get settings() {
    return this.app.settings;
  }

  get username() {
    return this.app.authState.username;
  }

  get isLoggedIn() {
    return this.app.authState.isLoggedIn;
  }

  get audio() {
    return this.app.audio;
  }

  get isSidebarOpen() {
    return this.sidebar.isPanelOpen;
  }

  get isMobile() {
    return this.settings.isMobile;
  }
  get isDesktop() {
    return this.settings.isDesktop;
  }

  get sidebar() {
    return this.app.sidebarState;
  }

  get modals() {
    return this.app.modals;
  }

  get leaderboard() {
    return this.app.leaderboard;
  }

  get googleAnalytics() {
    return this.app.googleAnalytics;
  }

  get isNavBarVisible() {
    return this.app.navigator.isNavBarVisible;
  }
}
