import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as yaml from 'js-yaml';

import { FavoritesList, GameList, IExternalGame } from '../data/interfaces';
import { GameProvider } from '../data/game-providers';
import { sleep } from 'src/app/util/timer-util';

const favoriteGamesKey = 'myFavoriteGames_v1';

@Injectable()
export class GamesListService {
  private gamesSubject: BehaviorSubject<GameList[]> = new BehaviorSubject<
    GameList[]
  >([]);

  private games: GameList[] = [];

  private favorites: FavoritesList = {};

  private numOfProvidersLoaded = 0;

  constructor(private http: HttpClient) {
    this.favorites = JSON.parse(localStorage.getItem(favoriteGamesKey) || '{}');

    this.initGameList();
  }

  initGameList() {
    for (const providerkey in GameProvider) {
      this.fetchtList(providerkey).subscribe((res: string) => {
        this.parseList(res, providerkey);
        this.gamesSubject.next(this.games);

        this.numOfProvidersLoaded++;
      });
    }
  }
  private fetchtList(gameProvider: string) {
    return this.http.get(`assets/games-yaml/${gameProvider}.yaml`, {
      responseType: 'text',
    });
  }
  private parseList(list: string, providerkey: string) {
    let listData = yaml.load(list);
    const indentedJson = JSON.stringify(listData);
    const games = JSON.parse(indentedJson) as IExternalGame[];

    this.games.push({
      name: providerkey,
      games: games.map((game) => {
        game.liked = this.favorites[providerkey]?.[game.identifier] === true;
        return game;
      }),
    });
  }

  addFavorite(game: IExternalGame) {
    const provider = game.producer;

    if (!provider) {
      console.log({ game });
      throw new Error('no provider for game!');
    }

    if (!this.favorites[provider]) {
      this.favorites[provider] = {};
    }

    this.favorites[provider][game.identifier] = true;

    this.saveFavorites();
  }
  removeFavorite(game: IExternalGame) {
    const provider = game.producer;

    if (!provider) {
      console.log({ game });
      throw new Error('no provider for game!');
    }

    delete this.favorites[provider][game.identifier];

    if (Object.keys(this.favorites[provider]).length == 0) {
      delete this.favorites[provider];
    }

    this.saveFavorites();
  }
  private saveFavorites() {
    localStorage.setItem(favoriteGamesKey, JSON.stringify(this.favorites));
  }

  async getGamesForProvider(providerName: string) {
    await this.waitForInit();

    return this.getGamesByProvider(providerName);
  }
  getGamesByProvider(provider_name: string) {
    const providerGames = this.games.find(
      (g: GameList) => g.name === provider_name
    );
    return providerGames;
  }

  async getFavoriteGames(): Promise<IExternalGame[]> {
    const promises: Promise<IExternalGame>[] = [];

    for (const provider in this.favorites) {
      for (const identifier in this.favorites[provider]) {
        promises.push(this.getGameByIdentifier(identifier));
      }
    }

    const games = await Promise.all(promises);

    return games;
  }

  async searchGames(query: string) {
    await this.waitForInit();

    const results: IExternalGame[] = [];

    for (const list of this.games) {
      list.games
        .filter(
          (game) => game.title.toLowerCase().indexOf(query.toLowerCase()) > -1
        )
        .forEach((game) => results.push(game));
    }

    return results;
  }

  async getGameByIdentifier(identifier: string): Promise<IExternalGame> {
    await this.waitForInit();

    for (const list of this.games) {
      const result = list.games.find(
        (game: IExternalGame) => identifier === game.identifier
      );

      if (result) {
        return result;
      }
    }

    throw new Error('game not found: ' + identifier);
  }

  private async waitForInit() {
    while (this.numOfProvidersLoaded < 6) {
      await sleep(10);
    }
  }

  gamesObservable() {
    return this.gamesSubject.asObservable();
  }
}
