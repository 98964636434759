import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nft-modal',
  templateUrl: './nft.component.html'
})
export class NftComponent implements OnInit {
  @Input() modal: any;
  constructor() { }

  ngOnInit(): void {
  }

}
