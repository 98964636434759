import { Component, OnInit } from '@angular/core';
import { cryptoList } from 'src/app/shared/data/cryptoList';

@Component({
  selector: 'user-bet-statistics',
  templateUrl: './user-bet-statistics.component.html',
  styleUrls: ['./user-bet-statistics.component.scss'],
})
export class UserBetStatisticsComponent implements OnInit {
  currency = cryptoList;
  userData = {
    betStatistics: {
      totalBets: 17950,
      totalWagered: 157632,
      currency: [
        {
          name: 'btc',
          fullName: this.currency[0].fullName,
          bets: 18,
          wagered: 0.000435,
        },
        {
          name: 'eth',
          fullName: this.currency[1].fullName,
          bets: 1661,
          wagered: 4.33846451,
        },
        {
          name: 'ltc',
          fullName: this.currency[3].fullName,
          bets: 16271,
          wagered: 13.88161023,
        },
      ],
    },
  };

  constructor() {}

  ngOnInit(): void {}

  getSource(getSource: any) {
    return `assets/icons/crypto/${getSource}-logo.svg`;
  }
}
